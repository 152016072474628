<nav id="header3" class="navbar navbar-expand-lg bg-transparent fixed-top common-header3" *ngIf="header3Type=='botStationAi'" data-aos="fade-down" data-aos-duration="1000">
    <div class="container">
        <!-- Brand -->
      <a class="navbar-brand" [routerLink]="['/bot-station-ai-home']">
        <img src="./assets/images/bot-station-ai/logo/Logo1.svg" class="float-start" width="50" alt="logo" />
        <span class="float-start ms-3 text-white">Bot Station AI</span>
      </a>
      <!-- Toggler -->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon">
            <i class="ri-menu-3-line text-white"></i>
        </span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <a class="nav-link text-white-50" [routerLinkActive]="['active']" [routerLink]="['/bot-station-ai-home']">Platform</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle text-white-50" [routerLinkActive]="['active']" [routerLink]="['/bot-station-ai-solution']" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Solutions
            </a>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                <li>
                    <a class="dropdown-item" [routerLink]="['/bot-station-ai-solution']">
                      <i class="ri-global-line"></i> Website
                    </a>
                </li>
                <li>
                    <a class="dropdown-item" [routerLink]="['/bot-station-ai-solution']">
                      <i class="ri-smartphone-line"></i> Mobile
                    </a>
                </li>
                <li>
                    <a class="dropdown-item" [routerLink]="['/bot-station-ai-solution']">
                      <i class="ri-whatsapp-line"></i> Whatsapp
                    </a>
                </li>
                <li>
                    <a class="dropdown-item" [routerLink]="['/bot-station-ai-solution']">
                      <i class="ri-facebook-line"></i> Facebook
                    </a>
                </li>
                <li>
                    <a class="dropdown-item" [routerLink]="['/bot-station-ai-solution']">
                      <i class="ri-twitter-line"></i> Instagram
                    </a>
                </li>
                <li>
                    <a class="dropdown-item" [routerLink]="['/bot-station-ai-solution']">
                      <i class="ri-phone-line"></i> Voice
                    </a>
                </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link text-white-50" [routerLinkActive]="['active']" [routerLink]="['/bot-station-ai-resources']">Customers</a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link text-white-50" aria-current="page" [routerLink]="['/corporate-blue-price']">Start 14 day’s trail</a>
          </li> -->
          <app-modal1 [modal1Type]="'botStationAi'"></app-modal1>
          <app-modal2 [modal2Type]="'botStationAi'"></app-modal2>
        </ul>
      </div>
    </div>
</nav>