
<!-- image-text-wrapper3 section start here -->
<section class="container-fluid testimonial-wrapper-1 section bg-blue bg-dotted-white" *ngIf="testimonialWrapper1Type=='corporateBlue'" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="200">
    <div class="container pb-5 pt-5">
      <div class="row">
        <div class="col-12 pb-5">
          <owl-carousel-o [options]="testimonial1">
            <ng-template carouselSlide>
              <div class="testimonial-item text-center max-699 m-auto">
                <img
                  src="./assets/images/brand-logo/skype.svg"
                  class="m-auto"
                  style="width: 75px"
                />
                <br />
                <div class="w-100">
                  <p class="text-white">
                    Completely restore optimal human capital with economically
                    sound infomediaries. Authoritatively extend end-to-end content
                    with covalent relationships.
                  </p>
                  <div class="testi-meta mt-4 text-white">
                    <span class="float-start w-100 name mb-2">Steve Jobs</span>
                    <span class="float-start w-100 text-white-50"
                      >Apple Inc.</span
                    >
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="testimonial-item text-center max-699 m-auto">
                <img
                  src="./assets/images/brand-logo/netflix-white.svg"
                  class="m-auto"
                  style="width: 125px"
                />
                <br />
                <div class="w-100">
                  <p class="text-white">
                    Dynamically supply cross functional process improvements
                    whereas enterprise-wide ROI. Distinctively streamline cost
                    effective data and multifunctional functionalities.
                  </p>
                  <div class="testi-meta mt-4 text-white">
                    <span class="float-start w-100 name mb-2">Collis Ta'eed</span>
                    <span class="float-start w-100 text-white-50"
                      >Envato Inc.</span
                    >
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="testimonial-item text-center max-699 m-auto">
                <img
                  src="./assets/images/brand-logo/nat-geo.svg"
                  class="m-auto"
                  style="width: 125px"
                />
                <br />
                <div class="w-100">
                  <p class="text-white">
                    Globally implement strategic resources via ethical markets.
                    Interactively drive highly efficient potentialities before
                    economically sound applications communicate solutions.
                  </p>
                  <div class="testi-meta mt-4 text-white">
                    <span class="float-start w-100 name mb-2">John Doe</span>
                    <span class="float-start w-100 text-white-50">XYZ Inc.</span>
                  </div>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>
  <!-- image-text-wrapper3 section end here -->