import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NmnpHomeComponent } from './pages/views/nmnp/nmnp-home/nmnp-home.component';
import { NmnpAboutComponent } from './pages/views/nmnp/nmnp-about/nmnp-about.component';
import { NmnpTeamComponent } from './pages/views/nmnp/nmnp-team/nmnp-team.component';
import { NmnpServicesComponent } from './pages/views/nmnp/nmnp-services/nmnp-services.component';
import { NmnpContactComponent } from './pages/views/nmnp/nmnp-contact/nmnp-contact.component';

const routes: Routes = [
  { path: 'home', component: NmnpHomeComponent },
  { path: 'about', component: NmnpAboutComponent },
  { path: 'team', component: NmnpTeamComponent },
  { path: 'services', component: NmnpServicesComponent },
  { path: 'contactus', component: NmnpContactComponent },
  { path: '**', component: NmnpHomeComponent },
  { path: '', redirectTo: 'training-home', pathMatch: 'full' },
];

@NgModule({
  declarations: [
  ],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
