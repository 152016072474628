<!-- image-text-wrapper5 section start here -->
<div class="section image-text-wrapper5 bg-transparent mt-0" *ngIf="imageTextwrapper5Type=='corporateBlue'">
    <div class="container mt-5 pt-5 mb-5 pb-5">
        <div class="row justify-content-between">
            <div class="col-lg-5">
                <div class="text-nowrap">
                    <h2 class="nott mb-3 display-3 fw-bold" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">We're truly Yours</h2>
                    <p class="small text-black-50" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">Recusandae ducimus, sequi, sapiente possimus optio impedit <br> efficient services. Completely promote.</p>
                </div>

                <div class="clear"></div>

                <div class="my-5">
                    <p class="fw-bold" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="900">Headquarters:</p>
                    <p class="mb-4">
                        795 Folsom Ave, Suite 600<br>
                        San Francisco, CA 94107<br>
                    </p>
                    <p class="mb-3" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1200"><span class="fw-bold">Phone: </span> (1) 8547 632521</p>
                    <p data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1500"><span class="fw-bold">Email: </span> info@canvas.com</p>
                </div>

                <div class="d-flex mt-4">
                    <a href="#" class="social-icon si-colored si-facebook" title="Facebook">
                        <i class="icon-facebook"></i>
                        <i class="icon-facebook"></i>
                    </a>

                    <a href="#" class="social-icon si-colored si-instagram" title="Instagram">
                        <i class="icon-instagram"></i>
                        <i class="icon-instagram"></i>
                    </a>

                    <a href="#" class="social-icon si-colored si-twitter" title="Twitter">
                        <i class="icon-twitter"></i>
                        <i class="icon-twitter"></i>
                    </a>

                    <a href="#" class="social-icon si-colored si-appstore" title="Apple">
                        <i class="icon-apple"></i>
                        <i class="icon-apple"></i>
                    </a>

                    <a href="#" class="social-icon si-colored si-android" title="Android">
                        <i class="icon-android"></i>
                        <i class="icon-android"></i>
                    </a>

                </div>
            </div>
            <div class="col-lg-7" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="200">
                <img class="w-100" src="./assets/images/co-working/co-working-1.jpeg" alt="Office Image">
            </div>
        </div>
    </div>
</div>
<!-- image-text-wrapper5 section start here -->