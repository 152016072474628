<footer id="footer" class="bg-white border-0 footer-1 section pb-0" *ngIf="footer1Type=='corporateBlue'"  data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
    <div class="container">
      <div class="footer-widgets-wrap pt-2 pb-3">
        <div class="row mx-auto">
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-3 col-6">
                <div class="widget widget_links">
                  <h4 class="mb-3">Features</h4>
  
                  <ul>
                    <li><a href="#">Help Center</a></li>
                    <li><a href="#">Paid with Moblie</a></li>
                    <li><a href="#">Status</a></li>
                    <li><a href="#">Changelog</a></li>
                    <li><a href="#">Contact Support</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="widget widget_links">
                  <h4 class="mb-3">Support</h4>
  
                  <ul>
                    <li><a href="#">Home</a></li>
                    <li><a href="#">About</a></li>
                    <li><a href="#">FAQs</a></li>
                    <li><a href="#">Support</a></li>
                    <li><a href="#">Contact</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-3 col-6 mt-4 mt-md-0">
                <div class="widget widget_links">
                  <h4 class="mb-3">Trending</h4>
  
                  <ul>
                    <li><a href="#">Shop</a></li>
                    <li><a href="#">Portfolio</a></li>
                    <li><a href="#">Blog</a></li>
                    <li><a href="#">Events</a></li>
                    <li><a href="#">Forums</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-3 col-6 mt-4 mt-md-0">
                <div class="widget widget_links">
                  <h4 class="mb-3">Get to Know us</h4>
  
                  <ul>
                    <li><a href="#">Corporate</a></li>
                    <li><a href="#">Agency</a></li>
                    <li><a href="#">eCommerce</a></li>
                    <li><a href="#">Personal</a></li>
                    <li><a href="#">OnePage</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row align-items-center border-top mt-4 pt-4">
              <div class="col-12">
                <p class="small">Conveniently pontificate interdependent mindshare via standards
                    compliant applications. Rapidiously empower effective metrics.</p>
              </div>
            </div>
          </div>
  
          <div class="col-md-4 mt-4 mt-md-0">
            <div class="widget">
              <h4 class="mb-3 text-uppercase">From the Blog</h4>
  
              <article class="entry">
                <div class="entry-image mb-3">
                  <a href="#"
                    ><img src="./assets/images/blog-img/blog-img.jpeg" alt="Image 3"
                  /></a>
                </div>
                <div class="entry-title title-xs">
                  <h3 class="mb-2">
                    <a href="#" class="fw-bold text-dark text-uppercase">A Surprising Tool to Help you Lifestyle</a>
                  </h3>
                </div>
                <div class="entry-desc">
                  <p class="text-black-50 small">
                    Quickly maintain covalent supply chains for multidisciplinary
                    scenarios. Competently visualize.
                  </p>
                </div>
              </article>
            </div>
  
            <div class="float-start w-100">
              <div class="social-btns mt-3">
                  <a class="btn facebook" href="#">
                      <i class="ri ri-facebook-fill"></i>
                  </a>
                  <a class="btn instagram" href="#">
                      <i class=" ri ri-instagram-line"></i>
                  </a>
                  <a class="btn twitter" href="#">
                      <i class="ri ri-twitter-fill"></i>
                  </a>
                  <a class="btn appstore" href="#">
                      <i class="ri ri-app-store-fill"></i>
                  </a>
                  <a class="btn googleplay" href="#">
                      <i class="ri ri-google-play-fill"></i>
                  </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div id="copyrights" class="bg-blue dark py-5 copyrights">
      <div class="container">
        <div class="row col-mb-30">
          <div class="col-md-6 text-center text-md-start">
            <p class="small text-white-50">Copyrights © 2021 All Rights Reserved by AllInOne</p>
            <div class="copyright-links mt-1 small text-white-50">
              <a href="#" class="text-white-50 small">Terms of Use</a> /
              <a href="#" class="text-white-50 small">Privacy Policy</a>
            </div>
          </div>
  
          <div class="col-md-6 text-center text-md-end">
            <a href="#" class="btn color bg-white rounded px-3 py-2 nott ls0 shadow-sm">
                <i class="icon-apple1 me-2"></i>App Store
            </a>
            <a href="#" class="btn color bg-white rounded px-3 py-2 nott ls0 shadow-sm ms-2">
                <i class="icon-google-play me-2"></i>Play Store
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
  

  <footer id="footer" class="bg-white border-0 footer-1 section pb-0" *ngIf="footer1Type=='training'"  data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
    <div id="copyrights" class="bg-blue dark py-4 copyrights">
      <div class="container">
        <div class="row col-mb-30">
          <div class="col-md-6 text-center text-md-start">
            <p class="small text-white-50">Copyrights © 2023 All Rights Reserved by Samarthview Technologies</p>
          </div>
        </div>
      </div>
    </div>
  </footer>