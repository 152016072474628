import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-accordion1',
  templateUrl: './accordion1.component.html',
  styleUrls: ['./accordion1.component.scss']
})
export class Accordion1Component implements OnInit {

  @Input() accordion1Type:string;
  constructor() { }

  ngOnInit(): void {
  }

}
