import { Component, OnInit, Input } from '@angular/core';

import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-blog1',
  templateUrl: './blog1.component.html',
  styleUrls: ['./blog1.component.scss']
})
export class Blog1Component implements OnInit {
  
  @Input() blog1Type:string;

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    center: false,
    dots: false,
    autoHeight: true,
    autoWidth: true,
    nav: true,
    slideTransition:'linear',
    autoplayTimeout: 0,
    autoplaySpeed: 0,
    autoplayHoverPause: false,
    navText: [ '<i class="ri-arrow-left-s-line"></i>', '<i class="ri-arrow-right-s-line"></i>' ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      769: {
        items: 3,
      },

    }
  }
  constructor() { }

  ngOnInit(): void {
  }

}
