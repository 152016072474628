<div class="container-fluid pt-5 information-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-12 position-relative">
                <div class="back-btn float-start w-100"></div>
                <div class="heading float-start w-100">
                    <a routerlink="/training-course-details-ux" href="/training-course-details-ux"
                        class="float-start mb-2 cursor-pointer">
                        <i class="ri-arrow-left-circle-line text-secondary font-24 float-start"></i>
                        <span class="float-start ms-2">User-Centered Design Process</span>
                    </a>
                    <h5 class="text-black font-30 float-start w-100 mt-1">Design Principles for UX and UI Design</h5>
                </div>
            </div>
            <div class="col-12 mt-3 mb-4">
                <p class="mb-2 font-18">UX design is a creative and ever-changing field that welcomes fresh ideas from
                    new practitioners, but there are some foundational UX design principles that every new designer
                    should understand.</p>
                <p class="mb-2 font-18">The Interaction Design Foundation defines UX principles as “fundamental points
                    of advice for making easy-to-use, pleasurable designs as we select, create and organize elements and
                    features in our work.” Design principles, therefore, form the
                    heart and soul of UX design. Consequently, as new designers, it is essential that you learn these
                    guidelines and consider aligning your design practice with them.</p>
            </div>
            <div class="col-12 mt-3 mb-4">
                <div id="accordionExample" class="accordion">
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse"
                                data-bs-target="#section1" aria-expanded="true" aria-controls="section1"
                                class="accordion-button text-semibold font-18"> 1. Meet the users’ needs </button></h2>
                        <div id="section1" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                            class="accordion-collapse collapse show">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">The foremost of all UX design principles is to focus on users
                                    throughout the design process. The term user experience itself makes it clear that
                                    your work needs to center on improving your users’ experience with your product
                                    or service.</p>
                                <p class="mb-2 font-18">Thus, you need to learn what users are looking for in a design
                                    (through user testing and other methods). It is possible that a design may seem
                                    brilliant to you, but remember that you are not the user.</p>
                                <p class="mb-2 font-18">“All too often I have designed on an
                                    island by making assumptions based on what I think an end user needs, crafting
                                    pixel-perfect mockups based on my assumptions. I’ve learned that the ‘U’ in UX does
                                    not stand for ‘you’! It’s
                                    all about the user, so getting outside of my head and engaging with the user is an
                                    important step in the design process.”</p>
                                <div class="w-50 m-auto text-center p-3"><img
                                        src="./assets/images/training/course-details/what-user-thinks.gif" class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Meet the users’ needs</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse"
                                data-bs-target="#section2" aria-expanded="false" aria-controls="section2"
                                class="accordion-button text-semibold font-18 collapsed"> 2. Know where you are in the
                                design process </button></h2>
                        <div id="section2" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                            class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">For new UX designers who are only just testing the internship
                                    waters or are in junior positions, the design process can be overwhelming. A lot of
                                    work goes into designing, so knowing your place in the process is significant
                                    in several ways.</p>
                                <p class="mb-2 font-18">Firstly, you’ll need to use different tools for each phase, as
                                    demonstrated in the graph below. Secondly, knowing your design phase also helps you
                                    ask the right questions for user research. For instance, there’s is no point
                                    testing the color of a button if you are still figuring out where it should be
                                    placed in the design.</p>
                                <div class="w-50 m-auto text-center p-3"><img
                                        src="./assets/images/training/course-details/know-where.png" class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Know where you are in the
                                        design process</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse"
                                data-bs-target="#section3" aria-expanded="false" aria-controls="section3"
                                class="accordion-button text-semibold font-18 collapsed"> 3. Have a clear hierarchy
                            </button></h2>
                        <div id="section3" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                            class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">It is easy to take hierarchy for granted, but it is a UX
                                    principle that ensures smooth navigation throughout a design. There are two chief
                                    hierarchies that you need to note. First comes the hierarchy that is associated with
                                    how content or information is organized throughout the design.</p>
                                <p class="mb-2 font-18">For example, when you open a website or app, you will note the
                                    navigation bar that includes the main sections. This is the primary hierarchy. When
                                    you click or hover over this bar, you will note further sub-categories of content
                                    open up, taking you deeper into the app or site. These are the secondary menus.</p>
                                <p class="mb-2 font-18">This site map shows primary and secondary menus:</p>
                                <div class="w-50 m-auto text-center p-3"><img
                                        src="./assets/images/training/course-details/clear-hierarchy.png" class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Have a clear hierarchy</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse"
                                data-bs-target="#section4" aria-expanded="false" aria-controls="section4"
                                class="accordion-button text-semibold font-18 collapsed"> 4. Keep it consistent
                            </button></h2>
                        <div id="section4" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                            class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">Users expect products to share some similarities with other
                                    products they regularly use. This makes it easy for them to become familiar with the
                                    new product without any additional learning costs. It may sound a little
                                    counterintuitive,
                                    but the more familiar your design is to others, the faster users can learn to use
                                    it, which enhances their experience.</p>
                                <p class="mb-2 font-18">Such consistency also makes the design process easier for the
                                    designers, as they don’t have to reinvent the wheel every time they take on a new
                                    project. A case in point is the floating action button that has become common among
                                    apps, as seen in the Twitter and Google Docs apps below:</p>\
                                <div class="w-50 m-auto text-center p-3"><img
                                        src="./assets/images/training/course-details/cosistent.jpeg" class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Keep it consistent</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse"
                                data-bs-target="#section5" aria-expanded="false" aria-controls="section5"
                                class="accordion-button text-semibold font-18 collapsed"> 5. Understand accessibility
                            </button></h2>
                        <div id="section5" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                            class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">An increasingly important rule from among the UX design basics
                                    is designing with accessibility in mind. In simple words, a designer’s
                                    responsibility is to make sure their design is usable for as many people as
                                    possible. This
                                    means that your design needs to be accessible to people with disabilities too.</p>
                                <p class="mb-2 font-18">To this end, you should remove obstacles from the design layout
                                    for problem-free navigation. For example, you can use contrasting colors for the
                                    text on the background. This helps visually impaired users (as well as users in
                                    low-light settings) read the content on the screen more easily. Slack excels at this
                                    on its blog:</p>
                                <div class="w-50 m-auto text-center p-3"><img
                                        src="./assets/images/training/course-details/accessibility.png" class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Understand accessibility</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse"
                                data-bs-target="#section6" aria-expanded="false" aria-controls="section6"
                                class="accordion-button text-semibold font-18 collapsed"> 6. Context is key </button>
                        </h2>
                        <div id="section6" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                            class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">When designing, you need to take into account the user’s
                                    context. Location is a commonly understood contextual factor—are you designing for
                                    someone on the go or for someone sitting at a desk? But there are other things to
                                    consider,
                                    including the time available with the user, their emotional state, the device they
                                    are using, the people who influence them, and more.</p>
                                <p class="mb-2 font-18">All these factors help you understand the user’s behavior. Once
                                    you have insight into that, you can prepare a design that maximizes user experience.
                                    For example, the emotional state of a user would impact how patient or impatient
                                    they might be when interacting with the user interface of your product or service,
                                    so you’d want to design with that in mind.</p>
                                <div class="w-50 m-auto text-center p-3"><img
                                        src="./assets/images/training/course-details/context-key.jpeg" class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Context is key</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse"
                                data-bs-target="#section7" aria-expanded="false" aria-controls="section7"
                                class="accordion-button text-semibold font-18 collapsed"> 7. Usability first </button>
                        </h2>
                        <div id="section7" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                            class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">UX design is entirely focused on solving the users’ problems,
                                    which makes usability of the design one of the most crucial user experience design
                                    principles. No matter how aesthetically pleasing your work may be, it won’t strike
                                    a chord with the user unless it is safe and easy to use.</p>
                                <p class="mb-2 font-18">A case in point here is website design. Any site that is
                                    cluttered is bound to lose visitors. As a designer, your job is to make sure that
                                    each icon, button, and snippet of information that is present within the design has
                                    a purpose. Concentrate on clarity by bringing only useful features to the user’s
                                    attention.</p>
                                <p class="mb-2 font-18">Usability is the reason why prominent buttons and a minimalist
                                    design with few elements increase the click-through rate (CTR). Precision Marketing
                                    Group doubled the CTR on their call-to-action button by changing the shape and
                                    size of the button.</p>
                                <p class="mb-2 font-18">Usability testing asks users to interact with the design.
                                    Meanwhile, you take notes to see whether they encounter any problems.</p>
                                <p class="mb-2 font-18">If many people experience similar problems, you will have to
                                    make changes to the design to sort out the usability issue. Keep in mind that design
                                    is an iterative process and it demands improvements in all stages.</p>
                                <p class="mb-2 font-18">Hence, you should conduct usability tests
                                    throughout the UX design process, including before you start your initial design,
                                    during the prototyping phases, and at the end of the process. </p>
                                <div class="w-50 m-auto text-center p-3"><img
                                        src="./assets/images/training/course-details/usability-first.png" class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Usability first</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse"
                                data-bs-target="#section8" aria-expanded="false" aria-controls="section8"
                                class="accordion-button text-semibold font-18 collapsed"> 8. Less is more </button></h2>
                        <div id="section8" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                            class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">The less-is-more design principle was originally proposed by the
                                    architect Ludwig Mies van der Rohe. For UX, the underlying aim of this is simple:
                                    reducing the operational and cognitive costs of the users. In placing value
                                    on this, the design’s usability and consistency improve.</p>
                                <p class="mb-2 font-18">The less-is-more approach emphasizes simplicity as opposed to
                                    clutter or over-decoration in design. Several celebrated designs have surfaced as a
                                    result of this UX design principle, including the iPhone and iPod. In 2007, Apple
                                    streamlined the phone’s keyboard in pursuit of the less-is-more philosophy and the
                                    iPhone was birthed. Apple’s website also follows the same design principle.</p>
                                <div class="w-50 m-auto text-center p-3"><img
                                        src="./assets/images/training/course-details/less-more.png" class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Less is more</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse"
                                data-bs-target="#section9" aria-expanded="false" aria-controls="section9"
                                class="accordion-button text-semibold font-18 collapsed"> 9. Use simple language
                            </button></h2>
                        <div id="section9" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                            class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">Just as simplicity has become a best practice in visual design,
                                    UX-focused copywriting should avoid technical terms and opt for simple language.
                                    Users are busy, they’re on the go, they’re multi-tasking, so use words in your
                                    design that are closest to the user’s thoughts.</p>
                                <p class="mb-2 font-18">Simple language is easy to understand, which enhances your
                                    design’s user-friendliness. </p>
                                <p class="mb-2 font-18">You need to factor in five points as you choose simple words for
                                    your design. These are:</p>
                                <ul class="ps-5 disc-listing">
                                    <li _ngcontent-ghh-c10="">Audience and purpose of the design: question who your
                                        audience is, what information they have, what they need, and what they will want
                                        to achieve with your communication</li>
                                    <li _ngcontent-ghh-c10="">Structure: ask about the common structures for your
                                        communication, what structures your readers are familiar with, and what sequence
                                        will be easy to read</li>
                                    <li _ngcontent-ghh-c10="">Design layout: focus on the typography, layout, and
                                        information graphics</li>
                                    <li _ngcontent-ghh-c10="">Expression: consider the tone, verbs, sentence length,
                                        jargon, and choice of words your communication will adopt</li>
                                    <li _ngcontent-ghh-c10="">Evaluation: have someone review the text you finalize and
                                        conduct a usability test</li>
                                </ul>
                                <p class="mb-2 font-18">Put simply: use clear and consistent words throughout your
                                    design to reduce ambiguity. In this example, the “OK” is unclear. “Yes” is a more
                                    logical choice. Got it? (OK!)</p>
                                <div class="w-50 m-auto text-center p-3"><img
                                        src="./assets/images/training/course-details/simple-language.png" class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Use simple language</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse"
                                data-bs-target="#section10" aria-expanded="false" aria-controls="section10"
                                class="accordion-button text-semibold font-18 collapsed"> 10. Typography is powerful
                            </button></h2>
                        <div id="section10" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                            class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">Equally important to the words you use in your design is
                                    typography. The author of “The Elements of Typographic Style,” Robert Bringhurst,
                                    highlights the role of this UX design principle well. He writes, “Typography is the
                                    craft of endowing human language with a durable visual form.”</p>
                                <p class="mb-2 font-18">Typographic choices can significantly impact the way users
                                    interpret the language you use, helping enhance or suppress the message. Moreover,
                                    typography can improve UX in multiple ways.</p>
                                <p class="mb-2 font-18">For instance, it can improve accessibility and make the design
                                    more user-friendly as you consider a typographic hierarchy. You can take a page from
                                    the online publishing platform Medium’s design. Medium uses specific typography
                                    to make its content more readable.</p>
                                <div class="w-50 m-auto text-center p-3"><img
                                        src="./assets/images/training/course-details/typography-is-powerfull.png"
                                        class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Typography is powerful</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse"
                                data-bs-target="#section11" aria-expanded="false" aria-controls="section11"
                                class="accordion-button text-semibold font-18 collapsed"> 11. Feedback matters </button>
                        </h2>
                        <div id="section11" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                            class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">Design should be interactive by nature. So, when a user clicks
                                    on something, they will need a response from the product to understand that their
                                    command has been received. Feedback is a crucial way to encourage communication
                                    between humans and machines.</p>
                                <p class="mb-2 font-18">Your design may respond in various ways. The clicked icon may
                                    change shape, vibrate, discolor, emit light, and so on. Notice how the color reacts
                                    as you click a link on the Zapier website:</p>
                                <div class="w-50 m-auto text-center p-3"><img
                                        src="./assets/images/training/course-details/feedback-matters.png" class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Feedback matters</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse"
                                data-bs-target="#section12" aria-expanded="false" aria-controls="section12"
                                class="accordion-button text-semibold font-18 collapsed"> 12. Confirm before you commit
                            </button></h2>
                        <div id="section12" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                            class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">Accidents happen all the time. One common digital example: a
                                    person may unintentionally place an order. (How many parents have had to ask for
                                    refunds after their young kids accidentally made a purchase on Amazon or iTunes?)
                                    Your design should help correct this, though, because you don’t want to give the
                                    user a poor experience. This makes confirmation another one of the essential UX
                                    design principles.</p>
                                <p class="mb-2 font-18">Apple asking for confirmation when you empty the trash is one
                                    important way this principle is put into use.</p>
                                <div class="w-50 m-auto text-center p-3"><img
                                        src="./assets/images/training/course-details/confirm-before-commit.png"
                                        class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Feedback matters</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse"
                                data-bs-target="#section13" aria-expanded="false" aria-controls="section13"
                                class="accordion-button text-semibold font-18 collapsed"> 13. The user is in control
                            </button></h2>
                        <div id="section13" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                            class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <h5 class="text-semibold font-24 mb-3"></h5>
                                <p class="mb-2 font-18">User control focuses on greater flexibility of use and better
                                    control of where a user is within a design or product, enhancing user experience.
                                    Furthermore, it allows users to backpedal and recover from errors.</p>
                                <p class="mb-2 font-18">The “upward” icon on a webpage, which
                                    allows you to get to the top of the page, is a good example (see below). Similarly,
                                    the “cancel” button that allows users to abandon the task they were doing also
                                    empowers them. As does
                                    the “undo” button, which saves a user from making an unintended action.</p>
                                <div class="w-50 m-auto text-center p-3"><img
                                        src="./assets/images/training/course-details/user-in-control.png" class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">The user is in control</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse"
                                data-bs-target="#section14" aria-expanded="false" aria-controls="section14"
                                class="accordion-button text-semibold font-18 collapsed"> 14. Design with personality
                            </button></h2>
                        <div id="section14" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                            class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">Your design can attract more users if it showcases a character
                                    that interests the user persona you are designing for. Users find it hard to connect
                                    with a lifeless design, device, or code.</p>
                                <p class="mb-2 font-18">Adding personality to your design gives it the human touch that
                                    makes it more attractive and usable (like the Trello blog). </p>
                                <div class="w-50 m-auto text-center p-3"><img
                                        src="./assets/images/training/course-details/design-with-personality.png"
                                        class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Design with personality</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse"
                                data-bs-target="#section15" aria-expanded="false" aria-controls="section15"
                                class="accordion-button text-semibold font-18 collapsed"> 15. Visual grammarity
                            </button></h2>
                        <div id="section15" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                            class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">Visual grammar has its roots in graphic design, but it sits at
                                    the helm of all visual communication, which plays a significant role in user
                                    experience. Visual grammar consists of everything that makes up the visual elements
                                    of a design: icons, illustrations, patterns, and more.</p>
                                <p class="mb-2 font-18">Break these down and you will get the core elements of planes,
                                    points, and lines, as shown below. Your job as a new designer is to ensure that you
                                    understand these elements better for improved visual grammar.</p>
                                <p class="mb-2 font-18">Focus on one point at a time, starting
                                    from points, then lines, and finally planes. As you improve on this user experience
                                    design principle, you will be able to improve your overall UX design skill set.</p>
                                <div class="w-50 m-auto text-center p-3"><img
                                        src="./assets/images/training/course-details/visual-grammer.png" class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Visual grammarity</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse"
                                data-bs-target="#section16" aria-expanded="false" aria-controls="section16"
                                class="accordion-button text-semibold font-18 collapsed"> 16. Narrative design </button>
                        </h2>
                        <div id="section16" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                            class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">Another one of the most important user experience design
                                    principles is narrative design, or telling a story with your design. Two critical
                                    storytelling elements are time and rhythm. Time is the pacing your design adopts—for
                                    example, how slowly or quickly your narrative is unveiled.</p>
                                <p class="mb-2 font-18">Similarly, rhythm is the pattern of unfolding that your
                                    narrative adopts—for instance, the pattern of the series of screens through which
                                    the entire design opens up. Too slow a pace can bore your user, not giving them
                                    adequate
                                    information to hold their interest.</p>
                                <p class="mb-2 font-18">On the flip side, a fast pace can inundate the user with
                                    information, which can confuse them. Therefore, you need to balance pace and rhythm.
                                    Check out the Big Apple Hot Dogs website to note how the design uses narrative
                                    storytelling:</p>
                                <div class="w-50 m-auto text-center p-3"><img
                                        src="./assets/images/training/course-details/narrative-design.png" class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Narrative design</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>