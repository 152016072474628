<div class="container-fluid pt-5 information-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-12 position-relative">
                <div class="back-btn float-start w-100"></div>
                <div class="heading float-start w-100">
                    <a routerlink="/training-course-details-ux" href="/training-course-details-ux" class="float-start mb-2 cursor-pointer">
                        <i class="ri-arrow-left-circle-line text-secondary font-24 float-start"></i>
                        <span class="float-start ms-2">User-Centered Design Process</span>
                    </a>
                    <h5
                    class="text-black font-30 float-start w-100">UI vs. UX Design: What’s the Difference?</h5>
                </div>
            </div>
            <div class="col-12 mt-3 mb-4">
                <p class="mb-2 font-18">User interface (UI) and user experience (UX) are two words that you might hear mentioned frequently in tech circles (and sometimes interchangeably). But what do the terms actually mean, and what does it mean to be a UX or UI designer? </p>
                <p
                class="mb-2 font-18">UI refers to the screens, buttons, toggles, icons, and other visual elements that you interact with when using a website, app, or other electronic device. UX refers to the entire interaction you have with a product, including how you feel
                    about the interaction. While UI can certainly have an impact on UX, the two are distinct, as are the roles that designers play.</p>
                    <p class="font-18">In this article, we’ll take a closer look at how the roles of UX designer and UI designer overlap and differ, and how to know which you should pursue. Finally, we’ll discuss options for getting started, even if you don’t have a degree
                        or previous experience.</p>
            </div>
            <div class="col-12 mt-3 mb-4">
                <div id="accordionExample" class="accordion">
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#section1" aria-expanded="true" aria-controls="section1" class="accordion-button text-semibold font-18"> Difference between UI and UX </button></h2>
                        <div id="section1" aria-labelledby="headingOne" data-bs-parent="#accordionExample" class="accordion-collapse collapse show">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">Developing a product that people love often requires both good UI and good UX. For example, you could have a banking app that looks great and has intuitive navigation (UI). But if the app loads slowly or makes you click through
                                    numerous screens to transfer money (UX), it doesn’t matter how good it looks. You’re probably not going to want to use it.</p>
                                <p class="mb-2 font-18">On the other hand, a website could be loaded with unique, helpful content organized in a logical and intuitive way. But if it looks dated or you can’t easily figure out how to move between screens or scroll through options,
                                    you’re likely to click away from the site.</p>
                                <div class="w-50 m-auto text-center p-3"><img src="./assets/images/training/course-details/uxvsui.png" class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">UI vs. UX Design</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#section2" aria-expanded="false" aria-controls="section2" class="accordion-button text-semibold font-18 collapsed"> Tasks and responsibilities: What do they do? </button></h2>
                        <div id="section2" aria-labelledby="headingOne" data-bs-parent="#accordionExample" class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">Both UI and UX designers play key roles in the product development lifecycle. Let’s take a closer look at each. </p>
                                <p class="mb-2 font-18">UX designers focus their work on the experience a user has with a product. The goal is to make products that are functional, accessible, and enjoyable to use. While the term UX often applies to digital products, it can also
                                    be applied to non-digital products and services (like a coffee pot or a transportation system). Common tasks for a UX designer might include:</p>
                                <ul class="ps-5 disc-listing">
                                    <li>Conducting user research to identify any goals, needs, behaviors, and pain points involved with a product interaction</li>
                                    <li>Developing user personas based on target customers</li>
                                    <li>Creating user journey maps to analyze how a customer interacts with a product</li>
                                    <li>Building wireframes and prototypes to hone in on what the final product will look like</li>
                                    <li>Performing user testing to validate design decisions and identify problems</li>
                                    <li>Collaborating with stakeholders, UI designers, and developers</li>
                                </ul>
                                <p class="mt-2 font-18">UI designers create the graphical portions of mobile apps, websites, and devices—the elements that a user directly interacts with. Unlike UX, which can apply to just about any product or service, the term UI applies exclusively
                                    to digital products. A UI designer seeks to make apps and websites both visually appealing and easy to navigate. Common tasks of a UI designer include:</p>
                                <ul class="ps-5 disc-listing">
                                    <li>Organizing page layouts</li>
                                    <li>Choosing color palettes and fonts</li>
                                    <li>Designing interactive elements, such as scrollers, buttons, toggles, drop-down menus, and text fields</li>
                                    <li>Making high-fidelity wireframes and layouts to show what the final design will look like</li>
                                    <li>Working closely with developers to convert designs into a working product</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#section3" aria-expanded="false" aria-controls="section3" class="accordion-button text-semibold font-18 collapsed"> Is there such a thing as a UI/UX designer? </button></h2>
                        <div id="section3" aria-labelledby="headingOne" data-bs-parent="#accordionExample" class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">Search for UX on job listing sites, and you’re likely to find companies looking for UI/UX designers. Some companies do sometimes look for candidates with both sets of skills. But often when you start looking more closely at
                                    these listings, you’ll find the role leans more towards one than the other. </p>
                                <p class="mb-2 font-18">When it comes time to begin your job search, pay more attention to the list of tasks or qualifications than the specific job title.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#section4" aria-expanded="false" aria-controls="section4" class="accordion-button text-semibold font-18 collapsed"> Skills </button></h2>
                        <div id="section4" aria-labelledby="headingOne" data-bs-parent="#accordionExample" class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">UI and UX designers have some skills in common, but each role also requires its own unique skill set.</p>
                                <div class="w-50 m-auto text-center p-3"><img src="./assets/images/training/course-details/uxvsui2.png" class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">UI vs. UX Design</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#section5" aria-expanded="false" aria-controls="section5" class="accordion-button text-semibold font-18 collapsed"> Education </button></h2>
                        <div id="section5" aria-labelledby="headingOne" data-bs-parent="#accordionExample" class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">While a degree isn’t always necessary to get a job as a UX or UI designer, having one can often open up new opportunities. Only a few universities offer programs specific to UI/UX. UX designers might get a degree in computer
                                    science, psychology, human-computer interaction, or design. UI designers, on the other hand, might graduate with a degree in digital design, graphic design, or interaction design. </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#section6" aria-expanded="false" aria-controls="section6" class="accordion-button text-semibold font-18 collapsed"> Salary </button></h2>
                        <div id="section6" aria-labelledby="headingOne" data-bs-parent="#accordionExample" class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">According to the 2021 Salary Guide by digital creative staffing agency Onward Search, more than half of UX designers in the US reported making more than $100,400. That figure was $86,800 for UI designers. Your salary could
                                    depend on many factors, including your location, industry, amount of experience, and educational background.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#section7" aria-expanded="false" aria-controls="section7" class="accordion-button text-semibold font-18 collapsed"> How do I know if UI or UX is a better fit for me? </button></h2>
                        <div id="section7" aria-labelledby="headingOne" data-bs-parent="#accordionExample" class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">Both UI and UX design are well-paying careers that are in demand. Which you choose to pursue will depend on your goals and interests. If you’re interested in technology, thrive on variety, and love to solve problems, user experience
                                    design might be a good fit. If you’re a creative thinker with a strong aesthetic sense, consider pursuing user interface design. </p>
                                <p class="mb-2 font-18">If you’re still not sure whether UI or UX is a better fit for you, you can:</p>
                                <ul class="ps-5 disc-listing">
                                    <li>Take a class in each to experience them for yourself</li>
                                    <li>Read or listen to popular UI/UX blogs and podcasts to hear from experts in each field</li>
                                    <li>Reach out to industry professionals on LinkedIn for an informational interview</li>
                                    <li>Join some online design communities to ask questions</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#section8" aria-expanded="false" aria-controls="section8" class="accordion-button text-semibold font-18 collapsed"> Other user experience roles </button></h2>
                        <div id="section8" aria-labelledby="headingOne" data-bs-parent="#accordionExample" class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">The field of UX extends beyond the two roles of UI and UX designers. If you’re interested in a career in UI/UX, consider these other related roles as well.</p>
                                <ul class="ps-5 disc-listing">
                                    <li><span class="text-semibold">UX researchers</span> study the goals, needs, wants, and pain points of a product’s existing and target users.</li>
                                    <li><span class="text-semibold">UX writers</span> write the text that appears on websites, apps, and other digital products.</li>
                                    <li><span class="text-semibold">Developers</span> take the designs from UI and UX designers and code them into usable software, websites, or applications.</li>
                                    <li><span class="text-semibold">Product designers</span> lead the entire process of taking a product or service from idea to reality.</li>
                                    <li><span class="text-semibold">Content strategists</span> oversee the planning and production of marketing content through the lifecycle of a project. </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>