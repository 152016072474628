<div class="sidebar-wrapper-1" data-aos="fade-right" data-aos-duration="1000" >
    <div class="profile-img">
        <img src="./assets/images/profle-pic/pankaj_pede.jpeg" />
    </div>
    <ul class="social-icons m-0 p-0" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="300">
        <li class="float-start w-100">
            <a class="float-start">
                <i class="ri-facebook-fill"></i>
            </a>
        </li>
        <li class="float-start w-100" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="600">
            <a class="float-start">
                <i class="ri-instagram-line"></i>
            </a>
        </li>
        <li class="float-start w-100" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="900">
            <a class="float-start">
                <i class="ri-linkedin-fill"></i>
            </a>
        </li>
        <li class="float-start w-100" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="1200">
            <a class="float-start">
                <i class="ri-pinterest-fill"></i>
            </a>
        </li>
    </ul>
    <div class="download-icon text-center">
        <i class="ri-download-2-line text-white"></i>
    </div>
</div>