<div class="container-fluid pt-5 information-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-12 position-relative">
                <div class="back-btn float-start w-100"></div>
                <div class="heading float-start w-100">
                    <a routerlink="/training-course-details-ux" href="/training-course-details-ux"
                        class="float-start mb-2 cursor-pointer">
                        <i class="ri-arrow-left-circle-line text-secondary font-24 float-start"></i>
                        <span class="float-start ms-2">User-Centered Design Process</span>
                    </a>
                </div>
            </div>
            <div class="col-12 mt-3 mb-4">
                <div id="accordionExample" class="accordion">
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse"
                                data-bs-target="#section1" aria-expanded="true" aria-controls="section1"
                                class="accordion-button text-semibold font-18"> What Is Typography, and Why Is It
                                Important? </button></h2>
                        <div id="section1" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                            class="accordion-collapse collapse show">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">Whether it’s on our phones, in books or on websites, we’re
                                    constantly digesting written words. From instruction booklets to shop fronts, type
                                    is all around us.</p>
                                <p class="mb-2 font-18">We often reflect on the power of the written word, but rarely do
                                    we consider the designer’s role in emulating the tone of the word or sentence.</p>
                                <p class="mb-2 font-18">Behind the scenes, a designer has taken the time to consider the
                                    relationship between the look of the text and what the text says. In reality,
                                    different moods, atmospheres, and even trends can be expressed simply through the
                                    type choice. But what actually is typography—and why is it so vital?</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse"
                                data-bs-target="#section2" aria-expanded="false" aria-controls="section2"
                                class="accordion-button text-semibold font-18 collapsed"> What is typography? </button>
                        </h2>
                        <div id="section2" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                            class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">Let’s kick off with the basics: just what actually is
                                    typography?</p>
                                <p class="mb-2 font-18">Typography is the art of arranging letters and text in a way
                                    that makes the copy legible, clear, and visually appealing to the reader.</p>
                                <p class="mb-2 font-18">It involves font style, appearance, and structure, which aims to
                                    elicit certain emotions and convey specific messages. In short, typography is what
                                    brings the text to life.</p>
                                <p class="mb-2 font-18">In this video, CareerFoundry UI design mentor Olga lifts the lid
                                    on why typography matters—and what difference it makes for your users:</p>
                                <div class="w-50 m-auto text-center p-3">
                                    <iframe width="853" height="480" src="https://www.youtube.com/embed/fcE9GRcxCec"
                                        title="Typography Tutorial: How To Pick The Right Font" frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen=""></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse"
                                data-bs-target="#section3" aria-expanded="false" aria-controls="section3"
                                class="accordion-button text-semibold font-18 collapsed"> A very brief history of
                                typography </button></h2>
                        <div id="section3" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                            class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">Typography can be dated back to the 11th century, during the
                                    innovation of movable type. Before the digital age, typography was a specialized
                                    craft associated with books and magazines, and eventually public works.</p>
                                <p _ngcontent-ghh-c11="" class="mb-2 font-18">The first example of typography can be
                                    seen in the Gutenberg Bible, which kick-started a typography revolution in the west.
                                </p>
                                <p class="mb-2 font-18">Fun fact: the style of type used in the Gutenberg Bible is now
                                    known as Textura, and you’ll find it in the font dropdown menu on major desktop
                                    applications today!</p>
                                <p class="mb-2 font-18">Fast forward to modern day, where typography is mostly
                                    associated with both the digital design world and print.</p>
                                <p class="mb-2 font-18">With the birth of the internet came a creative explosion of the
                                    art of typography. Suddenly, web designers had an abundance of fonts and type
                                    options at their disposal, making typography more visually diverse than ever before.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse"
                                data-bs-target="#section4" aria-expanded="false" aria-controls="section4"
                                class="accordion-button text-semibold font-18 collapsed"> Why is typography important?
                            </button></h2>
                        <div id="section4" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                            class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">Typography is so much more than just choosing beautiful fonts:
                                    it’s a vital component of user interface design.</p>
                                <p class="mb-2 font-18">Good typography will establish a strong visual hierarchy,
                                    provide a graphic balance to the website, and set the product’s overall tone.
                                    Typography should guide and inform your users, optimize readability and
                                    accessibility,
                                    and ensure an excellent user experience.</p>
                                <p class="mb-2 font-18">Let’s delve a little deeper into why typography is so important.
                                </p>
                                <p class="mb-2 font-18 mt-3 mb-2"><span class="text-semibold">Typography builds brand
                                        recognition - </span> Not only will good typography enhance the website’s
                                    personality, but your users will subliminally begin to associate the typeface
                                    featured on your site with your brand. Unique, consistent typography will help you
                                    establish a strong user following, build trust with your users, and help to carry
                                    your brand forward.</p>
                                <p class="mb-2 font-18 mt-3 mb-2"><span class="text-semibold">Typography influences
                                        decision making - </span> Typography has a profound effect on the way that users
                                    digest and perceive the information conveyed by the text. Eye-catching
                                    type is much more persuasive than weak fonts that don’t reinforce the message of the
                                    text.</p>
                                <p class="mb-2 font-18 mt-3 mb-2"><span class="text-semibold">Typography holds the
                                        attention of the readers - </span> Good typography could be the difference
                                    between someone staying on your website for one minute or half an hour. It’s
                                    important that your website is visually stimulating and memorable, and typography
                                    plays a huge role in this process.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse"
                                data-bs-target="#section5" aria-expanded="false" aria-controls="section5"
                                class="accordion-button text-semibold font-18 collapsed"> The different elements of
                                typography </button></h2>
                        <div id="section5" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                            class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">To get started in typography, you first need to get to grips
                                    with the eight essential typographical design elements.</p>
                                <p class="mb-2 font-18">Good typography will establish a strong visual hierarchy,
                                    provide a graphic balance to the website, and set the product’s overall tone.
                                    Typography should guide and inform your users, optimize readability and
                                    accessibility,
                                    and ensure an excellent user experience.</p>
                                <p class="mb-2 font-18">Let’s delve a little deeper into why typography is so important.
                                </p>
                                <p class="mb-2 font-20 text-semibold mt-4 mb-3">Fonts and typefaces - </p>
                                <p class="mb-2 font-18 mt-2">There’s some confusion surrounding the difference between
                                    typefaces and fonts, with many treating the two as synonymous.</p>
                                <p class="mb-2 font-18 mt-2">A typeface is a design style which comprises a myriad of
                                    characters of varying sizes and weight, whereas a font is a graphical representation
                                    of text character.</p>
                                <p class="mb-2 font-18 mt-2">Put simply, a typeface is a family of related fonts, while
                                    fonts refer to the weights, widths, and styles that constitute a typeface.</p>
                                <div class="w-50 m-auto text-center p-3"><img
                                        src="./assets/images/training/course-details/typeface.jpeg" class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Know where you are in the
                                        design process</p>
                                </div>
                                <p class="mb-2 font-18 mt-2">There are three basic kinds of typeface: serif, sans-serif,
                                    and decorative. Here’s a visual example of each:</p>
                                <div class="w-50 m-auto text-center p-3"><img
                                        src="./assets/images/training/course-details/kinds-of-typeface.jpeg"
                                        class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Kinds Of Typeface</p>
                                </div>
                                <p class="mb-2 font-18 mt-3 mb-2"><span class="text-semibold">1. Serif - </span> As the
                                    visual example above demonstrates, serif typefaces are identified by the extra marks
                                    at the end of letters. The addition of these small strokes and
                                    elements give serif fonts an air of tradition, history, authority, and integrity.
                                    It’s no surprise, then, that you’ll see this “classic” style used for newspaper
                                    titles, for example, or for the font used in books. Times
                                    New Roman, the Microsoft Word original font, is a serif font. It was replaced in
                                    2007 by the sans-serif Calibri.</p>
                                <p class="mb-2 font-18 mt-3 mb-2"><span class="text-semibold">2. Sans-serif - </span>
                                    Just like the name suggests, sans-serif typefaces as defined by what they lack.
                                    Without serif’s more traditional strokes and dashes, the sans-serif font
                                    family is seen as much more modern and bold. As a result, it’s clear to read and
                                    when used in headlines, grabs your attention more than serifs. The CareerFoundry
                                    logo, for example, as well as the font used in this blog
                                    article, is sans-serif. Popular sans-serif fonts include Helvetica and Arial, which
                                    is the default font when you start writing in a Google Doc.</p>
                                <p class="mb-2 font-18 mt-3 mb-2"><span class="text-semibold">3. Decorative - </span>
                                    Again given away by its name, the function of this typeface is aesthetic more than
                                    readable. As a result, you’re far more likely to see these used in
                                    brand names, logos, and short titles. Walk around your local grocery or toy store
                                    and you’ll see decorative fonts jumping out at you from every shelf. Just imagine
                                    trying to read an entire article written in them! Decorative
                                    typefaces are excellent for allowing the user to show off even more personality,
                                    feeling, and uniqueness with their font choice.</p>
                                <p class="mb-2 font-20 text-semibold mt-4 mb-1">Contrast - </p>
                                <p class="mb-2 font-18 mb-2">Much like hierarchy, contrast helps to convey which ideas
                                    or message you want to emphasize to your readers. Spending some time on contrast
                                    makes your text interesting, meaningful, and attention-grabbing. Most designers
                                    create
                                    contrast by playing around with varying typefaces, colors, styles, and sizes to
                                    create impact and break up the page.</p>
                                <div class="w-50 m-auto text-center p-3"><img
                                        src="./assets/images/training/course-details/contrast-in-type-1.jpeg"
                                        class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Contrast</p>
                                </div>
                                <p class="mb-2 font-20 text-semibold mt-4 mb-1">Consistency - </p>
                                <p class="mb-2 font-18 mb-2">Keeping your typefaces consistent is key to avoiding a
                                    confusing and messy interface. When conveying information, it’s essential to stick
                                    to the same font style, so your readers instantly understand what they’re reading,
                                    and
                                    begin to notice a pattern. While it’s ok to play around with levels of hierarchy to
                                    some extent, it’s good practice to establish a consistent hierarchy of typefaces
                                    (one consistent font for headers, another for subheadings)
                                    and stick to it.</p>
                                <p class="mb-2 font-20 text-semibold mt-4 mb-1">White space - </p>
                                <p class="mb-2 font-18 mb-2">Often referred to as “negative space,” white space is the
                                    space around text or graphics. It’s often overlooked and tends to go unnoticed by
                                    the user, but proper use of white space ensures the interface is uncluttered and the
                                    text is readable. White space can even draw attention to the text and provides an
                                    overall aesthetically pleasing experience. White space often takes the form of
                                    margins, padding, or just areas with no text or graphics.</p>
                                <div class="w-50 m-auto text-center p-3"><img
                                        src="./assets/images/training/course-details/white-space-in-typog.jpeg"
                                        class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">White space</p>
                                </div>
                                <p class="mb-2 font-20 text-semibold mt-4 mb-1">Alignment - </p>
                                <p class="mb-2 font-18 mb-2">Alignment is the process of unifying and composing text,
                                    graphics, and images to ensure there is equal space, size, and distances between
                                    each element. Many UI designers create margins to ensure that their logo, header,
                                    and body
                                    of the text are aligned with each other. When aligning your user interface, it’s
                                    good practice to pay attention to industry standards. For example, aligning your
                                    text to the right will seem counterintuitive for readers who
                                    read left to right.</p>
                                <p class="mb-2 font-20 text-semibold mt-4 mb-1">Color - </p>
                                <p class="mb-2 font-18 mb-2">Color is one of the most exciting elements of typography.
                                    This is where designers can really get creative and elevate the interface to a new
                                    level. Text color, however, is not to be taken lightly: nailing your font color can
                                    make
                                    the text stand out and convey the tone of the message—but getting it wrong can
                                    result in a messy interface and text that clashes with the site colors. Color has
                                    three key components: value, hue, and saturation. A good designer
                                    will know how to balance these three components to make the text both eye-catching
                                    and clearly legible, even for those with visual impairments. Often, designers will
                                    test this by viewing the text in greyscale (without color)
                                    and making tweaks if the text is too dark or too light against the background color.
                                </p>
                                <p class="mb-2 font-20 text-semibold mt-4 mb-1">Hierarchy - </p>
                                <p class="mb-2 font-18 mb-2">Establishing hierarchy is one of the most vital principles
                                    of typography. Typographical hierarchy aims to create a clear distinction between
                                    prominent pieces of copy that should be noticed and read first, and standard text
                                    copy.
                                    In an age of short attention spans brought about by social media, designers are
                                    urged to be concise and create typefaces that allow users to consume the necessary
                                    information in short amounts of time.</p>
                                <div _ngcontent-ghh-c11="" class="w-50 m-auto text-center p-3"><img
                                        src="./assets/images/training/course-details/hierarchy-of-text.jpeg"
                                        class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Hierarchy</p>
                                </div>
                                <p class="mb-2 font-18 mb-2">Hierarchy can be created using sizing, color, contrast, and
                                    alignment. For example, if you have a line of copy with an exclamation mark icon at
                                    the beginning that is in red and larger than the previous copy, this is a visual
                                    clue
                                    to the readers that it’s a call to action. The most typical example of typographical
                                    hierarchy is size: headings should always be larger than subheadings and standard
                                    text.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse"
                            data-bs-target="#section6" aria-expanded="false" aria-controls="section6"
                            class="accordion-button text-semibold font-18 collapsed"> How do you choose the right
                            typeface for your website? </button></h2>
                    <div id="section6" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                        class="accordion-collapse collapse">
                        <div class="accordion-body">
                            <p class="mb-2 font-18">Now that we’ve familiarised ourselves with what is typography itself
                                as well as its elements, let’s talk about the process of picking typefaces for your
                                interface. Choosing a font for your website is a lot harder than it first
                                seems. With so many different fonts and typefaces to select from, it’s easy to feel
                                overwhelmed. Making the right choice depends on so much more than just seeing what looks
                                nice. Here are a few key considerations:</p>
                            <p _ngcontent-ghh-c11="" class="mb-2 font-20 text-semibold mt-4 mb-1">Think about
                                personality - </p>
                            <p class="mb-2 font-18 mb-2">How do you want your users to feel when they first enter your
                                website? Do you want to emulate a friendly atmosphere? Do you want the site to feel
                                high-end, welcoming, playful, or serious? It’s imperative that the typography reflects
                                the personality of the brand or product. A good starting point when faced with this
                                challenge is to define the core traits of your brand, and start to gather typefaces that
                                reflect these traits. From there, you can begin to
                                notice a trend.</p>
                            <p class="mb-2 font-20 text-semibold mt-4 mb-1">Reflect on tone - </p>
                            <p class="mb-2 font-18 mb-2">It’s equally important to consider how the font harmonizes with
                                the tone of the message. For example, if you want to convey serious or important
                                information, choose a less stylized or decorative font that is both clearly legible
                                and will limit distraction.</p>
                            <p class="mb-2 font-20 text-semibold mt-4 mb-1">Don’t skimp on function - </p>
                            <p class="mb-2 font-18 mb-2">Function is just as vital as form: there’s nothing worse than a
                                website that looks pretty but is entirely illegible, leading you to click the wrong
                                button or take a wrong turn because the instructions were unclear. When deciding
                                what typefaces to include in your interface, set the style, aesthetic, and voice aside
                                and reflect on whether the font is legible, readable, and accessible. Can the text be
                                legible without strain? Are the characters distinct
                                enough?</p>
                            <p class="mb-2 font-20 text-semibold mt-4 mb-1">Consider performance - </p>
                            <p class="mb-2 font-18 mb-2">One thing that many designers often overlook is choosing
                                typefaces that are web browser-friendly. Commonly used font libraries such as Google
                                fonts offer web-based font files that can be rendered perfectly in a browser without
                                any issues. Pro tip: when downloading web fonts, never download more character sets than
                                you need to. This way, you’ll avoid excess weight!</p>
                            <p class="mb-2 font-20 text-semibold mt-4 mb-1">Get inspired - </p>
                            <p class="mb-2 font-18 mb-2">If you’re not sure where to start, take some time to see what
                                other people are doing. Open your eyes to the typography that you see around you. Can
                                you notice similar patterns? Can you see good and bad examples of typography? Even
                                following typography hashtags on social media or looking up typography on Pinterest will
                                give you some good ideas of what’s out there.</p>
                            <p class="mb-2 font-20 text-semibold mt-4 mb-1">Take some time to test - </p>
                            <p class="mb-2 font-18 mb-2">The best way to decipher which font to use for your interface?
                                Test, test, test! By gathering useful feedback from real users, you’ll get a clearer
                                insight into what works, what doesn’t, what is legible and what feels counterintuitive
                                or clunky. In this video, Elizé Todd guides us through the key processes for typesetting
                                and gives us a hands-on tutorial in Adobe XD:</p>
                            <div class="w-50 m-auto text-center p-3">
                                <iframe width="725" height="408" src="https://www.youtube.com/embed/aOMO_ePj3cA"
                                    title="Typography &amp; Typesetting In UI Design (Adobe XD Tutorial)"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen=""></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>