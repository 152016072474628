import { Component, Input, OnInit } from '@angular/core';

import {NgbProgressbarConfig} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-image-text-wrapper2',
  templateUrl: './image-text-wrapper2.component.html',
  styleUrls: ['./image-text-wrapper2.component.scss']
})
export class ImageTextWrapper2Component implements OnInit {

  @Input() imageTextwrapper2Type:string;

  constructor(config: NgbProgressbarConfig) { 
    config.max = 1000;
    config.striped = true;
    config.animated = true;
    config.type = 'success';
    config.height = '20px';
  }
  ngOnInit(): void {
  }

}
