<!-- image-text-wrapper6 start here-->
<section class="image-text-wrapper7 section video-wrapper1 bg-light pt-0 pb-0" *ngIf="videoPlayer1Type=='botStationAi'">
    <div class="container-fluid content-area pt-5 pb-5">
        <div class="row">
            <div class="col-12 col-lg-8 bg-light text-wrapper">
                <h4 class="h2 mb-4 text-center heading" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000">Our customers love us, hear from them</h4>
                <ul class="tag-list">
                    <li class="tag-list-item float-start" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="200">
                        <img src="./assets/images/bot-station-ai/other/Best-Support-2.svg" />
                    </li>
                    <li class="tag-list-item float-start" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="400">
                        <img src="./assets/images/bot-station-ai/other/Easiest-Set-up.svg" />
                    </li>
                    <li class="tag-list-item float-start" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="600">
                        <img src="./assets/images/bot-station-ai/other/High-Performer-1.svg" />
                    </li>
                    <li class="tag-list-item float-start" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="800">
                        <img src="./assets/images/bot-station-ai/other/Leader-1.svg" />
                    </li>
                    <li class="tag-list-item float-start" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="1000">
                        <img src="./assets/images/bot-station-ai/other/users-loves-us.svg" />
                    </li>
                </ul>
                <div class="clearfix"></div>
            </div>
            <div class="col-12 col-lg-4 text-center" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                <div id="instructions" class="video-wrapper">
                    <iframe height="300" width="100%"  id="my_video_1"   
                        src="https://www.youtube.com/embed/O5hShUO6wxs">   
                    </iframe>   
                </div>
                
            </div>
        </div>
    </div>
</section>
<!-- image-text-wrapper6 start here-->
