import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-banner4',
  templateUrl: './top-banner4.component.html',
  styleUrls: ['./top-banner4.component.scss']
})
export class TopBanner4Component implements OnInit {

  @Input() topbanner4Type:string;

  constructor() { }

  ngOnInit(): void {
  }

}
