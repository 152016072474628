
<!-- carousel-image-wrapper1 section start here -->
<section class="container-fluid carousel-image-wrapper4 section info-wrapper-1 chatbot-wrapper mt-5" *ngIf="carouselWrapper4Type=='botStationAi'">
    <div class="container">
      <div class="row">
          <div class="col-12">
              <h4 class="text-center h2 mb-3" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000">You Are in Good, Nah, GREAT Company</h4>
              <p class="text-center text-muted" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="200">See how our customers—from best-in-class enterprises to the most progressive startups—use Verloop.io to delight their customers.</p>
          </div>
        <div class="col-12"  data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="400">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper col">
                <img src="./assets/images/brand-logo/union-bank-of-india.svg" class="m-auto" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper col">
                <img src="./assets/images/brand-logo/infosys-technologies.svg" class="m-auto" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper col">
                <img src="./assets/images/brand-logo/google-1.svg" class="m-auto" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper col">
                <img src="./assets/images/brand-logo/syngenta.svg" class="m-auto" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper col">
                <img src="./assets/images/brand-logo/netflix-1.svg" class="m-auto" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper col">
                <img src="./assets/images/brand-logo/tesla-1.svg" class="m-auto" />
              </div>
            </ng-template>
          </owl-carousel-o>
          <owl-carousel-o [options]="customOptions1" class="carousel-second">
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper col">
                <img src="./assets/images/brand-logo/toshiba-leading-innovation.svg" class="m-auto" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper col">
                <img src="./assets/images/brand-logo/linkedin-1.svg" class="m-auto" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper col">
                <img src="./assets/images/brand-logo/paypal.svg" class="m-auto" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper col">
                <img src="./assets/images/brand-logo/amazon-1.svg" class="m-auto" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper col">
                <img src="./assets/images/brand-logo/apple.svg" class="m-auto" />
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>
  <!-- carousel-image-wrapper1 section end here -->