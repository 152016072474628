
<!-- carousel-image-wrapper1 section start here -->
<section class="container-fluid carousel-image-wrapper3 section info-wrapper-1 bg-light-blue chatbot-wrapper" *ngIf="carouselWrapper3Type=='botStationAi'">
    <div class="container">
      <div class="row">
          <div class="col-12">
              <h4 class="text-center h2 mb-5" data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-duration="1000">Our trusted <span class="chatbot-text-primary">Clients</span></h4>
          </div>
        <div class="col-12" data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="500">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper col">
                <img src="./assets/images/brand-logo/union-bank-of-india.svg" class="m-auto" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper col">
                <img src="./assets/images/brand-logo/infosys-technologies.svg" class="m-auto" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper col">
                <img src="./assets/images/brand-logo/paypal.svg" class="m-auto" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper col">
                <img src="./assets/images/brand-logo/syngenta.svg" class="m-auto" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper col">
                <img src="./assets/images/brand-logo/netflix-1.svg" class="m-auto" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper col">
                <img src="./assets/images/brand-logo/tesla-1.svg" class="m-auto" />
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>
  <!-- carousel-image-wrapper1 section end here -->



  <!-- carousel-image-wrapper1 section start here -->
<section class="container-fluid carousel-image-wrapper3 section info-wrapper-1 bg-light-blue chatbot-wrapper" *ngIf="carouselWrapper3Type=='training'">
  <div class="container">
    <div class="row">
        <div class="col-12">
            <h4 class="text-center h2 mb-5" data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-duration="1000">Our Top <span class="chatbot-text-primary">Placements</span></h4>
        </div>
      <div class="col-12" data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="500">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide>
            <div class="carousel-image-wrapper col">
              <img src="./assets/images/brand-logo/union-bank-of-india.svg" class="m-auto" />
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="carousel-image-wrapper col">
              <img src="./assets/images/brand-logo/infosys-technologies.svg" class="m-auto" />
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="carousel-image-wrapper col">
              <img src="./assets/images/brand-logo/paypal.svg" class="m-auto" />
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="carousel-image-wrapper col">
              <img src="./assets/images/brand-logo/syngenta.svg" class="m-auto" />
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="carousel-image-wrapper col">
              <img src="./assets/images/brand-logo/netflix-1.svg" class="m-auto" />
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="carousel-image-wrapper col">
              <img src="./assets/images/brand-logo/tesla-1.svg" class="m-auto" />
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- carousel-image-wrapper1 section end here -->