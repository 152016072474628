
<!-- carousel-image-wrapper1 section start here -->
<section class="container-fluid carousel-image-wrapper1 section info-wrapper-1 bg-light-blue" *ngIf="carouselWrapper1Type=='corporateBlue'">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="max-699 text-center m-auto">
            <p class="heading-top text-muted small text-style-semibold mb-3" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
              GALLERY
            </p>
            <h2 class="heading mb-4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">App Screenshots</h2>
            <p class="text-font1" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="900">
              Recusandae ducimus, sequi, sapiente possimus optio impedit efficient
              services. Completely promote.
            </p>
          </div>
        </div>
      </div>
  
      <div class="row mt-5 pt-5" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="1300">
        <div class="col-12">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper">
                <img
                  src="./assets/images/devices/carousel-1.png"
                  class="m-auto"
                />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper">
                <img
                  src="./assets/images/devices/carousel-2.png"
                  class="m-auto"
                />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper">
                <img
                  src="./assets/images/devices/carousel-3.png"
                  class="m-auto"
                />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper">
                <img
                  src="./assets/images/devices/carousel-4.png"
                  class="m-auto"
                />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper">
                <img
                  src="./assets/images/devices/carousel-5.png"
                  class="m-auto"
                />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper">
                <img
                  src="./assets/images/devices/carousel-6.png"
                  class="m-auto"
                />
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>
  <!-- carousel-image-wrapper1 section end here -->