
<!-- Help-wrapper1 section start here -->
<section class="container-fluid help-wrapper-1 section bg-blue bg-dotted-white" *ngIf="helpwrapper1Type=='corporateBlue'">
    <div class="max-699 m-auto">
      <div class="row">
        <div class="col-12 text-center">
          <div class="top-icon-wrapper">
            <i class="ri-hand-heart-fill text-white" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200"></i>
            <h4 class="heading h2 text-white mt-3 fw-bold" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">Need Help?</h4>
            <p class="text-white mt-3" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="900">
              Enthusiastically morph unique web-readiness via impactful platforms.
            </p>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12">
          <div class="card bg-white shadow-lg border-0" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1300">
            <div class="card-body p-5">
              <div class="form-widget" data-loader="button" data-alert-type="inline">
                <form class="row mb-0 form-wrapper-1" id="landing-enquiry" novalidate="novalidate">
                  <div class="col-12 form-group mb-4">
                    <label>Name:</label>
                    <input type="text" name="landing-enquiry-name" id="landing-enquiry-name" 
                    class="form-control required" value="" placeholder="John Doe"/>
                  </div>
                  <div class="col-12 form-group mb-4">
                    <label>Email:</label>
                    <input type="email" name="landing-enquiry-email" id="landing-enquiry-email" 
                    class="form-control required" value="" placeholder="user@company.com" />
                  </div>
                  <div class="col-12 form-group mb-4">
                    <label>Phone:</label><br />
                    <div class="input-group input-group-lg">
                      <select class="custom-select required valid" name="landing-enquiry-idd" 
                      id="landing-enquiry-idd" style="max-width: 80px" aria-invalid="false" >
                        <option value="+1">+1</option>
                        <option value="+44">+44</option>
                        <option value="+66">+66</option>
                        <option value="+62">+62</option>
                        <option value="+61">+61</option>
                        <option value="+852">+852</option>
                        <option value="+65">+61</option>
                        <option value="+33">+61</option>
                        <option value="+49">+49</option>
                      </select>
                      <input type="text" name="landing-enquiry-phone" id="landing-enquiry-phone" 
                      class="form-control required valid" value="" placeholder="02-232-2424" 
                      autocomplete="off" />
                    </div>
                  </div>
                  <div class="col-12 form-group mb-4">
                    <!-- <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
              [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
              [selectedCountryISO]="CountryISO.UnitedKingdom" [phoneValidation]="true" name="phone" formControlName="phone">
          </ngx-intl-tel-input> -->
                  </div>
                  <div class="col-12 form-group mb-4">
                    <label>Message:</label>
                    <textarea name="landing-enquiry-additional-requirements" 
                    id="landing-enquiry-additional-requirements" class="form-control" 
                    cols="30" rows="5" placeholder="Please let us know how we can help you..." ></textarea>
                  </div>
                  <div class="col-12">
                    <button
                      type="submit"
                      name="landing-enquiry-submit"
                      class="btn btn-primary btn-hover-moveUp w-100 rounded-3 py-3 fw-semibold text-uppercase mt-2 " >
                      Contact Us
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Help-wrapper1 section end here -->