import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-image-text-wrapper1',
  templateUrl: './image-text-wrapper1.component.html',
  styleUrls: ['./image-text-wrapper1.component.scss']
})
export class ImageTextWrapper1Component implements OnInit {

  @Input() imageTextwrapper1Type:string;

  constructor() { }

  ngOnInit(): void {
  }

}
