<div class="info-wrapper3 text-center section">
  <div class="profile-img" data-aos="fade-up" data-aos-duration="1000">
    <img src="./assets/images/profle-pic/pankaj_pede.jpeg" />
    <p class="mt-4 text-secondary" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">Hello There,</p>
    <h4 class="h2 fw-bold mt-3" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="800">I'M PANKAJ PEDE</h4>
  </div>
  <div class="text-wrapper" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1200">
    <div class="float-start w-100 text-center mt-5">
        <span class="tw w-100 heading" #tw1> </span>
    </div>
    <div class="float-start w-100 text-center mt-2">
        <span #tw2 class="w-100 sub-heading"></span>
    </div>
    <div class="float-start w-100 text-center mt-5">
        <h1 class="tw w-100 heading" #tw3></h1>
    </div>
    <div class="float-start w-100 text-center mt-2">
        <h1 class="tw w-100 sub-heading" #tw4></h1>
    </div>

  </div>
</div>
