import { ViewChild } from '@angular/core';
import { Component, HostListener, OnInit, Input } from '@angular/core';

import { Modal1Component } from '../../../common/modal/modal1/modal1.component';  
import { Modal2Component } from '../../../common/modal/modal2/modal2.component';  
@Component({
  selector: 'app-header3',
  templateUrl: './header3.component.html',
  styleUrls: ['./header3.component.scss']
})
export class Header3Component implements OnInit {

  @Input() header3Type:string;

  @ViewChild('appDialog') appDialog: Modal1Component;
  constructor() { }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop < 20 ||     
    document.documentElement.scrollTop < 20) {
      document.getElementById('header3').classList.remove('scrolled');
      document.getElementById('header3').classList.add('noscrolled');
    }
    if (document.body.scrollTop > 20 ||     
    document.documentElement.scrollTop > 20) {
      document.getElementById('header3').classList.remove('noscrolled');
      document.getElementById('header3').classList.add('scrolled');
    }
  }

  ngOnInit(): void {
  }

}
