
<!-- image-text-wrapper4 section start here -->
<section class="container image-text-wrapper4 section" *ngIf="imageTextwrapper4Type=='corporateBlue'">
    <div class="row">
      <div class="col-md-5 p-0 right-section text-center">
        <h2 class="h2 mb-4 fw-bold" data-aos="fade-up" data-aos-duration="1000">What is canvas</h2>
        <img src="./assets/images/devices/mobile-highlist-section.jpeg" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400" />
      </div>
      <div class="col-md-6 left-section pt-5 offset-md-1">
        <ul class="text-wrapper">
          <li class="pt-4 pb-4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1200">
            <p>Professionally scale superior vortals and proactive relationships. Progressively network multidisciplinary supply chains vis-a-vis standardized leadership skills. Assertively maximize efficient mindshare via cross-media technologies. Dynamically create viral products for innovative metrics. Continually.</p>
          </li>
          <li class="pt-4 pb-4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1600">
            <p>Seamlessly redefine client-focused customer service with extensive infomediaries. Monotonectally brand unique expertise after diverse imperatives. Intrinsicly formulate client-based solutions without excellent imperatives. Globally empower go forward paradigms before effective relationships. Professionally.</p>
          </li>
          <li class="pt-4 pb-4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="2000">
            <p>Efficiently maximize web-enabled applications rather than visionary internal or "organic" sources. Quickly foster extensible experiences vis-a-vis highly efficient technology. Seamlessly repurpose client-based internal or "organic" sources through covalent content. Energistically streamline.</p>
          </li>
        </ul>
        
      </div>
    </div>
  </section>
  <!-- image-text-wrapper4 section end here -->