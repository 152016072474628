
<section class="container-fluid section why-wrapper1" *ngIf="whyWrappre1Type=='botStationAi'">
    <div class="row">
        <div class="col-12 text-center">
            <h2 class="heading fw-bold mb-3 h2 text-white" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="200">
                Why  
              <span class="text-white fw-bold">Bot Station AI</span> for Website?
            </h2>
            <p class="text-white" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="400">Experience the power of conversational AI customer support with the best chatbot for Website</p>
        </div>
    </div>
    <div class="container pt-5">
        <div class="row">
            <div class="col-md-3 col-12 mb-4" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="600">
                <div class="card why-card whycard-primarydark text-center">
                    <div class="card-body">
                        <div class="float-start w-100"> 
                            <i class="ri-hand-heart-line"></i>
                            <p>Greet All Visitors</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-12 mb-4" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="600">
                <div class="card why-card whycard-warning text-center">
                    <div class="card-body">
                        <div class="float-start w-100"> 
                            <i class="ri-user-add-line"></i>
                            <p>Generate More Leads</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-12 mb-4" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="600">
                <div class="card why-card whycard-success text-center">
                    <div class="card-body">
                        <div class="float-start w-100"> 
                            <i class="ri-wechat-2-line"></i>
                            <p>Give Quick Replies</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-12 mb-4" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="600">
                <div class="card why-card whycard-cyan text-center">
                    <div class="card-body">
                        <div class="float-start w-100"> 
                            <i class="ri-24-hours-line"></i>
                            <p>Provide 24×7 Support</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-3 col-12 mb-4" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="800">
                <div class="card why-card whycard-cyan text-center">
                    <div class="card-body">
                        <div class="float-start w-100"> 
                            <i class="ri-line-chart-line"></i>
                            <p>Increase Interactions</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-12 mb-4" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="800">
                <div class="card why-card whycard-success text-center">
                    <div class="card-body">
                        <div class="float-start w-100"> 
                            <i class="ri-install-line"></i>
                            <p>Easy to Install</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-12 mb-4" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="800">
                <div class="card why-card whycard-warning text-center">
                    <div class="card-body">
                        <div class="float-start w-100"> 
                            <i class="ri-pie-chart-line"></i>
                            <p>Reduce Ops Costs</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-12 mb-4" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="800">
                <div class="card why-card whycard-primarydark text-center">
                    <div class="card-body">
                        <div class="float-start w-100"> 
                            <i class="ri-medal-line"></i>
                            <p>Customise as per Brand</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>