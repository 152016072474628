
<div class="info-wrapper2 section" *ngIf="infpWrapper2Type=='corporateBlue'">
    <div class="start-your-journey max-699 m-auto text-center bg-white p-5" data-aos="fade-up" data-aos-duration="1000">
        <h2 class="h1 fw-bold" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">Start your journey 
            <br/>
            right now
        </h2>
        <p class="small mt-4 mb-4 text-muted" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="800">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptate sequi natus autem non temporibus aperiam, nesciunt iusto, perspiciatis.</p>
        <a href="#" class="btn me-sm-3 mt-2 text-white bg-dark rounded-3 px-4 py-3 nott ls0 shadow-sm 
        btn-hover-moveUp" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1200">
            <i class="ri-google-play-fill"></i> 
            App Store
        </a>
        <a href="#" class="ms-0 mt-2 btn text-white bg-dark rounded-3 px-4 py-3 nott ls0 shadow-sm 
        btn-hover-moveUp" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1200">
            <i class="ri-apple-fill"></i> 
            Play Store
        </a>
    </div>
</div>