<!-- image-text-wrapper6 start here-->
<section class="image-text-wrapper6 section chatbot-wrapper" *ngIf="imageTextwrapper6Type=='botStationAi'">
    <div class="container content-area">
        <div class="row">
            <div class="col-12 col-md-6">
                <h4 class="h2 mb-4" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000">Simple Platform to<br/> <span class="chatbot-text-primary">Build & Deploy</span> Bot Station AI</h4>
                <ul class="info-list-wrapper">
                    <li class="info-list-item float-start w-100" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="200">
                        <div class="icon-wrapper float-start">
                            <img src="./assets/images/icon/bot-builder.svg" />
                        </div>
                        <div class="text-wrapper float-start">
                            <h4 class="list-heading h5">Bot Builder</h4>
                            <p class="text-muted">Create your chatbot with ease by moving customizable 
                                elements over the scenario. Drag and drop responses and 
                                actions to compose the best conversational experience.</p>
                        </div>
                    </li>
                    <li class="info-list-item float-start w-100" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="400">
                        <div class="icon-wrapper float-start">
                            <img src="./assets/images/icon/NLP-engine.svg" />
                        </div>
                        <div class="text-wrapper float-start">
                            <h4 class="list-heading h5">NLP with conversational engine</h4>
                            <p class="text-muted">Create a bot that fully automates conversations at every 
                                stage of your marketing and sales funnel.</p>
                        </div>
                    </li>
                    <li class="info-list-item float-start w-100" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="600">
                        <div class="icon-wrapper float-start">
                            <img src="./assets/images/icon/chatbot-voicebot.svg" />
                        </div>
                        <div class="text-wrapper float-start">
                            <h4 class="list-heading h5">Chatbot & voicebot</h4>
                            <p class="text-muted">Build your custom AI chatbot in minutes. No technical skills 
                                needed.</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-12 col-md-6 text-center bg-shape-1">
                <img src="./assets/images/devices/home_build_deploy.svg" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000"/>
            </div>
        </div>
    </div>
</section>
<!-- image-text-wrapper6 start here-->


<!-- image-text-wrapper6 image on left side start here-->
<section class="image-text-wrapper6 section chatbot-wrapper animate__animated animate__fadeInDown" *ngIf="imageTextwrapper6aType=='botStationAi'">
    <div class="container content-area">
        <div class="row">
            <div class="col-12 col-md-6 text-center bg-shape-2">
                <img src="./assets/images/undraw/home_ai_chat.svg" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000"/>
            </div>
            <div class="col-12 col-md-6">
                <h4 class="h2 mb-4" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000">Build your custom <br/> <span class="chatbot-text-primary">Bot Station AI</span> in minutes</h4>
                <ul class="info-list-wrapper">
                    <li class="info-list-item float-start w-100" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="200">
                        <div class="icon-wrapper float-start">
                            <img src="./assets/images/icon/real-time-analytics.svg" />
                        </div>
                        <div class="text-wrapper float-start">
                            <h4 class="list-heading h5">Real-time analytics</h4>
                            <p class="text-muted">Engagely’s AI powered bots engage with visitors to 
                                understand their requirements and intents quickly. </p>
                        </div>
                    </li>
                    <li class="info-list-item float-start w-100" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="400">
                        <div class="icon-wrapper float-start">
                            <img src="./assets/images/icon/security.svg" />
                        </div>
                        <div class="text-wrapper float-start">
                            <h4 class="list-heading h5">Security</h4>
                            <p class="text-muted">Banking grade security which is in line with some of the 
                                most stringent regulatory requirements</p>
                        </div>
                    </li>
                    <li class="info-list-item float-start w-100" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="600">
                        <div class="icon-wrapper float-start">
                            <img src="./assets/images/icon/machineLearning.svg" />
                        </div>
                        <div class="text-wrapper float-start">
                            <h4 class="list-heading h5">Machine learning</h4>
                            <p class="text-muted">ML recommendation engines, and omnichannel capabilities 
                                deliver a world class engagement. It has transformed the way 
                                brands engage with customers</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
<!-- image-text-wrapper6 image on left side start here-->


<!-- image-text-wrapper6 image on left side start here-->
<section class="image-text-wrapper6 section chatbot-wrapper" *ngIf="imageTextwrapper6bType=='botStationAi'">
    <div class="container content-area">
        <div class="row">
            <div class="col-12 col-md-6">
                <h4 class="h2 mb-4" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000">Modernizing customized<br/> <span class="chatbot-text-primary">Customer Suport</span> easily</h4>
                <ul class="info-list-wrapper">
                    <li class="info-list-item float-start w-100" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="200">
                        <div class="icon-wrapper float-start">
                            <img src="./assets/images/icon/middle-east-dilect.svg" />
                        </div>
                        <div class="text-wrapper float-start">
                            <h4 class="list-heading h5">Middle east dialect</h4>
                            <p class="text-muted">Be with your customers throughout their journey & deliver a 
                                real-time engagement experience. This will not only make the 
                                customer experience better but also shorten the sales cycle.</p>
                        </div>
                    </li>
                    <li class="info-list-item float-start w-100" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="400">
                        <div class="icon-wrapper float-start">
                            <img src="./assets/images/icon/text-to-speech.svg" />
                        </div>
                        <div class="text-wrapper float-start">
                            <h4 class="list-heading h5">Text to speech</h4>
                            <p class="text-muted">Based on your customer’s behavior on your website, chatbot 
                                ML models understand which product a customer is looking 
                                for, this will help in making the product discovery in a few 
                                seconds.</p>
                        </div>
                    </li>
                    <li class="info-list-item float-start w-100" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="600">
                        <div class="icon-wrapper float-start">
                            <img src="./assets/images/icon/text-to-speech.svg" />
                        </div>
                        <div class="text-wrapper float-start">
                            <h4 class="list-heading h5">Speech to text</h4>
                            <p class="text-muted">Facilitate smooth end to end transition from product/service 
                                discovery, buying, to post-purchase support to the customers.</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-12 col-md-6 text-center bg-shape-3">
                <img src="./assets/images/undraw/refined.svg" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000"/>
            </div>
        </div>
    </div>
</section>
<!-- image-text-wrapper6 image on left side start here-->