import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-text-wrapper5',
  templateUrl: './image-text-wrapper5.component.html',
  styleUrls: ['./image-text-wrapper5.component.scss']
})
export class ImageTextWrapper5Component implements OnInit {

  @Input() imageTextwrapper5Type:string;

  constructor() { }

  ngOnInit(): void {
  }

}
