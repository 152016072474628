import { Component, OnInit, ViewChild } from '@angular/core';
import Typewriter from 't-writer.js';
@Component({
  selector: 'app-info-wrapper3',
  templateUrl: './info-wrapper3.component.html',
  styleUrls: ['./info-wrapper3.component.scss']
})
export class InfoWrapper3Component implements OnInit {

  @ViewChild('tw1', { static: true }) typewriterElement1;
  @ViewChild('tw2', { static: true }) typewriterElement2;
  @ViewChild('tw3', { static: true }) typewriterElement3;
  @ViewChild('tw4', { static: true }) typewriterElement4;
  constructor() { }

  ngOnInit(): void {
    const target1 = this.typewriterElement1.nativeElement
    const target2 = this.typewriterElement2.nativeElement;
    const target3 = this.typewriterElement3.nativeElement;
    const target4 = this.typewriterElement4.nativeElement;

    const writer1 = new Typewriter(target1, {
      loop: false,
    })

    writer1
      .type(`UX Tools`)
      .rest(500)
      .start()

    const writer2 = new Typewriter(target2, {
      // typeColor: '#',
    })

    writer2
      .type('Adobe Photoshop, Adobe Illustrator, Adobe XD, Invision, Figma')
      // .removeCursor()
      // .then(writer3.start.bind(writer3))
      // .start()
      // .rest(500)
      .start()

    const writer3 = new Typewriter(target3, {
      // typeColor: 'red'
    })
    writer3
      .type("Development Technologies")
      // .rest(500)
      .start()

      const writer4 = new Typewriter(target4, {
        // typeColor: 'blue'
      })

    writer4
    .type(`HTML/HTML5, CSS/CSS3/SCSS, JavaScript/Jquery, Angular, GIT`)
    .rest(500)
    .start()

    // .rest(500)
    // .clear()
    // .changeTypeColor('red')
    // .type("defy user expectations")
    // .rest(500)
    // .clear()
    // .changeTypeColor('blue')
    // .type("generate a custom loop")
    // .rest(500)
    // .clear()
    // .changeTypeColor('black')
    // .then(writer2.start.bind(writer2))

  }

}
