

<!-- info-wrapper-1 wrapper start here -->
<section class="container-fluid section info-wrapper-1" *ngIf="infpWrapper1Type=='corporateBlue'">
    <div class="row">
      <div class="col-12">
        <div class="max-699 text-center m-auto">
          <p class="heading-top text-muted small text-style-semibold mb-3" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            WHY YOU CHOOSE US?
          </p>
          <h2 class="heading mb-4"  data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">Powerful & Best Features for Canvas App</h2>
          <p class="text-font125" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">
            Dynamically underwhelm end-to-end experiences for premier
            methodologies. Objectively benchmark front-end bandwidth vis-a-vis
            flexible e-services. Globally drive business convergence without
            backward-compatible markets.
          </p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="max-992 m-auto">
        <div class="row pt-5">
          <div class="col-12 col-md-6 info-item" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="1000">
            <div class="icon-wrapper">
              <i class="ri-map-pin-line text-primary"></i>
            </div>
            <div class="text-wrapper">
              <h4 class="sub-heading mt-2 mb-2">Anywhere & Anytime</h4>
              <p>
                Appropriately reconceptualize timely convergence through resource
                maximizing collaboration and idea-sharing. Globally coordinate
                cross-platform products via interoperable models.
              </p>
            </div>
          </div>
          <div class="col-12 col-md-6 info-item" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="1000">
            <div class="icon-wrapper">
              <i class="ri-bank-card-2-line text-danger"></i>
            </div>
            <div class="text-wrapper">
              <h4 class="sub-heading mt-2 mb-2">International Transactions</h4>
              <p>
                Rapidiously actualize wireless benefits before resource-leveling
                quality vectors. Dramatically innovate progressive convergence
                without tactical schemas. Competently unleash distributed users
                whereas.
              </p>
            </div>
          </div>
          <div class="col-12 col-md-6 info-item" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="1000">
            <div class="icon-wrapper">
              <i class="ri-shield-check-line text-warning"></i>
            </div>
            <div class="text-wrapper">
              <h4 class="sub-heading mt-2 mb-2">100% Secured & Trustable</h4>
              <p>
                Appropriately redefine market positioning leadership skills
                whereas client-based "outside the box" thinking. Monotonectally
                engage next-generation leadership skills without one-to-one
                testing procedures.
              </p>
            </div>
          </div>
          <div class="col-12 col-md-6 info-item" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="1000">
            <div class="icon-wrapper">
              <i class="ri-book-mark-line text-cyan"></i>
            </div>
            <div class="text-wrapper">
              <h4 class="sub-heading mt-2 mb-2">Detailed Documentation</h4>
              <p>
                Compellingly evisculate impactful e-services for extensible
                resources. Interactively grow timely e-commerce rather than
                optimal expertise. Uniquely optimize impactful experiences rather
                than cooperative.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- info-wrapper-1 wrapper end here -->