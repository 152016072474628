
<li class="nav-item" *ngIf="modal1Type=='botStationAi'">
    <a class="nav-link text-white-50" aria-current="page" (click)="loginModalStatusClick()">Start 14 day’s trail</a>
</li>

<div class="modal fade modal1-wrapper" [ngClass]="loginModalStatus ? 'show' : 'hide'" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog" role="document" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="1000" data-aos-delay="200">
	  <div class="modal-content">
		<div class="modal-header">
            <div class="header-title w-100">
                <div class="logo float-start me-3">
                    <img src="./assets/images/bot-station-ai/logo/Logo1.svg" />
                </div>
				<div class="text float-start">
					<span class="main-text w-100 float-start">Bot Station AI</span>
					<span class="sub-text text-primary w-100 float-start mt-2">Request to start 14 day's trail</span>
				</div>
				<div class="close cursor-pointer" (click)="loginModalStatusClick()" >
					<span aria-hidden="true">&times;</span>
				</div>
            </div>
		</div>
		<div class="modal-body">
			<!-- Step-1 start here -->
			<div class="chat-message float-start w-100 mb-3" [ngClass]="step1Status ? 'show' : 'hide'">
				<div class="message-content float-start">
					<div class="logo float-start">
						<img src="./assets/images/bot-station-ai/logo/Logo1.svg" />
					</div>
					<div class="message-body float-start">
						<p>You are doing a good thing today,</p>
						<p>To create your 14 day’s free trial portal will you please provide us few details.</p>
					</div>
				</div>
			</div>
			<div class="chat-message float-start w-100 mb-3" [ngClass]="step1Status ? 'show' : 'hide'">
				<div class="message-content float-start">
					<div class="logo float-start">
						<img src="./assets/images/bot-station-ai/logo/Logo1.svg" />
					</div>
					<div class="message-body float-start">
						<p>What is you first name?</p>
					</div>
				</div>
			</div>
			<!-- Step-2 start here -->
			<div class="chat-reply float-start w-100 mb-3" [ngClass]="step2Status ? 'show' : 'hide'">
				<div class="message-content float-end">
					<p class="mb-0 text-capitalize">{{value1}}</p>
				</div>
			</div>
			<div class="chat-message float-start w-100 mb-3" [ngClass]="step2Status ? 'show' : 'hide'">
				<div class="message-content float-start">
					<div class="logo float-start">
						<img src="./assets/images/bot-station-ai/logo/Logo1.svg" />
					</div>
					<div class="message-body float-start">
						<p>Hello <span class="text-capitalize">{{value1}}</span>..!! </p>
						<p>What is you last name?</p>
					</div>
				</div>
			</div>
			<!-- Step-3 start here -->
			<div class="chat-reply float-start w-100 mb-3" [ngClass]="step3Status ? 'show' : 'hide'">
				<div class="message-content float-end">
					<p class="mb-0 text-capitalize">{{value2}}</p>
				</div>
			</div>
			<div class="chat-message float-start w-100 mb-3" [ngClass]="step3Status ? 'show' : 'hide'">
				<div class="message-content float-start">
					<div class="logo float-start">
						<img src="./assets/images/bot-station-ai/logo/Logo1.svg" />
					</div>
					<div class="message-body float-start">
						<p>So you full name is <span class="text-capitalize">{{value1}} {{value2}}</span>..!! </p>
						<p>What is you work email address? Which you can user to login to your 14 day’s trail portal.</p>
					</div>
				</div>
			</div>
			<!-- Step-4 start here -->
			<div class="chat-reply float-start w-100 mb-3" [ngClass]="step4Status ? 'show' : 'hide'">
				<div class="message-content float-end">
					<p class="mb-0 text-lowercase">{{value3}}</p>
				</div>
			</div>
			<div class="chat-message float-start w-100 mb-3" [ngClass]="step4Status ? 'show' : 'hide'">
				<div class="message-content float-start">
					<div class="logo float-start">
						<img src="./assets/images/bot-station-ai/logo/Logo1.svg" />
					</div>
					<div class="message-body float-start">
						<p>We will use <span class="fw-bold text-lowercase">{{value3}}</span> for further notifications..!! </p>
						<p>Set your password now.</p>
						<p>We recommend a minimum of 8 characters that include at least 1 letter, number and special character.</p>
					</div>
				</div>
			</div>
			<!-- Step-5 start here -->
			<div class="chat-reply float-start w-100 mb-3" [ngClass]="step5Status ? 'show' : 'hide'">
				<div class="message-content float-end">
					<p class="mb-0">{{value4}}</p>
				</div>
			</div>
			<div class="chat-message float-start w-100 mb-3" [ngClass]="step5Status ? 'show' : 'hide'">
				<div class="message-content float-start">
					<div class="logo float-start">
						<img src="./assets/images/bot-station-ai/logo/Logo1.svg" />
					</div>
					<div class="message-body float-start">
						<p>Confirm password please.</p>
					</div>
				</div>
			</div>
			<div class="float-start w-100 border-top pt-3 btn-wrapper" [ngClass]="step1btnStatus ? 'show' : 'hide'">
				<input type="text" class="float-start border-0" placeholder="Type your message here" (keyup)="onKey1($event)">
				<button type="button" class="btn btn-primary float-end" (click)="step1()">
					<i class="ri-send-plane-line"></i> 
					<span>Send</span>
				</button>
			</div>
			<div class="float-start w-100 border-top pt-3 btn-wrapper" [ngClass]="step2btnStatus ? 'show' : 'hide'">
				<input type="text" class="float-start border-0" placeholder="Type your message here" (keyup)="onKey2($event)">
				<button type="button" class="btn btn-primary float-end" (click)="step2()">
					<i class="ri-send-plane-line"></i> 
					<span>Send</span>
				</button>
			</div>
			<div class="float-start w-100 border-top pt-3 btn-wrapper" [ngClass]="step3btnStatus ? 'show' : 'hide'">
				<input type="text" class="float-start border-0" placeholder="Type your message here" (keyup)="onKey3($event)">
				<button type="button" class="btn btn-primary float-end" (click)="step3()">
					<i class="ri-send-plane-line"></i> 
					<span>Send</span>
				</button>
			</div>
			<div class="float-start w-100 border-top pt-3 btn-wrapper" [ngClass]="step4btnStatus ? 'show' : 'hide'">
				<input type="text" class="float-start border-0" placeholder="Type your message here" (keyup)="onKey4($event)">
				<button type="button" class="btn btn-primary float-end" (click)="step4()">
					<i class="ri-send-plane-line"></i> 
					<span>Send</span>
				</button>
			</div>
			<div class="float-start w-100 border-top pt-3 btn-wrapper" [ngClass]="step5btnStatus ? 'show' : 'hide'">
				<input type="text" class="float-start border-0" placeholder="Type your message here" (keyup)="onKey5($event)">
				<button type="button" class="btn btn-primary float-end" (click)="step5()">
					<i class="ri-send-plane-line"></i> 
					<span>Send</span>
				</button>
			</div>
			<!-- Step-6 start here -->
			<div class="float-start w-100 text-center signup-success" [ngClass]="step6Status ? 'show' : 'hide'">
				<img src="./assets/images/undraw/order_confirmed.svg" />
				<h4 class="h4 mt-3 mb-3 text-primary">14 Day's trail started successfully..!!</h4>
				<p class="mt-3">Please login to explore more features</p>
			</div>
		</div>
	  </div>
	</div>
</div>