import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-modal2',
  templateUrl: './modal2.component.html',
  styleUrls: ['./modal2.component.scss']
})
export class Modal2Component implements OnInit {

  @Input() modal2Type:string;

  loginModalStatus: boolean = false;

  constructor() { }


  loginModalStatusClick(){
    this.loginModalStatus = !this.loginModalStatus;
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });      
  }

  ngOnInit(): void {
  }

}
