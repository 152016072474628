
<!-- image-text-wrapper2 section start here -->
<section class="container image-text-wrapper2 section" *ngIf="imageTextwrapper2Type=='corporateBlue'">
    <div class="row">
      <div class="col-md-5 p-0 right-section text-center" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
        <img src="./assets/images/devices/mobile-highlist-section.jpeg" />
      </div>
      <div class="col-md-6 left-section pt-5 offset-md-1">
        <div class="w-100 float-start">
          <i class="ri-file-text-line text-primary h1 mb-3 float-start" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500"></i>
        </div>
        <div class="float-start w-100" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="900">
          <h4 class="h2 fw-bold mb-4">Version 6.0 is now 2x Faster.</h4>
          <p class="mb-4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1300">
            Enthusiastically morph unique web-readiness via impactful platforms.
            Intrinsicly matrix premium expertise for diverse expertise.
            Intrinsicly drive collaborative bandwidth for accurate testing.
          </p>
        </div>
        <div class="float-start w-100" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1700">
          <p class="small fw-bold">VERSION 6.0</p>
          <ngb-progressbar [value]="500" type="primary"></ngb-progressbar>
          <span class="progress-content text-white fw-bold">60% Optimized</span>
        </div>
        <div class="float-start w-100 mt-5" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="2200">
          <a href="#" class="btn btn-primary p-3 btn-hover-moveUp"
            >Learn More <i class="ri-arrow-right-line"></i
          ></a>
        </div>
      </div>
    </div>
  </section>
  <!-- image-text-wrapper2 section end here -->


  <!-- image-text-wrapper2 section start here -->
<section class="container image-text-wrapper2 section pb-0" *ngIf="imageTextwrapper2Type=='botStationAi'">
  <div class="row">
    <div class="col-md-5 p-0 right-section text-center">
      <img src="./assets/images/undraw/firmware.svg" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" />
    </div>
    <div class="col-md-6 left-section pt-5 offset-md-1">
      <div class="w-100 float-start">
        <i class="ri-file-text-line text-primary h1 mb-3 float-start" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000"></i>
      </div>
      <div class="float-start w-100">
        <h4 class="h2 fw-bold mb-4" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000">More Scale, More Success, More Smile</h4>
        <p class="mb-4" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="200">
          Enthusiastically morph unique web-readiness via impactful platforms.
          Intrinsicly matrix premium expertise for diverse expertise.
          Intrinsicly drive collaborative bandwidth for accurate testing.
        </p>
      </div>
      <div class="float-start w-100" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="400">
        <p class="small fw-bold">VERSION 6.0</p>
        <ngb-progressbar [value]="500" type="primary"></ngb-progressbar>
        <span class="progress-content text-white fw-bold">60% Optimized</span>
      </div>
      <div class="float-start w-100 mt-5" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="600">
        <a href="#" class="btn btn-primary p-3 btn-hover-moveUp"
          >Schedule a Demo <i class="ri-arrow-right-line"></i
        ></a>
      </div>
    </div>
  </div>
</section>
<!-- image-text-wrapper2 section end here -->


<!-- image-text-wrapper2 section start here -->
<section class="container image-text-wrapper2 section resources-page pt-0" *ngIf="imageTextwrapper2Type=='botStationAiResources'">
  <div class="row">
    <div class="col-md-4 p-0 right-section text-center"  data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="1000" data-aos-delay="200">
      <img src="./assets/images/bot-station-ai/other/our-story-new-image.png" />
    </div>
    <div class="col-md-7 left-section pt-5 offset-md-1">
      <div class="float-start w-100">
        <h4 class="h2 fw-bold mb-4"  data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="200">Our Story</h4>
        <p class="mb-2"  data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="400">
          allinone, was founded by Pankaj Pede, who is the epitome of the Indian startup success story. He is a serial entrepreneur (with a successful exit) and is passionate about building technology for Customer Support teams that enables them to deliver delightful support experiences.
        </p>
        <p class="mb-2" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="400">
          Our story has been all about building the operating system of customer support. With Verloop.io, brands have the ability to scale their support, reduce their operational expense and gain completely visibility into their customer support operations. allinone combines the power of agent assist, machine learning model, speech recognition, and Natural Language Processing (NLP) to transform the way support is delivered.
        </p>
      </div>
    </div>
  </div>
</section>
<!-- image-text-wrapper2 section end here -->