<section class="container-fluid image-text-wrapper2 price-plan-wrapper1 section" *ngIf="priceTableWrapper1Type=='corporateBlue'">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 left-section pt-5 bg-dotted">
          <div class="w-100 float-start" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
            <i class="ri-file-text-line h1 mb-3 float-start"></i>
          </div>
          <div class="float-start w-100">
            <h4 class="sub-heading fw-bold mb-4 h2" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
              No Hidden Charges. <br />
              Choose Your Best Plan.
            </h4>
            <p class="mt-4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="900">
              Credibly target highly efficient niche markets whereas end-to-end
              results.
            </p>
            <span class="mt-4 small float-start" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="900">
              During Free trail you won't be charge and you can freely use this
              app without
              <a href="#"><u>Terms and Condtions</u></a>
            </span>
          </div>
        </div>
        <div class="col-lg-7 offset-lg-1 right-section pt-5 price-item-wrapper">
          <div class="row">
            <div class="col-12 col-lg-6 col-md-12" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="1300">
              <div class="price-item float-start shadow">
                <span class="small fw-bold">STARTUP</span>
                <h2 class="h1 fw-bold mt-2 mb-4">Free</h2>
                <span class="small text-muted float-start mb-4"
                  >Phosfluorescently negotiate alternative human capital with
                  fully tested leadership skills. Quickly enable.
              </span>
                <ul class="iconlist">
                  <li>
                    <i class="ri-checkbox-circle-fill"></i> 7 Days Free Trail
                  </li>
                  <li><i class="ri-checkbox-circle-fill"></i> App-In-Purchase</li>
                  <li>
                    <i class="ri-checkbox-circle-fill"></i> 100% Safe &amp; Secure
                  </li>
                  <li>
                    <i class="ri-checkbox-circle-fill"></i> Liecenced Company
                  </li>
                  <li>
                    <i class="ri-checkbox-circle-fill"></i> No Hidden Charges
                  </li>
                </ul>
                <a href="#" class="btn btn-primary d-block mt-4 py-3 btn-hover-moveUp">Start Now!</a>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-md-12" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="1700">
              <div class="price-item float-start shadow">
                <span class="small fw-bold text-white-50">Pro</span>
                <h2 class="h1 fw-bold mt-2 mb-5 text-white">$29</h2>
                <span class="small text-muted float-start mb-4 mt-4"
                  >Holisticly disintermediate viral results rather than
                  next-generation action items vertical metrics rather.
                  </span>
                <ul class="iconlist">
                  <li>
                    <i class="ri-checkbox-circle-fill"></i> One Time Purchase
                  </li>
                  <li>
                    <i class="ri-checkbox-circle-fill"></i> All Bundle Included
                  </li>
                  <li>
                    <i class="ri-checkbox-circle-fill"></i> 100% Safe & Secure
                  </li>
                  <li>
                    <i class="ri-checkbox-circle-fill"></i> Liecenced Company
                  </li>
                  <li>
                    <i class="ri-checkbox-circle-fill"></i> No Hidden Charges
                  </li>
                </ul>
                <a href="#" class="btn btn-primary d-block mt-4 py-3 btn-hover-moveUp">Start Now!</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>