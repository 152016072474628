import { Component, ViewChild, OnInit } from '@angular/core';

import * as AOS from 'aos';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'all-in-one';
  ngOnInit() {
    AOS.init();
  }
}
