<!-- Top banner start here-->
<section class="container-fluid top-banner1 banner-area" *ngIf="topbanner4Type=='training'">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="banner-content">
                    <span data-aos="fade-up" data-aos-delay="500" data-aos-duration="800" data-aos-once="true">FOR A
                        BETTER FUTURE</span>
                    <h1 data-aos="fade-up" data-aos-delay="1000" data-aos-duration="800" data-aos-once="true">Discover
                        the most exciting online courses</h1>
                    <p data-aos="fade-up" data-aos-delay="1200" data-aos-duration="800" data-aos-once="true">Flexible
                        easy to access learning opportunities can bring a significant change in how individuals prefer
                        to learn! The Ellen can offer you to enjoy the beauty of eLearning!
                    </p>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="banner-img">
                    <img src="./assets/images/training/man.png" data-aos="fade-up" data-aos-duration="1000"
                        data-aos-once="true" alt="Man" class="w-100">
                    <div class="bg-shape">
                        <img src="./assets/images/training/home-one-shape.png" data-aos="fade-up" data-aos-delay="900"
                            data-aos-duration="1000" data-aos-once="true" alt="Shape">
                    </div>
                    <div class="top-content" data-aos="fade-down" data-aos-delay="900" data-aos-duration="1000"
                        data-aos-once="true">
                        <i class="ri-user-line"></i>
                        <div class="content">
                            <h3>250K</h3>
                            <p>Assisted student</p>
                        </div>
                    </div>
                    <div class="right-content" data-aos="fade-left" data-aos-delay="900" data-aos-duration="1000"
                        data-aos-once="true">
                        <i class="ri-shield-check-line"></i>
                        <div class="content">
                            <h3>Congratulations</h3>
                            <p>Your admission completed</p>
                        </div>
                    </div>
                    <div class="left-content" data-aos="fade-up" data-aos-delay="900" data-aos-duration="1000"
                        data-aos-once="true">
                        <div class="content">
                            <img src="./assets/images/training/user-img.jpeg" alt="User">
                            <h3>User experience class</h3>
                            <p>Today at 12.00 PM</p>
                        </div>
                        <a href="#" class="join-btn">Join now</a>
                    </div>
                    <div class="banner-img-shape">
                        <div class="shape1" data-aos="fade-up" data-aos-delay="900" data-aos-duration="1000"
                            data-aos-once="true">
                            <img src="./assets/images/training/shape3.png" alt="Shape">
                        </div>
                        <div class="shape2" data-aos="fade-up" data-aos-delay="900" data-aos-duration="1000"
                            data-aos-once="true">
                            <img src="./assets/images/training/shape2.png" alt="Shape">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="banner-shape">
        <div class="banner-shape1">
            <img src="./assets/images/training/shape1.png" alt="Shape">
        </div>
    </div>
</section>
<!-- Top banner end here -->

<!-- <div class="container mb-5" *ngIf="topbanner4Type=='training'">
    <div class="row">
        <div class="col-12">
            <div class="featured-area featured-area-mt pb-70 my-5">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-6">
                            <div class="featured-item">
                                <div class="img-wrapper">
                                    <img src="./assets/images/training/Python-logo.png" />
                                </div>
                                <h3>Python Training</h3>
                                <p>Duis aute irure dolor in voluptate velit esse cillum labore .</p>
                                <a [routerLink]="['/training-course-details-ux']" class="btn btn-sm btn-primary mt-2">Read More</a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-6">
                            <div class="featured-item">
                                <div class="img-wrapper">
                                    <img src="./assets/images/training/AWS.png" />
                                </div>
                                <h3>AWS Traning</h3>
                                <p>Duis aute irure dolor in voluptate velit esse cillum labore .</p>
                                <a [routerLink]="['/training-course-details-aws']" class="btn btn-sm btn-primary mt-2">Read More</a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-6">
                            <div class="featured-item">
                                <i class="ri-stack-line"></i>
                                <h3>UX/UI Designing</h3>
                                <p>Duis aute irure dolor in voluptate velit esse cillum labore .</p>
                                <a [routerLink]="['/training-course-details-ux']" class="btn btn-sm btn-primary mt-2">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->