import { Component, Input, OnInit } from '@angular/core';

import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-carousel-wrapper3',
  templateUrl: './carousel-wrapper3.component.html',
  styleUrls: ['./carousel-wrapper3.component.scss']
})
export class CarouselWrapper3Component implements OnInit {

  @Input() carouselWrapper3Type:string;

  limit: number = 10; // <==== Edit this number to limit API results
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    center: false,
    dots: false,
    autoHeight: true,
    autoWidth: true,
    nav: false,
    navText: [ '','' ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      769: {
        items: 3,
      },
      1024: {
        items: 4,
      },
    }
  }
  constructor() { }

  ngOnInit(): void {
  }

}
