<app-header4 [header4Type]="'training'"></app-header4>
        <!-- Breadcrumb Section Start -->
        <div class="breadcrumb-wrap position-relative index-1 bg-title">
            <div class="br-bg br-bg-5 position-absolute top-0 end-0 md-none"></div>
            <img src="../assets/vendor/bruk/img/breadcrumb/br-shape-2.webp" alt="Image" class="br-shape-two position-absolute">
            <div class="container position-relative">
                <img src="../assets/vendor/bruk/img/breadcrumb/br-shape-1.webp" alt="Image" class="br-shape-one position-absolute md-none">
                <img src="../assets/vendor/bruk/img/breadcrumb/br-shape-3.webp" alt="Image" class="br-shape-three position-absolute md-none">
                <div class="row">
                    <div class="col-xxl-6 col-xl-6 col-lg-6">
                        <ul class="br-menu list-unstyle d-inline-block">
                            <li><a href="#">Home</a></li>
                            <li>Our Team</li>
                        </ul>
                        <h2 class="br-title fw-medium text-white mb-0">Meet With Our Special Team Member For Your Service</h2>
                    </div>
                </div>
            </div>
        </div>
        <!-- Breadcrumb Section End -->

        <!-- Team Section Start -->
        <div class="container ptb-130">
            <div class="row align-items-center gx-xl-25">
                <div class="col-xl-5 col-lg-6">
                    <div class="single-team-img">
                        <img src="../assets/vendor/bruk/img/team/single-team.webp" alt="Image">
                    </div>
                </div>
                <div class="col-xl-7 col-lg-6">
                    <div class="team-desc">
                        <div class="section-title">
                            <span class="section-subtitle d-inline-block fs-15 fw-semibold text-title">Partners</span>
                            <h2 class="mb-20">CA Nilesh Mahajan</h2>
                            <p class="mb-0">Wed a little help from our friends from time to time. Although we offer the one-stop convenience of annery integrated range of legal, financial services under one roof, there are occasions when our clients areaneed specia- list advice beyond the scope of our own expertise. That’s why we’ve developed close working relationships.</p>
                        </div>
                        <div class="team-info">
                            <div class="team-info-item d-flex flex-wrap align-items-center">
                                <span class="text-title fw-bold">Language: </span>
                                English, Spanish
                            </div>
                            <div class="team-info-item d-flex flex-wrap align-items-center">
                                <span class="text-title fw-bold">Phone: </span>
                                <a href="tel:2870174302">+(2) 870 174 302</a>
                            </div>
                            <div class="team-info-item d-flex flex-wrap align-items-center">
                                <span class="text-title fw-bold">Email: </span>
                                <a href="https://templates.hibotheme.com/cdn-cgi/l/email-protection#cbb9a4a9aeb9bfa9b9a4bca58ba9b9bea0e5a8a4a6"><span class="__cf_email__" data-cfemail="afddc0cdcadddbcdddc0d8c1efcddddac481ccc0c2">[email&#160;protected]</span></a>
                            </div>
                            <div class="team-info-item d-flex flex-wrap align-items-center">
                                <span class="text-title fw-bold">Location: </span>
                                <p class="mb-0">256 Avenue, Mark Street, New York City,USA</p>
                            </div>
                            <div class="team-info-item d-flex flex-wrap align-items-center">
                                <span class="text-title fw-bold">Follow Me: </span>
                                <ul class="social-profile list-unstyle">
                                    <li><a href="https://www.facebook.com/" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                                    <li><a href="https://www.instagram.com/" target="_blank"><i class="ri-instagram-line"></i></a></li>
                                    <li><a href="https://www.twitter.com/" target="_blank"><i class="ri-twitter-x-line"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Team Section End -->

        <!-- Team Section Start -->
        <div class="container ptb-130">
            <div class="row align-items-center gx-xl-25">
                <div class="col-xl-7 col-lg-6">
                    <div class="team-desc">
                        <div class="section-title">
                            <span class="section-subtitle d-inline-block fs-15 fw-semibold text-title">Profile Information</span>
                            <h2 class="mb-20">Robert Brown</h2>
                            <p class="mb-0">Wed a little help from our friends from time to time. Although we offer the one-stop convenience of annery integrated range of legal, financial services under one roof, there are occasions when our clients areaneed specia- list advice beyond the scope of our own expertise. That’s why we’ve developed close working relationships.</p>
                        </div>
                        <div class="team-info">
                            <div class="team-info-item d-flex flex-wrap align-items-center">
                                <span class="text-title fw-bold">Language: </span>
                                English, Spanish
                            </div>
                            <div class="team-info-item d-flex flex-wrap align-items-center">
                                <span class="text-title fw-bold">Phone: </span>
                                <a href="tel:2870174302">+(2) 870 174 302</a>
                            </div>
                            <div class="team-info-item d-flex flex-wrap align-items-center">
                                <span class="text-title fw-bold">Email: </span>
                                <a href="https://templates.hibotheme.com/cdn-cgi/l/email-protection#cbb9a4a9aeb9bfa9b9a4bca58ba9b9bea0e5a8a4a6"><span class="__cf_email__" data-cfemail="afddc0cdcadddbcdddc0d8c1efcddddac481ccc0c2">[email&#160;protected]</span></a>
                            </div>
                            <div class="team-info-item d-flex flex-wrap align-items-center">
                                <span class="text-title fw-bold">Location: </span>
                                <p class="mb-0">256 Avenue, Mark Street, New York City,USA</p>
                            </div>
                            <div class="team-info-item d-flex flex-wrap align-items-center">
                                <span class="text-title fw-bold">Follow Me: </span>
                                <ul class="social-profile list-unstyle">
                                    <li><a href="https://www.facebook.com/" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                                    <li><a href="https://www.instagram.com/" target="_blank"><i class="ri-instagram-line"></i></a></li>
                                    <li><a href="https://www.twitter.com/" target="_blank"><i class="ri-twitter-x-line"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-5 col-lg-6">
                    <div class="single-team-img">
                        <img src="../assets/vendor/bruk/img/team/single-team.webp" alt="Image">
                    </div>
                </div>
            </div>
        </div>
        <!-- Team Section End -->



<app-footer2 [footer2Type]="'botStationAi'"></app-footer2>