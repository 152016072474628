import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Modal1Component } from '../../modal/modal1/modal1.component';

@Component({
  selector: 'app-header4',
  templateUrl: './header4.component.html',
  styleUrls: ['./header4.component.scss']
})
export class Header4Component implements OnInit {


  @Input() header4Type:string;

  @ViewChild('appDialog') appDialog: Modal1Component;
  constructor() { }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop < 20 ||     
    document.documentElement.scrollTop < 20) {
      document.getElementById('header3').classList.remove('scrolled');
      document.getElementById('header3').classList.add('noscrolled');
    }
    if (document.body.scrollTop > 20 ||     
    document.documentElement.scrollTop > 20) {
      document.getElementById('header3').classList.remove('noscrolled');
      document.getElementById('header3').classList.add('scrolled');
    }
  }

  ngOnInit(): void {
  }

}
