<!-- Header2 start here-->
<app-header4 [header4Type]="'training'"></app-header4>
<!-- Header2 end here-->

<div class="edu-breadcrumb-area breadcrumb-style-3">
    <div class="container">
        <div class="breadcrumb-inner">
            <ul class="edu-breadcrumb">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="separator"><i class="ri-arrow-right-s-line"></i></li>
                <li class="breadcrumb-item"><a href="course-one.html">Courses</a></li>
                <li class="separator"><i class="ri-arrow-right-s-line"></i></li>
                <li class="breadcrumb-item active" aria-current="page">Course Details</li>
            </ul>
            <div class="page-title">
                <h1 class="title">UX/UI Designer Master Class : Figma, HTML/HTML5/CSS/CSS3/SCSS/Node.js</h1>
            </div>
            <ul class="course-meta">
                <li><i class="ri-shield-user-line"></i>By Vijay Patil</li>
                <li><i class="ri-global-line"></i>English</li>
                <li class="course-rating">
                    <div class="rating">
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-half-line"></i>
                    </div>
                    <span class="rating-count">(72 Rating)</span>
                </li>
            </ul>
        </div>
    </div>
    <ul class="shape-group">
        <li class="shape-1">
            <span></span>
        </li>
        <!-- <li class="shape-2 scene" style="transform: translate3d(0px, 0px, 0px) rotate(0.0001deg); transform-style: preserve-3d; backface-visibility: hidden; pointer-events: none;">
            <img data-depth="2" src="./assets/images/shape-13.png" alt="shape" style="transform: translate3d(5.4px, -0.1px, 0px); transform-style: preserve-3d; backface-visibility: hidden; position: relative; display: block; left: 0px; top: 0px;">
        </li> -->
        <li class="shape-3 scene" style="transform: translate3d(0px, 0px, 0px) rotate(0.0001deg); transform-style: preserve-3d; backface-visibility: hidden; pointer-events: none;">
            <img data-depth="-2" src="./assets/images/training/shape-13.png" alt="shape" style="transform: translate3d(-3.4px, 0px, 0px); transform-style: preserve-3d; backface-visibility: hidden; position: relative; display: block; left: 0px; top: 0px;">
        </li>
        <li class="shape-4">
            <span></span>
        </li>
        <li class="shape-5 scene" style="transform: translate3d(0px, 0px, 0px) rotate(0.0001deg); transform-style: preserve-3d; backface-visibility: hidden; pointer-events: none;">
            <img data-depth="2" src="./assets/images/shape-07.png" alt="shape" style="transform: translate3d(4.2px, -0.1px, 0px); transform-style: preserve-3d; backface-visibility: hidden; position: relative; display: block; left: 0px; top: 0px;">
        </li>
    </ul>
</div>
<section class="edu-section-gap course-details-area">
    <div class="container">
        <div class="row row--30">
            <div class="col-lg-8">
                <div class="course-details-content">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true">Overview</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="carriculam-tab" data-bs-toggle="tab" data-bs-target="#carriculam" type="button" role="tab" aria-controls="carriculam" aria-selected="false">Carriculam</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="instructor-tab" data-bs-toggle="tab" data-bs-target="#instructor" type="button" role="tab" aria-controls="instructor" aria-selected="false">Instructor</button>
                        </li>

                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="review-tab" data-bs-toggle="tab" data-bs-target="#review" type="button" role="tab" aria-controls="review" aria-selected="false">Reviews</button>
                        </li>
                    </ul>

                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                            <div class="course-tab-content">
                                <div class="course-overview">
                                    <h3 class="heading-title">Course Description</h3>
                                    <p>User Experience (UX) and User Interface (UI) designs are the most important terms in web and app development and are often underlooked during software development, but they play a big silent role in attracting customers by providing a visually appealing, smooth, and functional experience to them. For example, Adobe XD is a very popular free-to-use tool that allows us to create prototypes and wireframes that help us in designing web and mobile applications. In this session, we learn the basics of UI/UX philosophy, and the difference between them, wireframing, prototyping, and user testing. We will also be discussing what the UX process is, what is a prototype, an example of user testing, tools that will be used for this purpose, UI concepts, and a lot more. All of this will help you form a framework that will help you build the career of your choice and bring you to appreciate this under-appreciated practice. </p>
                                    <p>Using the latest best practices in Web Design and Mobile Design as well as User Interface and User Experience Design (UI/UX), this course focuses on efficiently getting you from zero to a point where you can get hired or win freelance contracts. We will use in demand tools like Figma to show you a full workflow from start to finish.</p>
                                    <p>The curriculum is going to be very hands on as we walk you from start to finish of working as a Designer, all the way into learning how to create final professional designs and then converting them into actual websites or apps using HTML and CSS. </p>
                                    <h5 class="title">What You’ll Learn?</h5>
                                    <ul class="mb--60">
                                        <li><i class="ri-check-double-line"></i> Build beautifully designed web and mobile projects for your customers using modern tools used by top companies in 2022</li>
                                        <li><i class="ri-check-double-line"></i> Get hired as a Designer or become a freelancer that can work from anywhere and for anyone. Designers are in high demand!</li>
                                        <li><i class="ri-check-double-line"></i> Master Figma for your design needs then learn to convert your designs into a live HTML an CSS website</li>
                                        <li><i class="ri-check-double-line"></i> Have an amazing design portfolio customized and professionally completed by the end of the course (we provide it for you!)</li>
                                        <li><i class="ri-check-double-line"></i> Master both Web and Mobile design principles and how to go from sketching to fully fledged high fidelity designs that will wow customers</li>
                                        <li><i class="ri-check-double-line"></i> Learn to design for all types of devices using Figma and other tools used by some of the top designers in the world</li>
                                        <li><i class="ri-check-double-line"></i> Learn to use HTML5 and CSS3 to make your designs come to life and create fully working websites</li>
                                        <li><i class="ri-check-double-line"></i> Learn best practices that takes years to learn in the design industry</li>
                                        <li><i class="ri-check-double-line"></i> Learn to make professional logos and design choices for all branding needs</li>
                                        <li><i class="ri-check-double-line"></i> Learn UI/UX best practices using the latest trends in the industry</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="carriculam" role="tabpanel" aria-labelledby="carriculam-tab">
                            <div class="course-tab-content">
                                <div class="course-curriculam">
                                    <h3 class="heading-title">Course Curriculum</h3>
                                    <div class="card-body border-0 curriculum-wrapper">
                                        <div id="accordionPanelsStayOpenExample" class="accordion custom-accordion">
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-introduction" aria-expanded="true" aria-controls="panelsStayOpen-introduction" class="accordion-button"> Introduction to user-centered design and the UX design process </button></h2>
                                                <div id="panelsStayOpen-introduction" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse show">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><a routerlink="/course-detail-uxui-overview" class="float-start w-100" href="/course-detail-uxui-overview"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Overview of UI &amp; UX Design</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a routerlink="/Overview-design-process" class="float-start w-100" href="/Overview-design-process"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Overview of the UX Design Process</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a routerlink="/diff-btw-ux-ui" class="float-start w-100" href="/diff-btw-ux-ui"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Difference between User Interface (UI) vs User Experience (UX)</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a routerlink="/intro-interview-qsn-ans" class="float-start w-100" href="/intro-interview-qsn-ans"><i class="ri-question-line float-start"></i><span class="float-start ms-2 font-14">Interview questions &amp; answers</span></a></li>
                                                            <li class="float-start w-100 p-2"><a routerlink="/design-process-assignments" class="float-start w-100" href="/design-process-assignments"><i class="ri-medal-line float-start"></i><span class="float-start ms-2 font-14">Assignments</span></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-designTheory" aria-expanded="true" aria-controls="panelsStayOpen-designTheory" class="accordion-button collapsed"> Design Theory &amp; Fundamentals of UI </button></h2>
                                                <div id="panelsStayOpen-designTheory" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><a routerlink="/design-principle" class="float-start w-100" href="/design-principle"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Design Principles for UX and UI Design</span></a></li>
                                                            <li
                                                            class="float-start w-100 border-bottom p-2"><a routerlink="/typography" class="float-start w-100" href="/typography"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Typography</span></a></li>
                                                                <li class="float-start w-100 border-bottom p-2"><a routerlink="/iconography" class="float-start w-100" href="/iconography"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Iconography</span></a></li>
                                                                <li class="float-start w-100 border-bottom p-2"><a routerlink="/course-detail-colorography" class="float-start w-100" href="/course-detail-colorography"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Colorography</span></a></li>
                                                                <li
                                                                class="float-start w-100 border-bottom p-2"><a routerlink="/course-detail-form-ui-element" class="float-start w-100" href="/course-detail-form-ui-element"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Form + UI Elements</span></a></li>
                                                                    <li
                                                                    class="float-start w-100 border-bottom p-2"><a routerlink="/course-detail-mobileapp-ui-element" class="float-start w-100" href="/course-detail-mobileapp-ui-element"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Mobile App UI Components</span></a></li>
                                                                        <li
                                                                        class="float-start w-100 border-bottom p-2"><a routerlink="/course-detail-visual-design" class="float-start w-100" href="/course-detail-visual-design"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Visual Design</span></a></li>
                                                                            <li
                                                                            class="float-start w-100 border-bottom p-2"><a routerlink="/course-detail-accessibility" class="float-start w-100" href="/course-detail-accessibility"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Accessibility</span></a></li>
                                                                                <li
                                                                                class="float-start w-100 border-bottom p-2"><a routerlink="/course-detail-design-pattern" class="float-start w-100" href="/course-detail-design-pattern"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Design Pattern/System</span></a></li>
                                                                                    <li
                                                                                    class="float-start w-100 border-bottom p-2"><a routerlink="/course-ios-android-guideline" class="float-start w-100" href="/course-ios-android-guideline"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">iOS vs. Android App UI Design: The Complete Guide</span></a></li>
                                                                                        <li
                                                                                        class="float-start w-100 border-bottom p-2"><a routerlink="/interaction-design" class="float-start w-100" href="/interaction-design"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Interaction Principles</span></a></li>
                                                                                            <li
                                                                                            class="float-start w-100 border-bottom p-2"><a routerlink="/style-guide" class="float-start w-100" href="/style-guide"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Master the Brand Platforms &amp; Style Guides</span></a></li>
                                                                                                <li
                                                                                                class="float-start w-100 border-bottom p-2"><a routerlink="/interview-qsn-ans-design-theory" class="float-start w-100" href="/interview-qsn-ans-design-theory"><i class="ri-question-line float-start"></i><span class="float-start ms-2 font-14">Interview questions &amp; answers</span></a></li>
                                                                                                    <li
                                                                                                    class="float-start w-100 p-2"><a routerlink="/assignments-design-theory" class="float-start w-100" href="/assignments-design-theory"><i class="ri-medal-line float-start"></i><span class="float-start ms-2 font-14">Assignments</span></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-userResearch" aria-expanded="false" aria-controls="panelsStayOpen-userResearch" class="accordion-button collapsed"> Conducting user research &amp; Including user interviews </button></h2>
                                                <div id="panelsStayOpen-userResearch" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><a routerlink="/introduction-user-research" class="float-start w-100" href="/introduction-user-research"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Introduction to User Research</span></a></li>
                                                            <li
                                                            class="float-start w-100 border-bottom p-2"><a routerlink="/type-user-research" class="float-start w-100" href="/type-user-research"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Types of User Research methods in UX Design</span></a></li>
                                                                <li
                                                                class="float-start w-100 border-bottom p-2"><a routerlink="/identify-user-research-method" class="float-start w-100" href="/identify-user-research-method"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Identifying Appropriate Research Methods</span></a></li>
                                                                    <li
                                                                    class="float-start w-100 border-bottom p-2"><a routerlink="/conduct-user-interview" class="float-start w-100" href="/conduct-user-interview"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">How to conduct interviews – best practices</span></a></li>
                                                                        <li
                                                                        class="float-start w-100 border-bottom p-2"><a routerlink="/prepare-research-report" class="float-start w-100" href="/prepare-research-report"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">How to prepare research report?</span></a></li>
                                                                            <li
                                                                            class="float-start w-100 border-bottom p-2"><a routerlink="/interview-user-research" class="float-start w-100" href="/interview-user-research"><i class="ri-question-line float-start"></i><span class="float-start ms-2 font-14">Interview questions &amp; answers</span></a></li>
                                                                                <li
                                                                                class="float-start w-100 p-2"><a routerlink="/assignment-user-research" class="float-start w-100" href="/assignment-user-research"><i class="ri-medal-line float-start"></i><span class="float-start ms-2 font-14">Assignments</span></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-wireframe" aria-expanded="false" aria-controls="panelsStayOpen-wireframe" class="accordion-button collapsed"> Elementary Sketching &amp; Wireframing </button></h2>
                                                <div id="panelsStayOpen-wireframe" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><a routerlink="/low-vs-high-fedility" class="float-start w-100" href="/low-vs-high-fedility"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">What is Lo Fi Wireframe vs High Fidelity?</span></a></li>
                                                            <li
                                                            class="float-start w-100 border-bottom p-2"><a routerlink="/ux-sketching" class="float-start w-100" href="/ux-sketching"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">UX Sketching</span></a></li>
                                                                <li class="float-start w-100 border-bottom p-2"><a routerlink="/wireframe-for-responsive" class="float-start w-100" href="/wireframe-for-responsive"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Wireframing for Responsive Design</span></a></li>
                                                                <li
                                                                class="float-start w-100 border-bottom p-2"><a routerlink="/interview-qsn-ans-wireframing" class="float-start w-100" href="/interview-qsn-ans-wireframing"><i class="ri-question-line float-start"></i><span class="float-start ms-2 font-14">Interview questions &amp; answers</span></a></li>
                                                                    <li
                                                                    class="float-start w-100 p-2"><a routerlink="/assignment-wireframing" class="float-start w-100" href="/assignment-wireframing"><i class="ri-medal-line float-start"></i><span class="float-start ms-2 font-14">Assignments</span></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-prototyping" aria-expanded="false" aria-controls="panelsStayOpen-prototyping" class="accordion-button collapsed"> High Fidelity Design </button></h2>
                                                <div id="panelsStayOpen-prototyping" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><a routerlink="/grid-system" class="float-start w-100" href="/grid-system"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Grid and layout systems</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a routerlink="/information-architechture" class="float-start w-100" href="/information-architechture"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">UX Information Architecture</span></a></li>
                                                            <li
                                                            class="float-start w-100 border-bottom p-2"><a routerlink="/card-sorting" class="float-start w-100" href="/card-sorting"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Card sorting methods</span></a></li>
                                                                <li class="float-start w-100 border-bottom p-2"><a routerlink="/design-pattern-guidelines" class="float-start w-100" href="/design-pattern-guidelines"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Design-Pattern Guidelines</span></a></li>
                                                                <li
                                                                class="float-start w-100 border-bottom p-2"><a routerlink="/ui-animations" class="float-start w-100" href="/ui-animations"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">UI Animations</span></a></li>
                                                                    <li class="float-start w-100 border-bottom p-2"><a routerlink="/interview-high-fedility" class="float-start w-100" href="/interview-high-fedility"><i class="ri-question-line float-start"></i><span class="float-start ms-2 font-14">Interview questions &amp; answers</span></a></li>
                                                                    <li
                                                                    class="float-start w-100 p-2"><a routerlink="/assignment-high-fedility" class="float-start w-100" href="/assignment-high-fedility"><i class="ri-medal-line float-start"></i><span class="float-start ms-2 font-14">Assignments</span></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-creativity" aria-expanded="false" aria-controls="panelsStayOpen-creativity" class="accordion-button collapsed"> Portfolio </button></h2>
                                                <div id="panelsStayOpen-creativity" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Style Guide</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Research Report</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Paper Wireframe</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Low Fedility Prototype</span></a></li>
                                                            <li class="float-start w-100 p-2 border-bottom"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Card Sorting</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">High Fedility Prototype</span></a></li>
                                                            <li class="float-start w-100 p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Case Study</span></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-HTML" aria-expanded="false" aria-controls="panelsStayOpen-HTML" class="accordion-button collapsed"> HTML Fundamentals </button></h2>
                                                <div id="panelsStayOpen-HTML" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><a routerlink="/introduction-html" class="float-start w-100" href="/introduction-html"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Introduction to HTML</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a routerlink="/html-element" class="float-start w-100" href="/html-element"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">HTML Elements</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a routerlink="/html-lists" class="float-start w-100" href="/html-lists"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">HTML Lists</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a routerlink="/html-images" class="float-start w-100" href="/html-images"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">HTML Image Elements</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a routerlink="/html-anchor" class="float-start w-100" href="/html-anchor"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">HTML Links and Anchor Tags</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a routerlink="/html-table" class="float-start w-100" href="/html-table"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">HTML Tables</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a routerlink="/html-form" class="float-start w-100" href="/html-form"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">HTML Forms</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a routerlink="/interview-html" class="float-start w-100" href="/interview-html"><i class="ri-question-line float-start"></i><span class="float-start ms-2 font-14">Interview questions &amp; answers</span></a></li>
                                                            <li class="float-start w-100 p-2"><a class="float-start w-100"><i class="ri-medal-line float-start"></i><span class="float-start ms-2 font-14">Assignments</span></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-html5" aria-expanded="false" aria-controls="panelsStayOpen-html5" class="accordion-button collapsed"> HTML 5 </button></h2>
                                                <div id="panelsStayOpen-html5" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">HTML5 - Overview</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Semantic Elements</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">HTML5 Web Forms</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Canvas</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">SVG</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">HTML5 Media (Video &amp; Audio) </span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-question-line float-start"></i><span class="float-start ms-2 font-14">Interview questions &amp; answers</span></a></li>
                                                            <li class="float-start w-100 p-2"><a class="float-start w-100"><i class="ri-medal-line float-start"></i><span class="float-start ms-2 font-14">Assignments</span></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-CSS" aria-expanded="false" aria-controls="panelsStayOpen-CSS" class="accordion-button collapsed"> CSS (Cascading Style Sheets) </button></h2>
                                                <div id="panelsStayOpen-CSS" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Introduction to CSS</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Inline CSS</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Internal CSS</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">External CSS</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">How to Debug CSS Code</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">CSS Selectors</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Classes vs. Ids</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Selectors</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Stylised Personal Site</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">What Are Favicons?</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">HTML Divs</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">The Box Model of Website Styling</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">CSS Display Property</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">CSS Position Property</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Font Styling in Our Personal Site</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">CSS Float and Clear</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">The CSS Z-Index</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Media Query</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Combining Selectors</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Selector Priority</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-question-line float-start"></i><span class="float-start ms-2 font-14">Interview questions &amp; answers</span></a></li>
                                                            <li class="float-start w-100 p-2"><a class="float-start w-100"><i class="ri-medal-line float-start"></i><span class="float-start ms-2 font-14">Assignments</span></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-CSS3" aria-expanded="false" aria-controls="panelsStayOpen-CSS3" class="accordion-button collapsed"> CSS3 </button></h2>
                                                <div id="panelsStayOpen-CSS3" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">CSS3 - Overview</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Border and box effects</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Background images and other decorative</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">2D Transformations</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">2D Transformations</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Transitions and animations </span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Media queries </span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-question-line float-start"></i><span class="float-start ms-2 font-14">Interview questions &amp; answers</span></a></li>
                                                            <li class="float-start w-100 p-2"><a class="float-start w-100"><i class="ri-medal-line float-start"></i><span class="float-start ms-2 font-14">Assignments</span></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-cssProcessors" aria-expanded="false" aria-controls="panelsStayOpen-cssProcessors" class="accordion-button collapsed"> CSS Preprocessor - SCSS &amp; SASS </button></h2>
                                                <div id="panelsStayOpen-cssProcessors" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">What is CSS Preprocessing?</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Variables</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Nesting</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Partials</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Mixins</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Extend/Inheritance</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-question-line float-start"></i><span class="float-start ms-2 font-14">Operators</span></a></li>
                                                            <li class="float-start w-100 p-2"><a class="float-start w-100"><i class="ri-medal-line float-start"></i><span class="float-start ms-2 font-14">Assignments</span></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-bootstrap" aria-expanded="false" aria-controls="panelsStayOpen-bootstrap" class="accordion-button collapsed"> Bootstrap 4 &amp; 5 </button></h2>
                                                <div id="panelsStayOpen-bootstrap" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">What is Bootstrap?</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Installing Bootstrap</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Setting Up Our New Project</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Bootstrap Grid Layout System</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Bootstrap Containers</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Bootstrap Navigation Bar</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Bootstrap Buttons &amp; Font Awesome</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Bootstrap Carousel</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Bootstrap Cards</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Bootstrap Forms</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Code Refactoring</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Completing the Website</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-question-line float-start"></i><span class="float-start ms-2 font-14">Interview questions &amp; answers</span></a></li>
                                                            <li class="float-start w-100 p-2"><a class="float-start w-100"><i class="ri-medal-line float-start"></i><span class="float-start ms-2 font-14">Assignments</span></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-nodejs" aria-expanded="false" aria-controls="panelsStayOpen-nodejs" class="accordion-button collapsed"> Node.js </button></h2>
                                                <div id="panelsStayOpen-nodejs" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">What is Node.js?</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Install Node.js</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">The Power of the Command Line and How to Use Node</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">How to Use the Native Node Modules</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">The NPM Package Manager and Installing External Node Modules</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Angular Project Structure</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-question-line float-start"></i><span class="float-start ms-2 font-14">Interview questions &amp; answers</span></a></li>
                                                            <li class="float-start w-100 p-2"><a class="float-start w-100"><i class="ri-medal-line float-start"></i><span class="float-start ms-2 font-14">Assignments</span></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-cssProcessors" aria-expanded="false" aria-controls="panelsStayOpen-cssProcessors" class="accordion-button collapsed"> CSS Preprocessor </button></h2>
                                                <div id="panelsStayOpen-cssProcessors" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">What is Node.js?</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Install Node.js</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">The Power of the Command Line and How to Use Node</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">How to Use the Native Node Modules</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">The NPM Package Manager and Installing External Node Modules</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Angular Project Structure</span></a></li>
                                                            <li class="float-start w-100 border-bottom p-2"><a class="float-start w-100"><i class="ri-question-line float-start"></i><span class="float-start ms-2 font-14">Interview questions &amp; answers</span></a></li>
                                                            <li class="float-start w-100 p-2"><a class="float-start w-100"><i class="ri-medal-line float-start"></i><span class="float-start ms-2 font-14">Assignments</span></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="instructor" role="tabpanel" aria-labelledby="instructor-tab">
                            <div class="course-tab-content">
                                <div class="course-instructor">
                                    <div class="thumbnail">
                                        <img src="./assets/images/author-01.jpeg" alt="Author Images">
                                    </div>
                                    <div class="author-content">
                                        <h6 class="title">Vijay Patil</h6>
                                        <span class="subtitle">10+ Years of Experience</span>
                                        <ul>
                                            <li class="mb-2">Senior UX Designer at UnitedLayer, passionate about crafting intuitive digital interactions.</li>
                                            <li class="mb-2">Leading design efforts for seamless user experiences, leveraging research and collaboration.</li>
                                            <li class="mb-2">Dedicated to innovative solutions at the forefront of UX trends and technologies.</li>
                                            <li class="mb-2">Fostering creativity and teamwork, driving outstanding results through collaboration.</li>
                                            <li class="mb-2">Contributing to UnitedLayer's culture of excellence and innovation.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="review" role="tabpanel" aria-labelledby="review-tab">
                            <div class="course-tab-content">
                                <div class="course-review">
                                    <h3 class="heading-title">Course Rating</h3>
                                    <p>5.00 average rating based on 7 rating</p>
                                    <div class="row g-0 align-items-center">
                                        <div class="col-sm-4">
                                            <div class="rating-box">
                                                <div class="rating-number">5.0</div>
                                                <div class="rating">
                                                    <i class="ri-star-fill"></i>
                                                    <i class="ri-star-fill"></i>
                                                    <i class="ri-star-fill"></i>
                                                    <i class="ri-star-fill"></i>
                                                    <i class="ri-star-fill"></i>
                                                </div>
                                                <span>(7 Review)</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-8">
                                            <div class="review-wrapper">

                                                <div class="single-progress-bar">
                                                    <div class="rating-text">
                                                        5 
                                                        <i class="ri-star-fill"></i>
                                                    </div>
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    <span class="rating-value">7</span>
                                                </div>

                                                <div class="single-progress-bar">
                                                    <div class="rating-text">
                                                        4 
                                                        <i class="ri-star-fill"></i>
                                                    </div>
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    <span class="rating-value">0</span>
                                                </div>

                                                <div class="single-progress-bar">
                                                    <div class="rating-text">
                                                        4 
                                                        <i class="ri-star-fill"></i>
                                                    </div>
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    <span class="rating-value">0</span>
                                                </div>

                                                <div class="single-progress-bar">
                                                    <div class="rating-text">
                                                        4 
                                                        <i class="ri-star-fill"></i>
                                                    </div>
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    <span class="rating-value">0</span>
                                                </div>

                                                <div class="single-progress-bar">
                                                    <div class="rating-text">
                                                        4 
                                                        <i class="ri-star-fill"></i>
                                                    </div>
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    <span class="rating-value">0</span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <!-- Start Comment Area  -->
                                    <div class="comment-area">
                                        <h3 class="heading-title">Reviews</h3>
                                        <div class="comment-list-wrapper">
                                            <!-- Start Single Comment  -->
                                            <div class="comment">
                                                <div class="thumbnail">
                                                    <img src="./assets/images/comment-01.jpeg" alt="Comment Images">
                                                </div>
                                                <div class="comment-content">
                                                    <div class="rating">
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                    </div>
                                                    <h5 class="title">Haley Bennet</h5>
                                                    <span class="date">Oct 10, 2024</span>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                </div>
                                            </div>
                                            <!-- End Single Comment  -->
                                            <!-- Start Single Comment  -->
                                            <div class="comment">
                                                <div class="thumbnail">
                                                    <img src="./assets/images/comment-02.jpeg" alt="Comment Images">
                                                </div>
                                                <div class="comment-content">
                                                    <div class="rating">
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                    </div>
                                                    <h5 class="title">Simon Baker</h5>
                                                    <span class="date">Oct 10, 2024</span>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                </div>
                                            </div>
                                            <!-- End Single Comment  -->
                                            <!-- Start Single Comment  -->
                                            <div class="comment">
                                                <div class="thumbnail">
                                                    <img src="./assets/images/comment-01.jpeg" alt="Comment Images">
                                                </div>
                                                <div class="comment-content">
                                                    <div class="rating">
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                    </div>
                                                    <h6 class="title">Richard Gere</h6>
                                                    <span class="date">Oct 10, 2024</span>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                </div>
                                            </div>
                                            <!-- End Single Comment  -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="course-sidebar-3 sidebar-top-position">
                    <div class="edu-course-widget widget-course-summery">
                        <div class="inner">
                            <div class="thumbnail">
                                <img src="./assets/images/training/course-46.jpeg" alt="Courses">
                                <a href="https://www.youtube.com/watch?v=PICj5tr9hcc" class="play-btn video-popup-activation">
                                    <i class="ri-play-fill"></i>
                                </a>
                            </div>
                            <div class="content">
                                <h4 class="widget-title">Course Includes:</h4>
                                <ul class="course-item">
                                    <li>
                                        <span class="label"><i class="ri-user-smile-line"></i>Instrutor:</span>
                                        <span class="value">Edward Norton</span>
                                    </li>
                                    <li>
                                        <span class="label"><i class="ri-time-line"></i>Duration:</span>
                                        <span class="value">3 weeks</span>
                                    </li>
                                    <li>
                                        <span class="label">
                                            <i class="ri-book-3-line"></i>Lessons:</span>
                                        <span class="value">8</span>
                                    </li>
                                    <li>
                                        <span class="label"><i class="ri-shield-check-line"></i>Enrolled:</span>
                                        <span class="value">65 students</span>
                                    </li>
                                    <li>
                                        <span class="label"><i class="ri-global-line"></i>Language:</span>
                                        <span class="value">English</span>
                                    </li>
                                    <li>
                                        <span class="label"><i class="ri-medal-line"></i>Certificate:</span>
                                        <span class="value">Yes</span>
                                    </li>
                                    <li>
                                        <span class="label"><i class="ri-money-rupee-circle-line"></i>Price:</span>
                                        <span class="value price">Rs. 38,000/-</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>