
<!-- image-text-wrapper1 section start here -->
<section class="container-fluid image-text-wrapper1 section pt-0" *ngIf="imageTextwrapper1Type=='corporateBlue'" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="200">
    <div class="row">
      <div class="col-lg-6 col-md-12 order-md-2 p-0 right-section">
        <!-- <img src="./assets/images/devices/hand-with-mobile.jpeg" class="w-100    float-start" /> -->
      </div>
      <div class="col-lg-6 col-md-12 order-md-1 bg-blue left-section">
        <div class="text-wrapper mb-5" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1000">
          <h4 class="sub-heading text-white mb-2">
            Intrinsicly synergistic benefits
          </h4>
          <p class="text-white small">
            Enthusiastically morph unique web-readiness via impactful platforms.
            Intrinsicly matrix premium expertise for diverse expertise.
            Intrinsicly drive collaborative bandwidth for accurate testing.
          </p>
        </div>
        <div class="text-wrapper mb-5" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1300">
          <h4 class="sub-heading text-white mb-3">
            Appropriately target customers
          </h4>
          <p class="text-white small">
            Energistically incentivize front-end web services via enabled
            collaboration and idea-sharing. Conveniently whiteboard effective
            niche markets before flexible ROI. Phosfluorescently build turnkey
            convergence without goal-oriented systems.
          </p>
        </div>
        <div class="text-wrapper" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1600">
          <h4 class="sub-heading text-white mb-3">Completely exploit focused</h4>
          <p class="text-white small">
            Continually enable leveraged users after functional web-readiness.
            Interactively conceptualize accurate resources whereas distinctive
            e-markets.
          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- image-text-wrapper1 section end here -->