<!-- Header2 start here-->
<app-header4 [header4Type]="'training'"></app-header4>
<!-- Header2 end here-->

<div class="edu-breadcrumb-area breadcrumb-style-3">
    <div class="container">
        <div class="breadcrumb-inner">
            <ul class="edu-breadcrumb">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="separator"><i class="ri-arrow-right-s-line"></i></li>
                <li class="breadcrumb-item"><a href="course-one.html">Courses</a></li>
                <li class="separator"><i class="ri-arrow-right-s-line"></i></li>
                <li class="breadcrumb-item active" aria-current="page">Course Details</li>
            </ul>
            <div class="page-title">
                <h1 class="title">AWS Course: Skill And Certification Oriented Course </h1>
            </div>
            <ul class="course-meta">
                <li><i class="ri-shield-user-line"></i>By Vishakha Chaudhari</li>
                <li><i class="ri-global-line"></i>English/Hindi</li>
                <li class="course-rating">
                    <div class="rating">
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-half-line"></i>
                    </div>
                    <span class="rating-count">(109 Rating)</span>
                </li>
            </ul>
        </div>
    </div>
    <ul class="shape-group">
        <li class="shape-1">
            <span></span>
        </li>
        <!-- <li class="shape-2 scene" style="transform: translate3d(0px, 0px, 0px) rotate(0.0001deg); transform-style: preserve-3d; backface-visibility: hidden; pointer-events: none;">
            <img data-depth="2" src="./assets/images/shape-13.png" alt="shape" style="transform: translate3d(5.4px, -0.1px, 0px); transform-style: preserve-3d; backface-visibility: hidden; position: relative; display: block; left: 0px; top: 0px;">
        </li> -->
        <li class="shape-3 scene" style="transform: translate3d(0px, 0px, 0px) rotate(0.0001deg); transform-style: preserve-3d; backface-visibility: hidden; pointer-events: none;">
            <img data-depth="-2" src="./assets/images/training/shape-13.png" alt="shape" style="transform: translate3d(-3.4px, 0px, 0px); transform-style: preserve-3d; backface-visibility: hidden; position: relative; display: block; left: 0px; top: 0px;">
        </li>
        <li class="shape-4">
            <span></span>
        </li>
        <li class="shape-5 scene" style="transform: translate3d(0px, 0px, 0px) rotate(0.0001deg); transform-style: preserve-3d; backface-visibility: hidden; pointer-events: none;">
            <img data-depth="2" src="./assets/images/shape-07.png" alt="shape" style="transform: translate3d(4.2px, -0.1px, 0px); transform-style: preserve-3d; backface-visibility: hidden; position: relative; display: block; left: 0px; top: 0px;">
        </li>
    </ul>
</div>
<section class="edu-section-gap course-details-area">
    <div class="container">
        <div class="row row--30">
            <div class="col-lg-8">
                <div class="course-details-content">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true">Overview</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="carriculam-tab" data-bs-toggle="tab" data-bs-target="#carriculam" type="button" role="tab" aria-controls="carriculam" aria-selected="false">Carriculam</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="instructor-tab" data-bs-toggle="tab" data-bs-target="#instructor" type="button" role="tab" aria-controls="instructor" aria-selected="false">Instructor</button>
                        </li>

                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="review-tab" data-bs-toggle="tab" data-bs-target="#review" type="button" role="tab" aria-controls="review" aria-selected="false">Reviews</button>
                        </li>
                    </ul>

                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                            <div class="course-tab-content">
                                <div class="course-overview">
                                    
                                    <h3 class="heading-title"> Who can do this course</h3>
                                    <ul class="list-type-dics">
                                        <li class="p-0"><p>Cloud engineers </p></li>
                                        <li class="p-0"><p>Network administrators </p></li>
                                        <li class="p-0"><p>Automation Engineers  </p></li>
                                        <li class="p-0"><p>Quality Analysts</p></li>
                                        <li class="p-0"><p>System administrators</p></li>
                                        <li class="p-0"><p>software developers </p></li>
                                        <li class="p-0"><p>DevOps consultants</p></li>
                                        <li class="p-0"><p>Stakeholder</p></li>
                                    </ul>


                                    <h3 class="heading-title mt-5"> Prerequisites</h3>
                                    <p>There are no prerequisites for this course but professionals having experience in IT (Development, Operations) will be beneficial.</p>

                                    <h3 class="heading-title mt-5">Course Deliverables </h3>
                                    <ul class="mb--60">
                                        <li><i class="ri-check-double-line"></i> Setup a distributed version control environment using GIT. </li>
                                        <li><i class="ri-check-double-line"></i> Design and implement branching and merging strategies. </li>
                                        <li><i class="ri-check-double-line"></i> Create a Dockerized environment. </li>
                                        <li><i class="ri-check-double-line"></i> Create automation scripts and deploy various environments (development, test staging, Production etc.) using Chef </li>
                                        <li><i class="ri-check-double-line"></i> Explain what cloud computing is and provision AWS services. </li>
                                        <li><i class="ri-check-double-line"></i> Design and implement a CI pipeline using Jenkins for both cloud and non cloud environment. </li>
                                        <li><i class="ri-check-double-line"></i> Design and configure Continuous Delivery/Deployment pipeline using Jenkins for both cloud and non cloud environment. </li>
                                        <li><i class="ri-check-double-line"></i> Certification covered: Jenkins Certified Engineer.  </li>
                                        <li><i class="ri-check-double-line"></i> Student can also think of Docker Certified Associate, Certified Chef Developer and AWS Certified DevOps Engineer. </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="carriculam" role="tabpanel" aria-labelledby="carriculam-tab">
                            <div class="course-tab-content">
                                <div class="course-curriculam">
                                    <h3 class="heading-title">Module 1: Cloud Practitioner Architect </h3>
                                    <div class="card-body border-0 curriculum-wrapper float-start w-100">
                                        <div id="accordionPanelsStayOpenExample" class="accordion custom-accordion">
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-introduction" aria-expanded="true" aria-controls="panelsStayOpen-introduction" class="accordion-button"> Module1: Linux(3Hrs) </button></h2>
                                                <div id="panelsStayOpen-introduction" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse show">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Linux Basic, Commands, Installing command applications (Apache, MYSQL etc.)</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">User Administration (Managing Permissions) </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 1: Execute basic commands </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 2: Install Apache and configure to host page.</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-designTheory" aria-expanded="true" aria-controls="panelsStayOpen-designTheory" class="accordion-button collapsed"> Module2: Linux Shell (3Hrs) </button></h2>
                                                <div id="panelsStayOpen-designTheory" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Shell Introduction</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> Shell Scripting</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> Variables and parameters  </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> Command Line Argument </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> Command substitution </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> Read Input </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> Arithmetic Operations </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> Conditional Statements </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> If-else-if </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> Case </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> Loops </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> while –Do-Done</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> Until- Do-Done </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> For </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> Loop Control-break </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> Continue Function </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> Case statements </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> Functions and Parameters. </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> Lab 1: Write shell script to install Apache & configure website </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> Lab 2: Write a shell script which will accept a filename as a command line argument and performs following option: 1 Check if file is available 2 If available, count lines & print.3 If not available show error message File does not exist.  </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> Lab 3: Write a script to send an email to your ID </span></li>
                                                            <li class="float-start w-100 p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> Lab 4: Write script to monitor the CPU, Disk & memory usage  </span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-userResearch" aria-expanded="false" aria-controls="panelsStayOpen-userResearch" class="accordion-button collapsed"> Module3: Introduction to DevOps(3Hrs)</button></h2>
                                                <div id="panelsStayOpen-userResearch" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Why DevOps</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">What is DevOps</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">DevOps Market Trends</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Terminologies- IaaS </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">PaaS </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">SaaS </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Continuous Integration </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Continuous Deployment </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Continuous Delivery </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Stages of CI and CD </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Difference between CI and CD </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Define a Use case for a CI/CD pipeline for AWS based deployment </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Define a Use Case for a CI/CD pipeline for non cloud deployment </span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-wireframe" aria-expanded="false" aria-controls="panelsStayOpen-wireframe" class="accordion-button collapsed"> Module4: Version Control System(4Hrs) </button></h2>
                                                <div id="panelsStayOpen-wireframe" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">What is Version Control System?(VCS) </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Why VCS </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Types of VCS </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">GIT Introduction </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Understand Git workflow </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Git Installation </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Key Terminologies - Working Tree </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Staging Area </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Local and Remote Repository </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">mportant Git Commands </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> Local Repository actions </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Remote Repository actions </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Tagging in Git </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Branching </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Merging </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Hosted Git Repositories </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Github </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Bitbucket. </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 1: Install & Configure Git </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 2: Clone a Github repo  </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 3: Add and Push changes to remote repo  </span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-prototyping" aria-expanded="false" aria-controls="panelsStayOpen-prototyping" class="accordion-button collapsed"> Module 5: Jenkins Fundamentals(8Hrs) </button></h2>
                                                <div id="panelsStayOpen-prototyping" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Introduction</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Jenkins Jobs</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Types of Jobs</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Scope of Jobs Builds</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">What are Jenkins Builds?</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Understand build steps</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Triggers</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">artifacts</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">epositories</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Build Tool configuration</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Source Code Management (SCM)</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Cloud based SCM</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Jenkins change logs</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">branching and merging strategies</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Infrastructure as Code</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 1: Install Jenkins</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 2: Configure Jenkins for Git, Java and Maven</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 3: Create a Job to perform Continuous Integration</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-creativity" aria-expanded="false" aria-controls="panelsStayOpen-creativity" class="accordion-button collapsed"> Module 6: Container(10Hrs) </button></h2>
                                                <div id="panelsStayOpen-creativity" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">What is Container?</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Why Container</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Docker: Introduction</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Docker: Fundamentals</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Docker: Docker file</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Docker: Docker Networking</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Docker: Docker Volume</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Docker: Docker Registries</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Docker: Docker Hub</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Docker: Orchestration</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Docker: Compose</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Docker: Swarm</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 1: Create a Docker Image from a container</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 2: Creating custom image from a Docker File</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 3: Create a Docker based Webserver</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-HTML" aria-expanded="false" aria-controls="panelsStayOpen-HTML" class="accordion-button collapsed"> Module 7: Jenkins Advance (6Hrs) </button></h2>
                                                <div id="panelsStayOpen-HTML" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Test Automation Benefits </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Define Various test with Jenkins</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Unit Test</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Smoke Test</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Acceptance Test</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Functional Test</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Notifications</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Importance of Notifications</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Types of Notifications</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">plugins</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">What are plugins?</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">What is plugin manager?</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Jenkins Best Practices</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab1: Create a delivery pipeline and deploy a web application.</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="card-body border-0 curriculum-wrapper float-start w-100">
                                        <div id="accordionPanelsStayOpenExample" class="accordion custom-accordion">

                                    <div class="accordion-item float-start w-100">
                                        <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-html5" aria-expanded="false" aria-controls="panelsStayOpen-html5" class="accordion-button collapsed"> Module 8 : Ansible </button></h2>
                                        <div id="panelsStayOpen-html5" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                            <div class="accordion-body float-start w-100 px-2 py-0">
                                                <ul class="curriculum-list float-start w-100">
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Introduction to Ansible </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Introduction to YAML</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Ansible vs Other tools</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Ansible Documentation</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Installation and Configuration</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Ansible Playbooks</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Ansible Modules</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 1: Configure the lab environment for Ansible</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 2: Modify the default HOSTS file and override the same</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 3: Modify the default ANSIBLE configuration file and override the same</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 4: Doing Various Operations with Ansible Playbooks</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">a) Using variables</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">b) Using connection properties</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">c) Using Sudo operations</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">d) Using system facts</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">e) Conditional Operations</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">f) Looping Operations</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">g) Event Handling</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">h) Write Interactive playbooks</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">I) Error handling</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item float-start w-100">
                                        <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-CSS" aria-expanded="false" aria-controls="panelsStayOpen-CSS" class="accordion-button collapsed"> Module 9:Monitoring(4Hrs)</button></h2>
                                        <div id="panelsStayOpen-CSS" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                            <div class="accordion-body float-start w-100 px-2 py-0">
                                                <ul class="curriculum-list float-start w-100">
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">What is Continuous Monitoring</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Nagios: Fundamental</span></li> 
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Installation Commands</span></li> 
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Introduction to Events</span></li> 
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Nagios Notifications.</span></li> 
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item float-start w-100">
                                        <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-CSS3" aria-expanded="false" aria-controls="panelsStayOpen-CSS3" class="accordion-button collapsed">  Module 10: AWS (12Hrs) </button></h2>
                                        <div id="panelsStayOpen-CSS3" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                            <div class="accordion-body float-start w-100 px-2 py-0">
                                                <ul class="curriculum-list float-start w-100">
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">EC2</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">ELB</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">S3</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">RDS</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">VPC</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Autoscalling</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Security Groups</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">AWS CoudFormation</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 1: Create an EC2 instance and deploy Apache Web Server</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 2: Install AWS CLI and use commands to create S3 bucket, list buckets content etc</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 3: Create a VPC, launch ELB, EC2 instances and RDS in VPC Register EC2 instances behind VPC</span></li>
                                                    <li class="float-start w-100 p-2"><i class="ri-medal-line float-start"></i><span class="float-start ms-2 font-14">Lab 4: Create CoudFormation script to repeat lab3</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item float-start w-100">
                                        <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-cssProcessors" aria-expanded="false" aria-controls="panelsStayOpen-cssProcessors" class="accordion-button collapsed"> Module 11: CI/CD Pipeline AWS(4Hrs) </button></h2>
                                        <div id="panelsStayOpen-cssProcessors" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                            <div class="accordion-body float-start w-100 px-2 py-0">
                                                <ul class="curriculum-list float-start w-100">
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">AWS S3</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">AWS Code</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Pipeline</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">AWS CodeCommit</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">AWS CodeBuid</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">AWS CodeCommit</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">AWS CodeDeploy</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab1: Provision infrastructure using CloudFormation</span></li>
                                                    <li class="float-start w-100 p-2"><i class="ri-medal-line float-start"></i><span class="float-start ms-2 font-14">Lab2: Create a CI/CD pipeline to deploy application on AWS environment</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item float-start w-100">
                                        <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-bootstrap" aria-expanded="false" aria-controls="panelsStayOpen-bootstrap" class="accordion-button collapsed"> Module 12 : Kubernete: </button></h2>
                                        <div id="panelsStayOpen-bootstrap" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                            <div class="accordion-body float-start w-100 px-2 py-0">
                                                <ul class="curriculum-list float-start w-100">
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Basics,API’s</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Kubectl commands</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Pods</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">services</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">labels</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">App creation and deployment</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Autoscaling monitoring</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">kubelet service</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 1 : Setting up raw kubernetes cluster</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 2 : Deploying kubernetes service and network</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 3: Join new servers to kubernetes cluster</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 4: Deploying containers on Kubernetes</span></li>
                                                    <li class="float-start w-100 p-2"><i class="ri-medal-line float-start"></i><span class="float-start ms-2 font-14">Lab 5 : Rolling Deployments, Scaling and Roll-back deployments</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item float-start w-100">
                                        <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-nodejs" aria-expanded="false" aria-controls="panelsStayOpen-nodejs" class="accordion-button collapsed"> Mini Projects (4Hrs)  </button></h2>
                                        <div id="panelsStayOpen-nodejs" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                            <div class="accordion-body float-start w-100 px-2 py-0">
                                                <p>Design a Delivery pipeline (CI and CD) for non cloud environment as per problem statement defined.</p> 
                                                <p>Design a Delivery pipeline (CI & CD) for AWS environment as per problem statement defined.</p>
                                            </div>
                                        </div>
                                    </div>


                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="instructor" role="tabpanel" aria-labelledby="instructor-tab">
                            <div class="course-tab-content">
                                <div class="course-instructor">
                                    <div class="thumbnail">
                                        <img src="./assets/images/author-01.jpeg" alt="Author Images">
                                    </div>
                                    <div class="author-content">
                                        <h6 class="title">Vijay Patil</h6>
                                        <span class="subtitle">10+ Years of Experience</span>
                                        <ul>
                                            <li class="mb-2">Senior UX Designer at UnitedLayer, passionate about crafting intuitive digital interactions.</li>
                                            <li class="mb-2">Leading design efforts for seamless user experiences, leveraging research and collaboration.</li>
                                            <li class="mb-2">Dedicated to innovative solutions at the forefront of UX trends and technologies.</li>
                                            <li class="mb-2">Fostering creativity and teamwork, driving outstanding results through collaboration.</li>
                                            <li class="mb-2">Contributing to UnitedLayer's culture of excellence and innovation.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="review" role="tabpanel" aria-labelledby="review-tab">
                            <div class="course-tab-content">
                                <div class="course-review">
                                    <h3 class="heading-title">Course Rating</h3>
                                    <p>5.00 average rating based on 7 rating</p>
                                    <div class="row g-0 align-items-center">
                                        <div class="col-sm-4">
                                            <div class="rating-box">
                                                <div class="rating-number">5.0</div>
                                                <div class="rating">
                                                    <i class="ri-star-fill"></i>
                                                    <i class="ri-star-fill"></i>
                                                    <i class="ri-star-fill"></i>
                                                    <i class="ri-star-fill"></i>
                                                    <i class="ri-star-fill"></i>
                                                </div>
                                                <span>(7 Review)</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-8">
                                            <div class="review-wrapper">

                                                <div class="single-progress-bar">
                                                    <div class="rating-text">
                                                        5 
                                                        <i class="ri-star-fill"></i>
                                                    </div>
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    <span class="rating-value">7</span>
                                                </div>

                                                <div class="single-progress-bar">
                                                    <div class="rating-text">
                                                        4 
                                                        <i class="ri-star-fill"></i>
                                                    </div>
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    <span class="rating-value">0</span>
                                                </div>

                                                <div class="single-progress-bar">
                                                    <div class="rating-text">
                                                        4 
                                                        <i class="ri-star-fill"></i>
                                                    </div>
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    <span class="rating-value">0</span>
                                                </div>

                                                <div class="single-progress-bar">
                                                    <div class="rating-text">
                                                        4 
                                                        <i class="ri-star-fill"></i>
                                                    </div>
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    <span class="rating-value">0</span>
                                                </div>

                                                <div class="single-progress-bar">
                                                    <div class="rating-text">
                                                        4 
                                                        <i class="ri-star-fill"></i>
                                                    </div>
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    <span class="rating-value">0</span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <!-- Start Comment Area  -->
                                    <div class="comment-area">
                                        <h3 class="heading-title">Reviews</h3>
                                        <div class="comment-list-wrapper">
                                            <!-- Start Single Comment  -->
                                            <div class="comment">
                                                <div class="thumbnail">
                                                    <img src="./assets/images/comment-01.jpeg" alt="Comment Images">
                                                </div>
                                                <div class="comment-content">
                                                    <div class="rating">
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                    </div>
                                                    <h5 class="title">Haley Bennet</h5>
                                                    <span class="date">Oct 10, 2024</span>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                </div>
                                            </div>
                                            <!-- End Single Comment  -->
                                            <!-- Start Single Comment  -->
                                            <div class="comment">
                                                <div class="thumbnail">
                                                    <img src="./assets/images/comment-02.jpeg" alt="Comment Images">
                                                </div>
                                                <div class="comment-content">
                                                    <div class="rating">
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                    </div>
                                                    <h5 class="title">Simon Baker</h5>
                                                    <span class="date">Oct 10, 2024</span>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                </div>
                                            </div>
                                            <!-- End Single Comment  -->
                                            <!-- Start Single Comment  -->
                                            <div class="comment">
                                                <div class="thumbnail">
                                                    <img src="./assets/images/comment-01.jpeg" alt="Comment Images">
                                                </div>
                                                <div class="comment-content">
                                                    <div class="rating">
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                    </div>
                                                    <h6 class="title">Richard Gere</h6>
                                                    <span class="date">Oct 10, 2024</span>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                </div>
                                            </div>
                                            <!-- End Single Comment  -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="course-sidebar-3 sidebar-top-position">
                    <div class="edu-course-widget widget-course-summery">
                        <div class="inner">
                            <div class="thumbnail">
                                <img src="./assets/images/training/course-46.jpeg" alt="Courses">
                                <a href="https://www.youtube.com/watch?v=PICj5tr9hcc" class="play-btn video-popup-activation">
                                    <i class="ri-play-fill"></i>
                                </a>
                            </div>
                            <div class="content">
                                <h4 class="widget-title">Course Includes:</h4>
                                <ul class="course-item">
                                    <li>
                                        <span class="label"><i class="ri-user-smile-line"></i>Instrutor:</span>
                                        <span class="value">Vishakha Chaudhari</span>
                                    </li>
                                    <li>
                                        <span class="label"><i class="ri-time-line"></i>Duration:</span>
                                        <span class="value">48 hrs, 8 weekends</span>
                                    </li>
                                    <li>
                                        <span class="label">
                                            <i class="ri-book-3-line"></i>Lessons:</span>
                                        <span class="value">17</span>
                                    </li>
                                    <li>
                                        <span class="label"><i class="ri-shield-check-line"></i>Enrolled:</span>
                                        <span class="value">19 students</span>
                                    </li>
                                    <li>
                                        <span class="label"><i class="ri-global-line"></i>Language:</span>
                                        <span class="value">English/Hindi</span>
                                    </li>
                                    <li>
                                        <span class="label"><i class="ri-medal-line"></i>Certificate:</span>
                                        <span class="value">Yes</span>
                                    </li>
                                    <li>
                                        <span class="label"><i class="ri-money-rupee-circle-line"></i>Price:</span>
                                        <span class="value price">Rs. 38,000/-</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>