<li class="nav-item mt-3 float-end login-btn" *ngIf="modal2Type == 'botStationAi'">
  <a class="btn-sm btn-primary" aria-current="page" (click)="loginModalStatusClick()">Login</a>
</li>
<div class="modal fade modal1-wrapper modal2-wrapper" [ngClass]="loginModalStatus ? 'show' : 'hide'" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header border-0">
        <div class="header-title w-100">
          <h5 class="modal-title text-center w-100 h4 mt-3">Sign in</h5>
          <div class="close cursor-pointer" (click)="loginModalStatusClick()">
            <span aria-hidden="true">&times;</span>
          </div>
        </div>
      </div>
      <div class="modal-body p-5 pt-3">
          <p class="text-secondary text-center mb-5">Manage your bussiness with bot station AI
            Don’t have an account yet? <span class="text-primary text-underline">Start your 14 day’s trail</span> </p>

            <form>
                <div class="form-group mb-4">
                  <label>Username</label>
                  <i class="ri-user-line"></i>
                  <input type="email" class="form-control" placeholder="Enter Username">
                </div>
                <div class="form-group mb-4">
                  <label>Password</label>
                  <i class="ri-lock-line"></i>
                  <input type="password" class="form-control" placeholder="Enter Password">
                </div>
                <button type="submit" class="btn btn-primary float-start">Login</button>
                <span class="float-end text-primary text-underline mt-3">Forgot Password?</span>
              </form>
      </div>
    </div>
  </div>
</div>
