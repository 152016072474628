
<section class="container schedule-wrapper1 section mb-5 mt-5"  data-aos="zoom-in" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="400" *ngIf="scheduleWrapper1Type=='botStationAi'">
    <div class="row">
        <div class="col-md-7 col-12 text-center">
            <img src="./assets/images/undraw/social_ideas.svg" />
        </div>
        <div class="col-md-5 col-12 mt-5">
            <h4 class="text-white h2">Build Your First Website Chatbot Today</h4>
            <button class="btn btn-primary mt-3">Schedule a demo</button>
        </div>
    </div>
</section>