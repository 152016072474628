<div class="container-fluid pt-5 information-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-12 position-relative">
                <div class="back-btn float-start w-100"></div>
                <div class="heading float-start w-100">
                    <a routerlink="/training-course-details-ux" href="/training-course-details-ux" class="float-start mb-2 cursor-pointer">
                        <i class="ri-arrow-left-circle-line text-secondary font-24 float-start"></i>
                        <span class="float-start ms-2">User-Centered Design Process</span>
                    </a>
                    <h5 class="text-black font-30 float-start w-100">User Experience Design Process</h5>
                </div>
            </div>
            <div class="col-12 mt-3 mb-4">
                <p class="mb-2 font-18">The User Experience Design Process is a practical and straightforward framework for solving complex problems in the digital age. It was created with the intention of making sure that everyone had a shared understanding of what went into digital
                    design, while also ensuring they were able to use this knowledge to create richer, more fulfilling user experiences. </p>
            </div>
            <div class="col-12 mt-3 mb-4">
                <div id="accordionExample" class="accordion">
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#section1" aria-expanded="true" aria-controls="section1" class="accordion-button text-semibold font-18"> Why User Experience Matters? </button></h2>
                        <div id="section1" aria-labelledby="headingOne" data-bs-parent="#accordionExample" class="accordion-collapse collapse show" style="">
                            <div class="accordion-body">
                                <p class="text-quote mb-2 font-20 text-center text-semibold">“Design is not just what it looks like and feels like. Design is how it works.” — Steve Jobs.</p>
                                <p class="mb-2 font-18">Great user experience design not only highlights and promotes your product, it has become a key part of building and growing customer confidence.</p>
                                <p class="mb-2 font-18">A great product or compelling content without an appealing user experience may affect the ability of an organization to achieve its business goals. </p>
                                <p class="mb-2 font-18">Good user experience design is always part of product development process. You interact with your users to build the unique combination of structure, content and user experience that will accomplish required goals in efficient
                                    ways. This approach allows you to create designs that are clean, simple, intuitive, flexible and engaging, and provide a WOW experience to your users and thus differentiate you from your competitors.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#section2" aria-expanded="false" aria-controls="section2" class="accordion-button text-semibold font-18 collapsed"> What are the steps for user experience design process? </button></h2>
                        <div id="section2" aria-labelledby="headingOne" data-bs-parent="#accordionExample" class="accordion-collapse collapse" style="">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">User experience design process is an iterative method that helps you continuously improve and polish your designs.</p>
                                <p class="mb-2 font-18">In the process, you go through different stages repeatedly while evaluating your designs on each stage. Each stage involves relevant stakeholders in your organization that take part in the process to make your products highly
                                    efficient and usable.</p>
                                <p class="mb-2 font-18">The design process involves following six stages.</p>
                                <div class="w-75 m-auto text-center"><img src="./assets/images/training/course-details/design-process.png" class="w-50"></div>
                                <p class="mb-2 font-18">Below is the detail that highlights stakeholders involved, activities being done and outcomes produced during each stage of the process.</p>
                                <ul class="ps-5 disc-listing">
                                    <li>Understand</li>
                                    <li>Research</li>
                                    <li>Sketch</li>
                                    <li>Design</li>
                                    <li>Implement </li>
                                    <li>Evaluate</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#section3" aria-expanded="false" aria-controls="section3" class="accordion-button text-semibold font-18 collapsed"> 1. Understand </button></h2>
                        <div id="section3" aria-labelledby="headingOne" data-bs-parent="#accordionExample" class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">Design solves a problem. In order to provide a solution, you first need to understand the problem.</p>
                                <p class="text-quote mb-2 font-20 text-center text-semibold">“Before beginning the design work, let your Design team understand the requirements clearly.”</p>
                                <p class="mb-2 font-18">To analyze requirements, follow industry standard user research methods including contextual and individual interviews, while observing the users in real environment. Conduct brainstorming sessions with clients and show them
                                    your existing products (if any) to get their feedback. Business Manager is the role in an organization that works directly with clients and gets requirements from them. Design team can work closely with Business Manager
                                    to understand users and their needs. This knowledge about user and his environment helps you to provide a clear direction to your design.</p>
                                <p class="text-quote mb-2 font-20 text-center text-semibold">“To be a great designer, you need to look a little deeper into how people think and act.” — Paul Boag</p>
                                <h6 class="text-semibold font-20 ms-4 mt-2 mb-0">Stakeholders</h6>
                                <ol type="1" class="number-list ms-5">
                                    <li>
                                        <p class="mb-2 font-18">Design Team</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Business Manager</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Product Manager</p>
                                    </li>
                                </ol>
                                <h6 class="text-semibold font-20 mt-3 ms-4 mt-2">Activities</h6>
                                <ol type="1" class="number-list ms-5">
                                    <li>
                                        <p class="mb-2 font-18">Meet, talk, observe and understand users in their environment</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Analyze requirements to understand and clarify them</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Define user personas and use-cases</p>
                                    </li>
                                </ol>
                                <h6 class="text-semibold font-20 mt-3 ms-4 mt-2">Outcomes</h6>
                                <ol type="1" class="number-list ms-5">
                                    <li>
                                        <p class="mb-2 font-18">User Personas</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">User Stories</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Use Cases, User Flows</p>
                                    </li>
                                </ol>
                                <div class="w-50 text-center p-3 float-start"><img src="./assets/images/training/course-details/user-persona.png" class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Create User Personas</p>
                                </div>
                                <div class="w-50 text-center p-3 float-start"><img src="./assets/images/training/course-details/screen-flow.png" class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Define Use-cases and User Flow</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#section4" aria-expanded="false" aria-controls="section4" class="accordion-button text-semibold font-18 collapsed"> 2. Research </button></h2>
                        <div id="section4" aria-labelledby="headingOne" data-bs-parent="#accordionExample" class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="text-quote mb-2 font-20 text-center text-semibold">“It took me a few seconds to draw it, but it took me 34 years to learn how to draw it in a few seconds” — Paula Scher</p>
                                <p class="mb-2 font-18">Research is the basic key step to design user experience. Design team does their research work to explore how the outer world is working on such features. Keep an eye on the latest UI trends, design principles and your existing
                                    user experience guidelines. While doing research, start thinking about possible layouts and options to provide the desired experience.</p>
                                <h6 class="text-semibold font-20 ms-4 mt-2 mb-0">Stakeholders</h6>
                                <ol type="1" class="number-list ms-5">
                                    <li>
                                        <p class="mb-2 font-18">Design Team</p>
                                    </li>
                                </ol>
                                <h6 class="text-semibold font-20 mt-3 ms-4 mt-2">Activities</h6>
                                <ol type="1" class="number-list ms-5">
                                    <li>
                                        <p class="mb-2 font-18">Study of competitors’ approaches</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Research on similar features in the world</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Analysis of latest UI/UX trends, design principles and rules</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Keep an eye on your own UX guidelines</p>
                                    </li>
                                </ol>
                                <h6 class="text-semibold font-20 mt-3 ms-4 mt-2">Outcomes</h6>
                                <ol type="1" class="number-list ms-5">
                                    <li>
                                        <p class="mb-2 font-18">A bunch of ideas and material on which you can build your actual design work</p>
                                    </li>
                                </ol>
                                <div class="w-50 m-auto text-center p-3"><img src="./assets/images/training/course-details/research-idea.png" class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Collect Ideas Related to Required Feature</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#section5" aria-expanded="false" aria-controls="section5" class="accordion-button text-semibold font-18 collapsed"> 3. Sketch </button></h2>
                        <div id="section5" aria-labelledby="headingOne" data-bs-parent="#accordionExample" class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="text-quote mb-2 font-20 text-center text-semibold">“Designing is not something that you just create and start using it. Draw and draft and redraw and redraft, thus creating an unmatched experience.”</p>
                                <p class="mb-2 font-18">This stage involves UI definition of required feature. Design team drives this activity which is based on the last two stages of the process. Draw paper sketches, white board flows and wireframes to share your ideas with stakeholders.
                                    This stage itself is an iterative process. Testing and evaluation of wireframes is part of this stage. Design team builds initial mockups and share with stakeholders to get their input. Throughout the process, it is important
                                    to keep your goal in mind — <span class="text-semibold">make a usable design to achieve end user satisfaction.</span></p>
                                <h6 class="text-semibold font-20 ms-4 mt-2 mb-0">Stakeholders</h6>
                                <ol type="1" class="number-list ms-5">
                                    <li>
                                        <p class="mb-2 font-18">Design Team</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Product Managers</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Technical Experts</p>
                                    </li>
                                </ol>
                                <h6 class="text-semibold font-20 mt-3 ms-4 mt-2">Activities</h6>
                                <ol type="1" class="number-list ms-5">
                                    <li>
                                        <p class="mb-2 font-18">Generate ideas and work on basic sketches</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Brainstorming sessions with stakeholders to get their feedback from technical perspective</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Re-draw sketches and re-test them with stakeholders</p>
                                    </li>
                                </ol>
                                <h6 class="text-semibold font-20 mt-3 ms-4 mt-2">Outcomes</h6>
                                <ol type="1" class="number-list ms-5">
                                    <li>
                                        <p class="mb-2 font-18">Sketches</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Wireframes, Mockups</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">User flows</p>
                                    </li>
                                </ol>
                                <div class="w-50 m-auto text-center p-3"><img src="./assets/images/training/course-details/wireframes.png" class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Create Wireframes</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#section6" aria-expanded="false" aria-controls="section6" class="accordion-button text-semibold font-18 collapsed"> 4. Design </button></h2>
                        <div id="section6" aria-labelledby="headingOne" data-bs-parent="#accordionExample" class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="text-quote mb-2 font-20 text-center text-semibold">“Designing is not something that you just create and start using it. Draw and draft and redraw and redraft, thus creating an unmatched experience.”</p>
                                <p class="mb-2 font-18">Now you have finalized layout and flow of the required interface with you, the next step is to work on final graphics. Turn the initial mockups and wireframes to great-looking images with theme and styles applied to them. Preparing
                                    and sharing of design specifications (principles, guidelines, colors, typography, iconography) to Development team is also part of this stage.</p>
                                <h6 class="text-semibold font-20 ms-4 mt-2 mb-0">Stakeholders</h6>
                                <ol type="1" class="number-list ms-5">
                                    <li>
                                        <p class="mb-2 font-18">Design Team</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Product Managers</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Business Manager</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Technical Experts</p>
                                    </li>
                                </ol>
                                <h6 class="text-semibold font-20 mt-3 ms-4 mt-2">Activities</h6>
                                <ol type="1" class="number-list ms-5">
                                    <li>
                                        <p class="mb-2 font-18">Design UI images</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Define final theme, specs, and guidelines required for implementation</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Design icons to display on screens</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Sessions with stakeholders to get their feedback from business and technical perspective</p>
                                    </li>
                                </ol>
                                <h6 class="text-semibold font-20 mt-3 ms-4 mt-2">Outcomes</h6>
                                <ol type="1" class="number-list ms-5">
                                    <li>
                                        <p class="mb-2 font-18">Design images</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Detailed design specs like colors, theme, styles, guidelines</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Icons</p>
                                    </li>
                                </ol>
                                <div class="w-50 m-auto text-center p-3"><img src="./assets/images/training/course-details/design.png" class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Design Images Followed by Theme and Style — Windows 8 Box app</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#section7" aria-expanded="false" aria-controls="section7" class="accordion-button text-semibold font-18 collapsed"> 5. Implement </button></h2>
                        <div id="section7" aria-labelledby="headingOne" data-bs-parent="#accordionExample" class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="text-quote mb-2 font-20 text-center text-semibold">“Designing is not something that you just create and start using it. Draw and draft and redraw and redraft, thus creating an unmatched experience.”</p>
                                <p class="mb-2 font-18">Since technical people participate in early stages of the process, they can start implementation while Design phase is in progress. Development team builds back end functionality first and connects it with UI when they get
                                    design artifacts. It is better that Design team involves in this step to help development phase. While implementing, it is possible to raise the need of minor changes in design.</p>
                                <h6 class="text-semibold font-20 ms-4 mt-2 mb-0">Stakeholders</h6>
                                <ol type="1" class="number-list ms-5">
                                    <li>
                                        <p class="mb-2 font-18">Development team</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Design Team</p>
                                    </li>
                                </ol>
                                <h6 class="text-semibold font-20 mt-3 ms-4 mt-2">Activities</h6>
                                <ol type="1" class="number-list ms-5">
                                    <li>
                                        <p class="mb-2 font-18">Implement back-end functionality and front interface</p>
                                    </li>
                                </ol>
                                <h6 class="text-semibold font-20 mt-3 ms-4 mt-2">Outcomes</h6>
                                <ol type="1" class="number-list ms-5">
                                    <li>
                                        <p class="mb-2 font-18">Developed UI with complete functionality and experience following the designed theme and style</p>
                                    </li>
                                </ol>
                                <div class="w-50 m-auto text-center p-3"><img src="./assets/images/training/course-details/implement.png" class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Code and Implement Functionality</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#section8" aria-expanded="false" aria-controls="section8" class="accordion-button text-semibold font-18 collapsed"> 6. Evaluate </button></h2>
                        <div id="section8" aria-labelledby="headingOne" data-bs-parent="#accordionExample" class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="text-quote mb-2 font-20 text-center text-semibold">“Design team validates the product in terms of user flow and experience and identify areas where improvements are needed.</p>
                                <p class="mb-2 font-18">When product features are implemented, the end product is evaluated based on few factors:</p>
                                <ul class="disc-listing ps-3 float-start w-100">
                                    <li>
                                        <p class="mb-2 font-18">Whether the system is usable?</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Is it easy to use for end user?</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Is it flexible and easy to change?</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Does it provide the desired solution to user’s problems?</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Does the product have the credibility that makes someone want to use it because of the experience it provides?</p>
                                    </li>
                                </ul>
                                <h6 class="text-semibold font-20 ms-4 mt-4 mb-0">Stakeholders</h6>
                                <ol type="1" class="number-list ms-5">
                                    <li>
                                        <p class="mb-2 font-18">Design Team</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Product Manager</p>
                                    </li>
                                </ol>
                                <h6 class="text-semibold font-20 mt-3 ms-4 mt-2">Activities</h6>
                                <ol type="1" class="number-list ms-5">
                                    <li>
                                        <p class="mb-2 font-18">Go through the flow and feel the experience</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Perform a comparison of implementation and defined interface</p>
                                    </li>
                                </ol>
                                <h6 class="text-semibold font-20 mt-3 ms-4 mt-2">Outcomes</h6>
                                <ol type="1" class="number-list ms-5">
                                    <li>
                                        <p class="mb-2 font-18">User feedback</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">UI audit reports</p>
                                    </li>
                                    <li>
                                        <p class="mb-2 font-18">Areas marked where improvement is required</p>
                                    </li>
                                </ol>
                                <div class="w-50 m-auto text-center p-3"><img src="./assets/images/training/course-details/evaluate.png" class="w-100 border">
                                    <p class="mb-2 font-14 float-start w-100 text-center">Perform User Testing</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 id="headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#section9" aria-expanded="false" aria-controls="section9" class="accordion-button text-semibold font-18 collapsed"> Conclusion </button></h2>
                        <div id="section9" aria-labelledby="headingOne" data-bs-parent="#accordionExample" class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <p class="mb-2 font-18">An amazing user experience can only be provided by following an iterative Design process.</p>
                                <p class="mb-2 font-18">All major stakeholders in your company contribute in the process by performing their tasks and duties. This is the only way you can retain your existing users and attract the new ones in this competitive world.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>