import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-wrapper1',
  templateUrl: './help-wrapper1.component.html',
  styleUrls: ['./help-wrapper1.component.scss']
})
export class HelpWrapper1Component implements OnInit {

  @Input() helpwrapper1Type:string

  constructor() { }

  ngOnInit(): void {
  }

}
