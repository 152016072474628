import { Component, Input, OnInit } from '@angular/core';

import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-testimonial3',
  templateUrl: './testimonial3.component.html',
  styleUrls: ['./testimonial3.component.scss']
})
export class Testimonial3Component implements OnInit {

  @Input() testimonialWrapper3Type:string;

  testimonial1: OwlOptions = {
    loop: true,
    autoplay: true,
    center: false,
    dots: false,
    autoHeight: true,
    autoWidth: true,
    nav: true,
    navText: [ '<i class="ri-arrow-left-s-line"></i>', '<i class="ri-arrow-right-s-line"></i>' ],
    responsive: {
      0: {
        items: 1,
      },
      991: {
        items: 1,
      },
      1024: {
        items: 1,
      },

    }
  }
  constructor() { }

  ngOnInit(): void {
  }

}
