import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-banner1',
  templateUrl: './top-banner1.component.html',
  styleUrls: ['./top-banner1.component.scss']
})
export class TopBanner1Component implements OnInit {

  @Input() topbanner1Type:string;
  constructor() { }

  ngOnInit(): void {
  }

}
