<!-- carousel-image-wrapper1 section start here -->
<section class="container-fluid blog-1 section" *ngIf="blog1Type=='botStationAi'">
    <div class="container">
      <div class="row">
        <div class="col-12" data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-duration="1000">
          <h4 class="text-center h2 mb-5">Articles and News</h4>
        </div>
        <div class="col-12" data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-duration="1000">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper w-100 float-start">
                <div class="float-start carousel-image-item">
                  <img src="./assets/images/undraw/share_opinion.svg" class="w-50 m-auto" />
                </div>
                <p class="float-start w-100 fw-bold">Top 5 Examples of Brands Using Website Chatbots for Customer Support</p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper w-100 float-start">
                <div class="float-start carousel-image-item">
                  <img src="./assets/images/undraw/group_chat.svg" class="w-50 m-auto" />
                </div>
                <p class="float-start w-100 fw-bold">Top 10 Benefits of Adding a Chatbot on Your Website</p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper w-100 float-start">
                <div class="float-start carousel-image-item">
                  <img src="./assets/images/undraw/chatting_re.svg" class="w-50 m-auto" />
                </div>
                <p class="float-start w-100 fw-bold">13 Reasons to Add Live Chat on Your Website</p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper w-100 float-start">
                <div class="float-start carousel-image-item">
                  <img src="./assets/images/undraw/share_opinion.svg" class="w-50 m-auto" />
                </div>
                <p class="float-start w-100 fw-bold">Top 5 Examples of Brands Using Website Chatbots for Customer Support</p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper w-100 float-start">
                <div class="float-start carousel-image-item">
                  <img src="./assets/images/undraw/group_chat.svg" class="w-50 m-auto" />
                </div>
                <p class="float-start w-100 fw-bold">Top 10 Benefits of Adding a Chatbot on Your Website</p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper w-100 float-start">
                <div class="float-start carousel-image-item">
                  <img src="./assets/images/undraw/chatting_re.svg" class="w-50 m-auto" />
                </div>
                <p class="float-start w-100 fw-bold">13 Reasons to Add Live Chat on Your Website</p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper w-100 float-start">
                <div class="float-start carousel-image-item">
                  <img src="./assets/images/undraw/share_opinion.svg" class="w-50 m-auto" />
                </div>
                <p class="float-start w-100 fw-bold">Top 5 Examples of Brands Using Website Chatbots for Customer Support</p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper w-100 float-start">
                <div class="float-start carousel-image-item">
                  <img src="./assets/images/undraw/group_chat.svg" class="w-50 m-auto" />
                </div>
                <p class="float-start w-100 fw-bold">Top 10 Benefits of Adding a Chatbot on Your Website</p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper w-100 float-start">
                <div class="float-start carousel-image-item">
                  <img src="./assets/images/undraw/chatting_re.svg" class="w-50 m-auto" />
                </div>
                <p class="float-start w-100 fw-bold">13 Reasons to Add Live Chat on Your Website</p>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>
  <!-- carousel-image-wrapper1 section end here -->
  