import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-video-player1',
  templateUrl: './video-player1.component.html',
  styleUrls: ['./video-player1.component.scss']
})
export class VideoPlayer1Component implements OnInit {

  playStatus: boolean = false;

  @Input() videoPlayer1Type:string;

  constructor() { }

  ngOnInit(): void {
  }

}
