


        <!-- Navbar Area Start -->
        <div class="navbar-area style-one position-absolute top-0 start-0 w-100" id="header3" *ngIf="header4Type=='training'" >
          <div class="container">
              <div class="navbar-top d-flex flex-wrap align-items-center md-none">
                  <a href="#" class="logo">
                      <img src="../assets/vendor/bruk/img/logo-white.webp" alt="Image">
                  </a>
                  <div class="contact-info d-flex">
                      <div class="contact-item position-relative">
                          <span class="fs-15 d-block"><i class="ri-phone-fill"></i>24/7 Support</span>
                          <a href="tel:5323213333" class="d-block lh-1">+532 321 33 33</a>
                      </div>
                      <div class="contact-item position-relative">
                          <span class="fs-15 d-block"><i class="ri-message-2-fill"></i>Send Your Message</span>
                          <a href="https://templates.hibotheme.com/cdn-cgi/l/email-protection#4a70222f2626250a28383f2164292527" class="d-block lh-1"><span class="__cf_email__" data-cfemail="9cf4f9f0f0f3dcfeeee9f7b2fff3f1">[email&#160;protected]</span></a>
                      </div>
                      <div class="contact-item position-relative">
                          <span class="fs-15 d-block"><i class="ri-map-pin-fill"></i>Our Location</span>
                          <p class="d-block mb-0 lh-1">250 Main Street, 67/T, USA</p>
                      </div>
                  </div>
                  <div class="other-options d-flex align-items-center justify-content-end ms-auto">
                      <!-- <ul class="social-profile list-unstyle ms-auto">
                          <li><a href="https://www.facebook.com/" target="_blank"><i class="ri-facebook-circle-fill"></i></a></li>
                          <li><a href="https://www.twitter.com/" target="_blank"><i class="ri-twitter-x-line"></i></a></li>
                          <li><a href="https://www.instagram.com/" target="_blank"><i class="ri-instagram-fill"></i></a></li>
                          <li><a href="https://www.pinterest.com/" target="_blank"><i class="ri-pinterest-fill"></i></a></li>
                      </ul> -->
                      <a href="#" class="btn style-three ms-auto">Get In Touch</a>
                  </div>
              </div>
              <nav class="navbar navbar-expand-lg d-flex justify-content-between align-items-center bg-white">
                  <a href="#" class="logo d-lg-none">
                      <img src="../assets/vendor/bruk/img/logo.webp" alt="Image" class="logo-light">
                      <img src="../assets/vendor/bruk/img/logo-white.webp" alt="Image" class="logo-dark">
                  </a>
                  <div class="option-item d-flex align-items-center d-lg-none">
                      <button type="button" class="search-btn d-flex flex-column align-items-center justify-content-center bg-transparent border-0" data-bs-toggle="modal" data-bs-target="#searchModal">
                          <i class="ri-search-2-line"></i>
                      </button>
                      <a class="navbar-toggler d-lg-none" data-bs-toggle="offcanvas" href="#navbarOffcanvas" role="button" aria-controls="navbarOffcanvas">
                          <span class="burger-menu">
                              <span class="top-bar"></span>
                              <span class="middle-bar"></span>
                              <span class="bottom-bar"></span>
                          </span>
                      </a>
                  </div>
                  <div class="collapse navbar-collapse">
                      <ul class="navbar-nav me-auto">
                        <li class="nav-item">
                            <a [routerLink]="['/home']" [routerLinkActive]="['active']" class="nav-link">Home</a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="['/about']" [routerLinkActive]="['active']" class="nav-link">About Us</a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="['/services']" [routerLinkActive]="['active']" class="nav-link">Our Services</a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="['/team']" [routerLinkActive]="['active']" class="nav-link">Our Team </a>
                        </li>
                        <li class="nav-item">
                            <a href="#" class="nav-link">Knowledge </a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="['/contactus']" [routerLinkActive]="['active']" class="nav-link">Contact Us </a>
                        </li>
                      </ul>
                      <!-- <div class="others-option d-flex align-items-center justify-content-end">
                          <div class="searchbox position-relative">
                              <input type="search" placeholder="Search" class="fs-15">
                              <button class="bg-transparent border-0 p-0 position-absolute"><i class="ri-search-2-line"></i></button>
                          </div>
                      </div> -->
                  </div>
              </nav>
          </div>
      </div>

      <!-- Start Responsive Navbar Area -->
      <div class="responsive-navbar offcanvas offcanvas-end border-0" data-bs-backdrop="static" tabindex="-1" id="navbarOffcanvas">
          <div class="offcanvas-header">
              <a href="#" class="logo d-inline-block">
                  <img src="assets/img/logo.webp" alt="Image" class="logo-light">
                  <img src="assets/img/logo-white.webp" alt="Image" class="logo-dark">
              </a>
              <button type="button" class="close-btn bg-transparent position-relative lh-1 p-0 border-0" data-bs-dismiss="offcanvas" aria-label="Close">
                  <i class="ri-close-line"></i>
              </button>
          </div>
          <div class="offcanvas-body">
              <ul class="responsive-menu">
                  <li class="responsive-menu-list"><a href="#;" class="active">Home</a>
                      <ul class="responsive-menu-items">
                          <li><a href="#" class="active">Home Finance</a></li>
                          <li><a href="#">Home Business Consulting</a></li>
                          <li><a href="#">Home Digital Banking</a></li>
                      </ul>
                  </li>
                  <li><a href="#">About Us</a></li>
                  <li class="responsive-menu-list"><a href="#;">Services</a>
                      <ul class="responsive-menu-items">
                          <li><a href="#">Services</a></li>
                          <li><a href="#">Service Details</a></li>
                      </ul>
                  </li>
                  <li class="responsive-menu-list"><a href="#;">Pages</a>
                      <ul class="responsive-menu-items">
                          <li class="responsive-menu-list"><a href="#;">Projects</a>
                              <ul class="responsive-menu-items">
                                  <li><a href="#">Projects</a></li>
                                  <li><a href="#">Project Details</a></li>
                              </ul>
                          </li>
                          <li class="responsive-menu-list"><a href="#;">Team</a>
                              <ul class="responsive-menu-items">
                                  <li><a href="#">Our Team</a></li>
                                  <li><a href="#">Team Single</a></li>
                              </ul>
                          </li>
                          <li><a href="#">Pricing Plan</a></li>
                          <li><a href="#">FAQ</a></li>
                          <li><a href="#">Terms & Conditions</a></li>
                          <li><a href="#">Privacy Policy</a></li>
                          <li><a href="#">404 Error</a></li>
                      </ul>
                  </li>
                  <li class="responsive-menu-list"><a href="#;">Blog</a>
                      <ul class="responsive-menu-items">
                          <li class="responsive-menu-list"> <a href="#;">Blog Layout</a>
                              <ul class="responsive-menu-items">
                                  <li><a href="#">Blog Left Sidebar</a></li>
                                  <li><a href="#">Blog Right Sidebar</a></li>
                                  <li><a href="#">Blog Grid</a></li>
                              </ul>
                          </li>
                          <li class="responsive-menu-list"><a href="#;">Blog Details Layout</a>
                              <ul class="responsive-menu-items">
                                  <li><a href="#">Blog Details Left Sidebar</a></li>
                                  <li><a href="#">Blog Details Right Sidebar</a></li>
                                  <li><a href="#">Blog Details Without Sidebar</a></li>
                              </ul>
                          </li>
                      </ul>
                  </li>
                  <li><a href="#">Contact Us</a></li>
              </ul>
              <div class="contact-info d-flex">
                  <div class="contact-item position-relative">
                      <span class="fs-15 d-block"><i class="ri-phone-fill"></i>24/7 Support</span>
                      <a href="tel:5323213333" class="d-block lh-1">+532 321 33 33</a>
                  </div>
                  <div class="contact-item position-relative">
                      <span class="fs-15 d-block"><i class="ri-message-2-fill"></i>Send Your Message</span>
                      <a href="https://templates.hibotheme.com/cdn-cgi/l/email-protection#665c0e030a0a09260414130d4805090b" class="d-block lh-1"><span class="__cf_email__" data-cfemail="38505d545457785a4a4d53165b5755">[email&#160;protected]</span></a>
                  </div>
                  <div class="contact-item position-relative">
                      <span class="fs-15 d-block"><i class="ri-map-pin-fill"></i>Our Location</span>
                      <p class="d-block mb-0 lh-1">250 Main Street, 67/T, USA</p>
                  </div>
              </div>
              <div class="other-options d-flex flex-wrap align-items-center justify-content-start">
                  <ul class="social-profile list-unstyle ms-auto">
                      <li><a href="https://www.facebook.com/" target="_blank"><i class="ri-facebook-circle-fill"></i></a></li>
                      <li><a href="https://www.twitter.com/" target="_blank"><i class="ri-twitter-x-line"></i></a></li>
                      <li><a href="https://www.instagram.com/" target="_blank"><i class="ri-instagram-fill"></i></a></li>
                      <li><a href="https://www.pinterest.com/" target="_blank"><i class="ri-pinterest-fill"></i></a></li>
                  </ul>
                  <a href="#" class="btn style-three">Get In Touch</a>
              </div>
          </div>
      </div>