import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-price-plan-wrapper1',
  templateUrl: './price-plan-wrapper1.component.html',
  styleUrls: ['./price-plan-wrapper1.component.scss']
})
export class PricePlanWrapper1Component implements OnInit {

  @Input() priceTableWrapper1Type:string;

  constructor() { }

  ngOnInit(): void {
  }

}
