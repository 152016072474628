import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-image-text-wrapper3',
  templateUrl: './image-text-wrapper3.component.html',
  styleUrls: ['./image-text-wrapper3.component.scss']
})
export class ImageTextWrapper3Component implements OnInit {

  @Input() imageTextwrapper3Type:string;

  constructor() { }

  ngOnInit(): void {
  }

}
