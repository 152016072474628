import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-design-process-assignments',
  templateUrl: './design-process-assignments.component.html',
  styleUrls: ['./design-process-assignments.component.scss']
})
export class DesignProcessAssignmentsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
