<!-- Header2 start here-->
<app-header4 [header4Type]="'training'"></app-header4>
<!-- Header2 end here-->

<div class="edu-breadcrumb-area breadcrumb-style-3">
    <div class="container">
        <div class="breadcrumb-inner">
            <ul class="edu-breadcrumb">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="separator"><i class="ri-arrow-right-s-line"></i></li>
                <li class="breadcrumb-item"><a href="course-one.html">Courses</a></li>
                <li class="separator"><i class="ri-arrow-right-s-line"></i></li>
                <li class="breadcrumb-item active" aria-current="page">Course Details</li>
            </ul>
            <div class="page-title">
                <h1 class="title">AWS Course: Skill And Certification Oriented Course </h1>
            </div>
            <ul class="course-meta">
                <li><i class="ri-shield-user-line"></i>By Vishakha Chaudhari</li>
                <li><i class="ri-global-line"></i>English/Hindi</li>
                <li class="course-rating">
                    <div class="rating">
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-half-line"></i>
                    </div>
                    <span class="rating-count">(109 Rating)</span>
                </li>
            </ul>
        </div>
    </div>
    <ul class="shape-group">
        <li class="shape-1">
            <span></span>
        </li>
        <!-- <li class="shape-2 scene" style="transform: translate3d(0px, 0px, 0px) rotate(0.0001deg); transform-style: preserve-3d; backface-visibility: hidden; pointer-events: none;">
            <img data-depth="2" src="./assets/images/shape-13.png" alt="shape" style="transform: translate3d(5.4px, -0.1px, 0px); transform-style: preserve-3d; backface-visibility: hidden; position: relative; display: block; left: 0px; top: 0px;">
        </li> -->
        <li class="shape-3 scene" style="transform: translate3d(0px, 0px, 0px) rotate(0.0001deg); transform-style: preserve-3d; backface-visibility: hidden; pointer-events: none;">
            <img data-depth="-2" src="./assets/images/training/shape-13.png" alt="shape" style="transform: translate3d(-3.4px, 0px, 0px); transform-style: preserve-3d; backface-visibility: hidden; position: relative; display: block; left: 0px; top: 0px;">
        </li>
        <li class="shape-4">
            <span></span>
        </li>
        <li class="shape-5 scene" style="transform: translate3d(0px, 0px, 0px) rotate(0.0001deg); transform-style: preserve-3d; backface-visibility: hidden; pointer-events: none;">
            <img data-depth="2" src="./assets/images/shape-07.png" alt="shape" style="transform: translate3d(4.2px, -0.1px, 0px); transform-style: preserve-3d; backface-visibility: hidden; position: relative; display: block; left: 0px; top: 0px;">
        </li>
    </ul>
</div>
<section class="edu-section-gap course-details-area">
    <div class="container">
        <div class="row row--30">
            <div class="col-lg-8">
                <div class="course-details-content">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true">Overview</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="carriculam-tab" data-bs-toggle="tab" data-bs-target="#carriculam" type="button" role="tab" aria-controls="carriculam" aria-selected="false">Carriculam</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="instructor-tab" data-bs-toggle="tab" data-bs-target="#instructor" type="button" role="tab" aria-controls="instructor" aria-selected="false">Instructor</button>
                        </li>

                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="review-tab" data-bs-toggle="tab" data-bs-target="#review" type="button" role="tab" aria-controls="review" aria-selected="false">Reviews</button>
                        </li>
                    </ul>

                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                            <div class="course-tab-content">
                                <div class="course-overview">
                                    
                                    <h3 class="heading-title"> Who can do this course</h3>
                                    <p>Anyone (Students, working professionals: Developers, Administrators, Testers, Architects) who wish to learn and make career in AWS </p>


                                    <h3 class="heading-title mt-5"> Prerequisites</h3>
                                    <ul class="mb--60">
                                        <li><i class="ri-check-double-line"></i> Operating Systems (how they work and operate at a high level): Windows, Linux and a bit of basic concepts about them. </li>
                                        <li><i class="ri-check-double-line"></i> Basics of Virtualization concepts </li>
                                        <li><i class="ri-check-double-line"></i> Basics of Networking concepts</li>
                                        <li><i class="ri-check-double-line"></i> Basics of Private and Public cloud</li>
                                    </ul>

                                    <h3 class="heading-title mt-5">Course Deliverables </h3>
                                    <ul class="mb--60">
                                        <li><i class="ri-check-double-line"></i> Ability to Explain AWS Services </li>
                                        <li><i class="ri-check-double-line"></i> Describe key services on the AWS platform and their common use cases</li>
                                        <li><i class="ri-check-double-line"></i> Describe basic security and compliance aspects of the AWS platform and the shared security model.</li>
                                        <li><i class="ri-check-double-line"></i> Describe AWS Cloud architectural principles. </li>
                                        <li><i class="ri-check-double-line"></i> Certification covered: 
                                            <ol>
                                                <li> AWS Cloud Practitioner </li>
                                                <li> AWS Architect Associate </li>
                                            </ol>
                                        </li>
                                        <li><i class="ri-check-double-line"></i> User Management Capability in AWS</li>
                                        <li><i class="ri-check-double-line"></i> Able to Setup Various Backup Scenarios in AWS </li>
                                        <li><i class="ri-check-double-line"></i> Able to create complete Datacentre Network on AWS </li>
                                        <li><i class="ri-check-double-line"></i> Able to create Virtual Machines on AWS</li>
                                        <li><i class="ri-check-double-line"></i> Able to create highly avaliable and Scalable DNS on AWS </li>
                                        <li><i class="ri-check-double-line"></i> Able to create Databases on AWS with Understanding of Best Practices </li>
                                        <li><i class="ri-check-double-line"></i> Understanding of various application Services </li>
                                        <li><i class="ri-check-double-line"></i> Understanding of AWS Best Practices </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="carriculam" role="tabpanel" aria-labelledby="carriculam-tab">
                            <div class="course-tab-content">
                                <div class="course-curriculam">
                                    <h3 class="heading-title">Module 1: Cloud Practitioner Architect </h3>
                                    <div class="card-body border-0 curriculum-wrapper float-start w-100">
                                        <div id="accordionPanelsStayOpenExample" class="accordion custom-accordion">
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-introduction" aria-expanded="true" aria-controls="panelsStayOpen-introduction" class="accordion-button"> Module1: Introduction to Cloud Computing </button></h2>
                                                <div id="panelsStayOpen-introduction" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse show">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Introduction to AWS Services</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">AWS Platform </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">AWS Global Infrastructure </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Regions and Availability Zones</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">LAB :- AWS Management Console Know-How</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-designTheory" aria-expanded="true" aria-controls="panelsStayOpen-designTheory" class="accordion-button collapsed"> Module2: AWS Services Introduction - Part 1  </button></h2>
                                                <div id="panelsStayOpen-designTheory" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">IAM Introduction</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> S3 Introduction</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> EC2 Introduction  </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14"> Lab :- Interacting with these services  </span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-userResearch" aria-expanded="false" aria-controls="panelsStayOpen-userResearch" class="accordion-button collapsed"> Module3: AWS Services Introduction - Part 2  </button></h2>
                                                <div id="panelsStayOpen-userResearch" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Load Balancers</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Databases</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Autoscaling</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Elastic Beanstalk </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">CloudFormation </span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-wireframe" aria-expanded="false" aria-controls="panelsStayOpen-wireframe" class="accordion-button collapsed"> Module4: Overview of AWS Billing </button></h2>
                                                <div id="panelsStayOpen-wireframe" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Introduction </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Detailed Billing Reports, Resource </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Groups and Tagging </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">AWS Organizations </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Consolidated Billing </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">AWS EC2 & TCO Calculator </span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-prototyping" aria-expanded="false" aria-controls="panelsStayOpen-prototyping" class="accordion-button collapsed"> Module 5: AWS Security Overview </button></h2>
                                                <div id="panelsStayOpen-prototyping" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Shared Responsibility Model  </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">AWS Risk and Compliance</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">AWS Trusted Advisor</span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">AWS Inspector, CloudTrail and Config </span></li>
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">AWS WAF and AWS Shield </span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-creativity" aria-expanded="false" aria-controls="panelsStayOpen-creativity" class="accordion-button collapsed"> Module 6: Architecting for AWS  </button></h2>
                                                <div id="panelsStayOpen-creativity" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <ul class="curriculum-list float-start w-100">
                                                            <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">AWS Best Practices   </span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item float-start w-100">
                                                <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-HTML" aria-expanded="false" aria-controls="panelsStayOpen-HTML" class="accordion-button collapsed"> Module 7: Course summary and mock exam </button></h2>
                                                <div id="panelsStayOpen-HTML" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                                    <div class="accordion-body float-start w-100 px-2 py-0">
                                                        <p>Dates are based on class schedule</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <h3 class="heading-title mb-0 mt-5 pt-5">Module 2: AWS Architect Associate </h3>
                                    <div class="card-body border-0 curriculum-wrapper float-start w-100">
                                        <div id="accordionPanelsStayOpenExample" class="accordion custom-accordion">

                                    <div class="accordion-item float-start w-100">
                                        <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-html5" aria-expanded="false" aria-controls="panelsStayOpen-html5" class="accordion-button collapsed"> Module 1: Introduction to AWS Services</button></h2>
                                        <div id="panelsStayOpen-html5" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                            <div class="accordion-body float-start w-100 px-2 py-0">
                                                <ul class="curriculum-list float-start w-100">
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Overview of the Exam </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Overview of Regions and AZ's</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item float-start w-100">
                                        <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-CSS" aria-expanded="false" aria-controls="panelsStayOpen-CSS" class="accordion-button collapsed"> Module 2 :Deep Dive into IAM </button></h2>
                                        <div id="panelsStayOpen-CSS" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                            <div class="accordion-body float-start w-100 px-2 py-0">
                                                <ul class="curriculum-list float-start w-100">
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Details of IAM Service </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 1 :- Creating Users, Groups and Roles Security Token Service </span></li> 
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item float-start w-100">
                                        <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-CSS3" aria-expanded="false" aria-controls="panelsStayOpen-CSS3" class="accordion-button collapsed">  Module 3 :Deep Dive into AWS Storage Services and CDN  </button></h2>
                                        <div id="panelsStayOpen-CSS3" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                            <div class="accordion-body float-start w-100 px-2 py-0">
                                                <ul class="curriculum-list float-start w-100">
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Introduction to S3 and Various classes </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">S3 Versioning </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">S3 Lifecycle Management and Glacier </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Cross Region Replication</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">S3 Security and Encryption </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">S3 Transfer Acceleration </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 1 :-  Exercises on S3 </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-question-line float-start"></i><span class="float-start ms-2 font-14">Storage Gateway </span></li>
                                                    <li class="float-start w-100 p-2"><i class="ri-medal-line float-start"></i><span class="float-start ms-2 font-14">Snowball</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item float-start w-100">
                                        <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-cssProcessors" aria-expanded="false" aria-controls="panelsStayOpen-cssProcessors" class="accordion-button collapsed"> Module 4 :Deep Dive into VPC  </button></h2>
                                        <div id="panelsStayOpen-cssProcessors" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                            <div class="accordion-body float-start w-100 px-2 py-0">
                                                <ul class="curriculum-list float-start w-100">
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Introduction to VPC</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Difference Between Default and Custom VPC</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Introduction to Gateways </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Understanding Route tables and Subnets </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Understanding Security Groups and NACL's</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">NAT's and Bastion Host </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-question-line float-start"></i><span class="float-start ms-2 font-14">VPC Endpoints</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-question-line float-start"></i><span class="float-start ms-2 font-14">Lab 1 :- VPC flow Logs</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-question-line float-start"></i><span class="float-start ms-2 font-14">Lab 2 :- Creating a Custom VPC </span></li>
                                                    <li class="float-start w-100 p-2"><i class="ri-medal-line float-start"></i><span class="float-start ms-2 font-14">Assignments</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item float-start w-100">
                                        <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-bootstrap" aria-expanded="false" aria-controls="panelsStayOpen-bootstrap" class="accordion-button collapsed"> Module 5 :Deep Dive into EC2</button></h2>
                                        <div id="panelsStayOpen-bootstrap" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                            <div class="accordion-body float-start w-100 px-2 py-0">
                                                <ul class="curriculum-list float-start w-100">
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Introduction to EC2</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Introduction to EBS Volumes</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 1 :- Creating an EC2 Instances</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab2: Working with EBS Volumes </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 3 :- Creating AMI's and LaunchingInstance </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 4 :- Setting up CloudWatch Alarms in EC2 </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">AWS Command line tool and bootstrapping</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">S3 CLI and Regions</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Understanding Autoscaling</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">EC2 placement Groups and Elastic File system</span></li>
                                                    <li class="float-start w-100 p-2"><i class="ri-medal-line float-start"></i><span class="float-start ms-2 font-14">Introduction to Lambda</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item float-start w-100">
                                        <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-nodejs" aria-expanded="false" aria-controls="panelsStayOpen-nodejs" class="accordion-button collapsed"> Module 6 :Route 53  </button></h2>
                                        <div id="panelsStayOpen-nodejs" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                            <div class="accordion-body float-start w-100 px-2 py-0">
                                                <ul class="curriculum-list float-start w-100">
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Introduction to Route 53</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Routing Policies and Health Checks </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 1 :- Route 53 Register a Domain Name</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab2:Simple Routing Policy</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 3 :- Weighted Routing Policy Lab </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab4Latency Based Routing Policy l </span></li>
                                                    <li class="float-start w-100 p-2"><i class="ri-medal-line float-start"></i><span class="float-start ms-2 font-14">Lab 5 :- Failover Routing Policy Lab</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item float-start w-100">
                                        <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-cssProcessors" aria-expanded="false" aria-controls="panelsStayOpen-cssProcessors" class="accordion-button collapsed"> Module 7 :Databases on AWS  </button></h2>
                                        <div id="panelsStayOpen-cssProcessors" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                            <div class="accordion-body float-start w-100 px-2 py-0">
                                                <ul class="curriculum-list float-start w-100">
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Introduction to Databases </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Lab 1 :- Create and RDS Instance </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">RDS - Backups</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Multi-AZ and Read Replicas Concept </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">DynamoDB</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Elasticache</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-question-line float-start"></i><span class="float-start ms-2 font-14">Redshift</span></li>
                                                    <li class="float-start w-100 p-2"><i class="ri-medal-line float-start"></i><span class="float-start ms-2 font-14">Aurora</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item float-start w-100">
                                        <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-new1" aria-expanded="false" aria-controls="panelsStayOpen-new1" class="accordion-button collapsed"> Module 8 :Introduction to Application Services </button></h2>
                                        <div id="panelsStayOpen-new1" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                            <div class="accordion-body float-start w-100 px-2 py-0">
                                                <ul class="curriculum-list float-start w-100">
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">SQA </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">SNS </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">SWF</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Elastic Transcoder </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">API Gateway </span></li>
                                                    <li class="float-start w-100 p-2"><i class="ri-medal-line float-start"></i><span class="float-start ms-2 font-14">Kinesis</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item float-start w-100">
                                        <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-new2" aria-expanded="false" aria-controls="panelsStayOpen-new2" class="accordion-button collapsed"> Module 9 : AWS Whitepapers Walkthrough </button></h2>
                                        <div id="panelsStayOpen-new2" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                            <div class="accordion-body float-start w-100 px-2 py-0">
                                                <ul class="curriculum-list float-start w-100">
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Overview of Amazon Web Services  </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Overview of Security Processes  </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">AWS Risk and Compliance </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Elastic Transcoder </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Storage Options in the Cloud  </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Architecting for the AWS cloud  </span></li>
                                                    <li class="float-start w-100 p-2"><i class="ri-medal-line float-start"></i><span class="float-start ms-2 font-14">Well Architected Framework</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item float-start w-100">
                                        <h2 id="panelsStayOpen-headingOne" class="accordion-header"><button type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-new3" aria-expanded="false" aria-controls="panelsStayOpen-new3" class="accordion-button collapsed"> Module 10 : Other Important Topics </button></h2>
                                        <div id="panelsStayOpen-new3" aria-labelledby="panelsStayOpen-headingOne" class="accordion-collapse collapse">
                                            <div class="accordion-body float-start w-100 px-2 py-0">
                                                <ul class="curriculum-list float-start w-100">
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Consolidated billing </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">AWS Organizations </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Cross Account Access </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Resource Groups and Tagging </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">VPC Peering</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Direct Connect </span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">ECS</span></li>
                                                    <li class="float-start w-100 border-bottom p-2"><i class="ri-file-line float-start"></i><span class="float-start ms-2 font-14">Active Directory Integration </span></li>
                                                    <li class="float-start w-100 p-2"><i class="ri-medal-line float-start"></i><span class="float-start ms-2 font-14">Workspaces</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>



                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="instructor" role="tabpanel" aria-labelledby="instructor-tab">
                            <div class="course-tab-content">
                                <div class="course-instructor">
                                    <div class="thumbnail">
                                        <img src="./assets/images/author-01.jpeg" alt="Author Images">
                                    </div>
                                    <div class="author-content">
                                        <h6 class="title">Vijay Patil</h6>
                                        <span class="subtitle">10+ Years of Experience</span>
                                        <ul>
                                            <li class="mb-2">Senior UX Designer at UnitedLayer, passionate about crafting intuitive digital interactions.</li>
                                            <li class="mb-2">Leading design efforts for seamless user experiences, leveraging research and collaboration.</li>
                                            <li class="mb-2">Dedicated to innovative solutions at the forefront of UX trends and technologies.</li>
                                            <li class="mb-2">Fostering creativity and teamwork, driving outstanding results through collaboration.</li>
                                            <li class="mb-2">Contributing to UnitedLayer's culture of excellence and innovation.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="review" role="tabpanel" aria-labelledby="review-tab">
                            <div class="course-tab-content">
                                <div class="course-review">
                                    <h3 class="heading-title">Course Rating</h3>
                                    <p>5.00 average rating based on 7 rating</p>
                                    <div class="row g-0 align-items-center">
                                        <div class="col-sm-4">
                                            <div class="rating-box">
                                                <div class="rating-number">5.0</div>
                                                <div class="rating">
                                                    <i class="ri-star-fill"></i>
                                                    <i class="ri-star-fill"></i>
                                                    <i class="ri-star-fill"></i>
                                                    <i class="ri-star-fill"></i>
                                                    <i class="ri-star-fill"></i>
                                                </div>
                                                <span>(7 Review)</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-8">
                                            <div class="review-wrapper">

                                                <div class="single-progress-bar">
                                                    <div class="rating-text">
                                                        5 
                                                        <i class="ri-star-fill"></i>
                                                    </div>
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    <span class="rating-value">7</span>
                                                </div>

                                                <div class="single-progress-bar">
                                                    <div class="rating-text">
                                                        4 
                                                        <i class="ri-star-fill"></i>
                                                    </div>
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    <span class="rating-value">0</span>
                                                </div>

                                                <div class="single-progress-bar">
                                                    <div class="rating-text">
                                                        4 
                                                        <i class="ri-star-fill"></i>
                                                    </div>
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    <span class="rating-value">0</span>
                                                </div>

                                                <div class="single-progress-bar">
                                                    <div class="rating-text">
                                                        4 
                                                        <i class="ri-star-fill"></i>
                                                    </div>
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    <span class="rating-value">0</span>
                                                </div>

                                                <div class="single-progress-bar">
                                                    <div class="rating-text">
                                                        4 
                                                        <i class="ri-star-fill"></i>
                                                    </div>
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    <span class="rating-value">0</span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <!-- Start Comment Area  -->
                                    <div class="comment-area">
                                        <h3 class="heading-title">Reviews</h3>
                                        <div class="comment-list-wrapper">
                                            <!-- Start Single Comment  -->
                                            <div class="comment">
                                                <div class="thumbnail">
                                                    <img src="./assets/images/comment-01.jpeg" alt="Comment Images">
                                                </div>
                                                <div class="comment-content">
                                                    <div class="rating">
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                    </div>
                                                    <h5 class="title">Haley Bennet</h5>
                                                    <span class="date">Oct 10, 2024</span>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                </div>
                                            </div>
                                            <!-- End Single Comment  -->
                                            <!-- Start Single Comment  -->
                                            <div class="comment">
                                                <div class="thumbnail">
                                                    <img src="./assets/images/comment-02.jpeg" alt="Comment Images">
                                                </div>
                                                <div class="comment-content">
                                                    <div class="rating">
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                    </div>
                                                    <h5 class="title">Simon Baker</h5>
                                                    <span class="date">Oct 10, 2024</span>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                </div>
                                            </div>
                                            <!-- End Single Comment  -->
                                            <!-- Start Single Comment  -->
                                            <div class="comment">
                                                <div class="thumbnail">
                                                    <img src="./assets/images/comment-01.jpeg" alt="Comment Images">
                                                </div>
                                                <div class="comment-content">
                                                    <div class="rating">
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                        <i class="ri-star-fill"></i>
                                                    </div>
                                                    <h6 class="title">Richard Gere</h6>
                                                    <span class="date">Oct 10, 2024</span>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                </div>
                                            </div>
                                            <!-- End Single Comment  -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="course-sidebar-3 sidebar-top-position">
                    <div class="edu-course-widget widget-course-summery">
                        <div class="inner">
                            <div class="thumbnail">
                                <img src="./assets/images/training/course-46.jpeg" alt="Courses">
                                <a href="https://www.youtube.com/watch?v=PICj5tr9hcc" class="play-btn video-popup-activation">
                                    <i class="ri-play-fill"></i>
                                </a>
                            </div>
                            <div class="content">
                                <h4 class="widget-title">Course Includes:</h4>
                                <ul class="course-item">
                                    <li>
                                        <span class="label"><i class="ri-user-smile-line"></i>Instrutor:</span>
                                        <span class="value">Vishakha Chaudhari</span>
                                    </li>
                                    <li>
                                        <span class="label"><i class="ri-time-line"></i>Duration:</span>
                                        <span class="value">48 hrs, 8 weekends</span>
                                    </li>
                                    <li>
                                        <span class="label">
                                            <i class="ri-book-3-line"></i>Lessons:</span>
                                        <span class="value">17</span>
                                    </li>
                                    <li>
                                        <span class="label"><i class="ri-shield-check-line"></i>Enrolled:</span>
                                        <span class="value">19 students</span>
                                    </li>
                                    <li>
                                        <span class="label"><i class="ri-global-line"></i>Language:</span>
                                        <span class="value">English/Hindi</span>
                                    </li>
                                    <li>
                                        <span class="label"><i class="ri-medal-line"></i>Certificate:</span>
                                        <span class="value">Yes</span>
                                    </li>
                                    <li>
                                        <span class="label"><i class="ri-money-rupee-circle-line"></i>Price:</span>
                                        <span class="value price">Rs. 38,000/-</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>