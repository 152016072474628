import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overview-design-process',
  templateUrl: './overview-design-process.component.html',
  styleUrls: ['./overview-design-process.component.scss']
})
export class OverviewDesignProcessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scroll(0,0);
  }

}
