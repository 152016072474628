import { Component, Input, OnInit } from '@angular/core';

import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-testimonial2',
  templateUrl: './testimonial2.component.html',
  styleUrls: ['./testimonial2.component.scss']
})
export class Testimonial2Component implements OnInit {

  @Input() testimonialWrapper2Type:string;

  testimonial1: OwlOptions = {
    loop: true,
    autoplay: true,
    center: false,
    dots: true,
    autoHeight: true,
    autoWidth: true,
    nav: false,
    navText: [ '<i class="ri-arrow-left-s-line"></i>', '<i class="ri-arrow-right-s-line"></i>' ],
    responsive: {
      0: {
        items: 1,
      },
      991: {
        items: 2,
      },
      1024: {
        items: 3,
      },

    }
  }
  constructor() { }

  ngOnInit(): void {
  }

}
