
<!-- image-text-wrapper3 section start here -->
<section class="container-fluid image-text-wrapper3 section bg-light-blue pb-0" *ngIf="imageTextwrapper3Type=='corporateBlue'">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12 left-section pt-5">
          <div class="float-start w-75">
            <h2 class="heading fw-bold mb-4 h2" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
              Experienced by
              <span class="text-primary fw-bold">46,000+</span> People.
            </h2>
            <p class="mb-4 small text-muted" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
              Enthusiastically morph unique web-readiness via impactful platforms.
              Intrinsicly matrix premium expertise for diverse expertise.
              Intrinsicly drive collaborative bandwidth for accurate testing.
            </p>
          </div>
          <div class="float-start w-75 border-bottom pb-4 pt-4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="900">
            <div class="icon-wrapper float-start text-center" >
              <i class="ri-map-pin-line text-primary"></i>
            </div>
            <div class="text-wrapper float-start">
              <h4 class="sub-heading mt-2 mb-2">RESPONSIVE LAYOUT</h4>
              <p class="small text-muted">
                Powerful Layout with Responsive functionality that can be adapted
                to any screen size. Resize browser to view.
              </p>
            </div>
          </div>
          <div class="float-start w-75 pb-4 pt-4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1300">
            <div class="icon-wrapper float-start text-center">
              <i class="ri-bank-card-2-line text-danger"></i>
            </div>
            <div class="text-wrapper float-start">
              <h4 class="sub-heading mt-2 mb-2">LIGHT & DARK COLOR SCHEME</h4>
              <p class="small text-muted">
                Change your Website's Primary Scheme instantly by simply adding
                the dark class to the body.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0 right-section text-center">
          <div class="float-start w-100 pt-5 bg-dotted position-relative" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
            <img src="./assets/images/devices/iphone-hand.png" />
            <video preload loop autoplay muted >
              <source src="./assets/images/video/form-video.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- image-text-wrapper3 section end here -->