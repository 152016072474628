import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intro-interview-qsn-ans',
  templateUrl: './intro-interview-qsn-ans.component.html',
  styleUrls: ['./intro-interview-qsn-ans.component.scss']
})
export class IntroInterviewQsnAnsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
