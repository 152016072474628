import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nmnp-about',
  templateUrl: './nmnp-about.component.html',
  styleUrls: ['./nmnp-about.component.scss']
})
export class NmnpAboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
