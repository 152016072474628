import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscribe1',
  templateUrl: './subscribe1.component.html',
  styleUrls: ['./subscribe1.component.scss']
})
export class Subscribe1Component implements OnInit {

  @Input() subscribeWrapper1Type:string;

  constructor() { }

  ngOnInit(): void {
  }

}
