import { Component, Input, OnInit } from '@angular/core';

import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-testimonial1',
  templateUrl: './testimonial1.component.html',
  styleUrls: ['./testimonial1.component.scss']
})
export class Testimonial1Component implements OnInit {

  @Input() testimonialWrapper1Type:string;

  testimonial1: OwlOptions = {
    loop: true,
    autoplay: true,
    center: false,
    dots: false,
    autoHeight: true,
    autoWidth: true,
    nav: true,
    navText: [ '<i class="ri-arrow-left-s-line"></i>', '<i class="ri-arrow-right-s-line"></i>' ],
    responsive: {
      0: {
        items: 1,
      }
    }
  }
  constructor() { }

  ngOnInit(): void {
  }

}
