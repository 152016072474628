<!-- Top banner start here-->
<section class="container-fluid top-banner1 bg-blue" *ngIf="topbanner1Type=='corporateBlue'">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="top-banner-wrapper float-start w-100">
          <h2 class="display-4 mb-5 text-white" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">Build
            Your Programming Skill with Edublink</h2>
          <p class="mb-4 text-light text-font125" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="800">
            Best Wallet App for your upcoming Projects.
          </p>
          <div class="w-100 mt-2">
            <a href="#" class="btn me-3 mt-2 text-dark bg-white rounded-3 px-4 py-3 nott ls0 shadow-sm 
            btn-hover-moveUp" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1600">
              <i class="ri-google-play-fill"></i>
              Get it on the App Store
            </a>
            <a href="#" class="ms-0 mt-2 btn text-dark bg-white rounded-3 px-4 py-3 nott ls0 shadow-sm 
            btn-hover-moveUp" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1600">
              <i class="ri-apple-fill"></i>
              Get it on Play Store
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-1 d-md-none d-lg-block"></div>
      <div class="col-lg-5 col-md-6 align-self-lg-end">
        <div class="slide-imgs" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="2200">
          <img src="./assets/images/devices/top-banner-1.png" alt="Image" class="card-img" />
          <img src="./assets/images/devices/top-banner-2.png" alt="Image" class="iphone-img" />
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Top banner end here -->
