<!-- image-text-wrapper3 section start here -->
<section class="container-fluid section testimonial-wrapper-3" *ngIf="testimonialWrapper3Type=='botStationAi'">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center heading-wrapper mb-3">
        <div class="w-75 m-auto">
          <h4 class="h2 mb-3" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000">
            Testimonials by<span class="chatbot-text-primary"> Clients</span>
          </h4>
          <p class="text-muted" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="200">
            We will be happy to organize a 30 minute demo to showcase why the
            industry leaders prefer all in one for Conversational AI platform.
          </p>
        </div>
      </div>
    </div>
    <div class="row" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="400">
      <div class="col-12">
        <owl-carousel-o [options]="testimonial1">
          <ng-template carouselSlide>
            <div class="testimonial-item">
              <div class="testimonial-content float-start w-100">
                <div class="top-bladge float-start">
                  <span
                    class="
                      bold-text
                      float-start
                      w-100
                      text-center text-white
                      fw-bold
                    "
                    >80%</span
                  >
                  <span
                    class="
                      support-text
                      float-start
                      w-100
                      text-center text-white
                      mt-2
                    "
                    >Open Rate</span
                  >
                </div>
                <div class="profile-img float-start">
                  <img src="./assets/images/users/1.png" />
                </div>
                <div class="testominial-text float-end">
                  <p>
                    Your reply and action has been very prompt, and I appreciate
                    your promptness in addressing customer concerns. Keep up the
                    good work!
                  </p>
                  <div class="testi-meta mt-2 float-start w-100">
                    <span class="float-start w-100 mb-2 fw-bold">
                      <span class="chatbot-text-primary fw-bold"
                        >- Ana Watson
                      </span>
                      <span> Syntel Inc</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="testimonial-item">
              <div class="testimonial-content float-start w-100">
                <div class="top-bladge float-start">
                  <span
                    class="
                      bold-text
                      float-start
                      w-100
                      text-center text-white
                      fw-bold
                    "
                    >100%</span
                  >
                  <span
                    class="
                      support-text
                      float-start
                      w-100
                      text-center text-white
                      mt-2
                    "
                    >Chat Support</span
                  >
                </div>
                <div class="profile-img float-start">
                  <img src="./assets/images/users/2.png" />
                </div>
                <div class="testominial-text float-end">
                  <p>
                    We use Tetrapack.io to send Proactive Messages to which we
                    want users to reply. Because we know Verloop.io has an
                    excellent way to build customer journeys to streamline the
                    communication
                  </p>
                  <div class="testi-meta mt-2 float-start w-100">
                    <span class="float-start w-100 mb-2 fw-bold">
                      <span class="chatbot-text-primary fw-bold"
                        >- John Doe
                      </span>
                      <span> Tetrapack</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="testimonial-item">
              <div class="testimonial-content float-start w-100">
                <div class="top-bladge float-start">
                  <span
                    class="
                      bold-text
                      float-start
                      w-100
                      text-center text-white
                      fw-bold
                    "
                    >96%</span
                  >
                  <span
                    class="
                      support-text
                      float-start
                      w-100
                      text-center text-white
                      mt-2
                    "
                    >Bot Deflection</span
                  >
                </div>
                <div class="profile-img float-start">
                  <img src="./assets/images/users/3.png" />
                </div>
                <div class="testominial-text float-end">
                  <p>
                    We, at Microsoft, have been able to follow the “less human
                    intervention, more automation” model with a reduction of 33%
                    of our agents’ time over chat. With Verloop.io’s automation
                    in play, we’ve been able to handle the larger volumes of
                    customer queries coming in too.
                  </p>
                  <div class="testi-meta mt-2 float-start w-100">
                    <span class="float-start w-100 mb-2 fw-bold">
                      <span class="chatbot-text-primary fw-bold"
                        >- Andrew Lee
                      </span>
                      <span> Microsoft</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- image-text-wrapper3 section end here -->
