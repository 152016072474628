<app-header4 [header4Type]="'training'"></app-header4>



        <!-- Breadcrumb Section Start -->
        <div class="breadcrumb-wrap position-relative index-1 bg-title">
            <div class="br-bg br-bg-1 position-absolute top-0 end-0 md-none"></div>
            <img src="../assets/vendor/bruk/img/breadcrumb/br-shape-2.webp" alt="Image" class="br-shape-two position-absolute">
            <div class="container position-relative">
                <img src="../assets/vendor/bruk/img/breadcrumb/br-shape-1.webp" alt="Image" class="br-shape-one position-absolute md-none">
                <img src="../assets/vendor/bruk/img/breadcrumb/br-shape-3.webp" alt="Image" class="br-shape-three position-absolute md-none">
                <div class="row">
                    <div class="col-xxl-6 col-xl-6 col-lg-6">
                        <ul class="br-menu list-unstyle d-inline-block">
                            <li><a href="#">Home</a></li>
                            <li>Service</li>
                        </ul>
                        <h2 class="br-title fw-medium text-white mb-0">Investment Planning: Raise Capital Faster & Negotiate On Your Own Terms</h2>
                    </div>
                </div>
            </div>
        </div>
        <!-- Breadcrumb Section End -->

        <!-- Service Section Start -->
        <div class="container ptb-130">
            <div class="row justify-content-center">
                <div class="col-xxl-3 col-xl-4 col-md-6">
                    <div class="service-card bg-optional text-center transition mb-65">
                        <img src="../assets/vendor/bruk/img/icons/investment.svg" alt="Image" class="service-icon transition">
                        <h3 class="fs-24"><a href="#" class="transition">Investment</a></h3>
                        <p class="transition">Ever Find Yourself Staring At  Computer is Screen A Good that Consulting that</p>
                        <a href="#" class="service-link d-flex flex-column align-items-center justify-content-center round-2 transition"><img src="../assets/vendor/bruk/img/icons/right-arrow-2.svg" alt="Image"></a>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-md-6">
                    <div class="service-card bg-optional text-center transition mb-65">
                        <img src="../assets/vendor/bruk/img/icons/retirement.svg" alt="Image" class="service-icon transition">
                        <h3 class="fs-24"><a href="#" class="transition">Retirement</a></h3>
                        <p class="transition">Ever Find Yourself Staring At  Computer is Screen A Good that Consulting that</p>
                        <a href="#" class="service-link d-flex flex-column align-items-center justify-content-center round-2 transition"><img src="../assets/vendor/bruk/img/icons/right-arrow-2.svg" alt="Image"></a>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-md-6">
                    <div class="service-card bg-optional text-center transition mb-65">
                        <img src="../assets/vendor/bruk/img/icons/education.svg" alt="Image" class="service-icon transition">
                        <h3 class="fs-24"><a href="#" class="transition">Education</a></h3>
                        <p class="transition">Ever Find Yourself Staring At  Computer is Screen A Good that Consulting that</p>
                        <a href="#" class="service-link d-flex flex-column align-items-center justify-content-center round-2 transition"><img src="../assets/vendor/bruk/img/icons/right-arrow-2.svg" alt="Image"></a>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-md-6">
                    <div class="service-card bg-optional text-center transition mb-65">
                        <img src="../assets/vendor/bruk/img/icons/investment.svg" alt="Image" class="service-icon transition">
                        <h3 class="fs-24"><a href="#" class="transition">Management</a></h3>
                        <p class="transition">Ever Find Yourself Staring At  Computer is Screen A Good that Consulting that</p>
                        <a href="#" class="service-link d-flex flex-column align-items-center justify-content-center round-2 transition"><img src="../assets/vendor/bruk/img/icons/right-arrow-2.svg" alt="Image"></a>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-md-6">
                    <div class="service-card bg-optional text-center transition mb-65">
                        <img src="../assets/vendor/bruk/img/icons/retirement.svg" alt="Image" class="service-icon transition">
                        <h3 class="fs-24"><a href="#" class="transition">Wealth Collection</a></h3>
                        <p class="transition">Ever Find Yourself Staring At  Computer is Screen A Good that Consulting that</p>
                        <a href="#" class="service-link d-flex flex-column align-items-center justify-content-center round-2 transition"><img src="../assets/vendor/bruk/img/icons/right-arrow-2.svg" alt="Image"></a>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-md-6">
                    <div class="service-card bg-optional text-center transition mb-65">
                        <img src="../assets/vendor/bruk/img/icons/education.svg" alt="Image" class="service-icon transition">
                        <h3 class="fs-24"><a href="#" class="transition">Risk Management</a></h3>
                        <p class="transition">Ever Find Yourself Staring At  Computer is Screen A Good that Consulting that</p>
                        <a href="#" class="service-link d-flex flex-column align-items-center justify-content-center round-2 transition"><img src="../assets/vendor/bruk/img/icons/right-arrow-2.svg" alt="Image"></a>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-md-6">
                    <div class="service-card bg-optional text-center transition mb-65">
                        <img src="../assets/vendor/bruk/img/icons/investment.svg" alt="Image" class="service-icon transition">
                        <h3 class="fs-24"><a href="#" class="transition">Tax Planning</a></h3>
                        <p class="transition">Ever Find Yourself Staring At  Computer is Screen A Good that Consulting that</p>
                        <a href="#" class="service-link d-flex flex-column align-items-center justify-content-center round-2 transition"><img src="../assets/vendor/bruk/img/icons/right-arrow-2.svg" alt="Image"></a>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-md-6">
                    <div class="service-card bg-optional text-center transition mb-65">
                        <img src="../assets/vendor/bruk/img/icons/retirement.svg" alt="Image" class="service-icon transition">
                        <h3 class="fs-24"><a href="#" class="transition">Estate Planning</a></h3>
                        <p class="transition">Ever Find Yourself Staring At  Computer is Screen A Good that Consulting that</p>
                        <a href="#" class="service-link d-flex flex-column align-items-center justify-content-center round-2 transition"><img src="../assets/vendor/bruk/img/icons/right-arrow-2.svg" alt="Image"></a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Service Section End -->

        <!-- Working Process Section Start -->
        <div class="work-process-wrap pb-100 position-relative">
            <div class="container px-xxl-0">
                <div class="section-title text-center mb-50">
                    <span class="section-subtitle d-inline-block fs-15 fw-semibold text-title">Work Process</span>
                    <h2 class="mb-15">How It Works</h2>
                </div>
                <div class="work-card-wrap d-flex flex-wrap justify-content-center">
                    <div class="work-card text-center position-relative index-1 mb-30">
                        <h3 class="fs-24">User Input And Authentication</h3>
                        <p>Users start by accessing the financial website through a web browser or a mobile application. They may need to create an account or log in using existing.</p>
                        <span class="work-counter text-white fs-20 d-flex flex-column align-items-center justify-content-center mx-auto">01</span>
                    </div>
                    <div class="work-card text-center position-relative index-1 mb-30">
                        <h3 class="fs-24">Data Retrieval And Analysis</h3>
                        <p>Users start by accessing the financial website through a web browser or a mobile application. They may need to create an account or log in using existing.</p>
                        <span class="work-counter text-white fs-20 d-flex flex-column align-items-center justify-content-center mx-auto">02</span>
                    </div>
                    <div class="work-card text-center position-relative index-1 mb-30">
                        <h3 class="fs-24">Transactions And Communication</h3>
                        <p>Users start by accessing the financial website through a web browser or a mobile application. They may need to create an account or log in using existing.</p>
                        <span class="work-counter text-white fs-20 d-flex flex-column align-items-center justify-content-center mx-auto">03</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- Working Process Section End -->


<app-footer2 [footer2Type]="'botStationAi'"></app-footer2>