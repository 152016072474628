import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-detail-uxui-overview',
  templateUrl: './course-detail-uxui-overview.component.html',
  styleUrls: ['./course-detail-uxui-overview.component.scss']
})
export class CourseDetailUxuiOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    window.scroll(0,0);
  }

}
