import { Component, OnInit, Input } from '@angular/core';

import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-carousel-wrapper1',
  templateUrl: './carousel-wrapper1.component.html',
  styleUrls: ['./carousel-wrapper1.component.scss']
})
export class CarouselWrapper1Component implements OnInit {

  @Input() carouselWrapper1Type : string;

  limit: number = 10; // <==== Edit this number to limit API results
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    center: false,
    dots: false,
    autoHeight: true,
    autoWidth: true,
    nav: true,
    navText: [ '<i class="ri-arrow-left-s-line"></i>', '<i class="ri-arrow-right-s-line"></i>' ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      }
    }
  }
  constructor() { }

  ngOnInit(): void {
  }

}
