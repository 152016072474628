
<app-header4 [header4Type]="'training'"></app-header4>


        
        <!-- Breadcrumb Section Start -->
        <div class="breadcrumb-wrap position-relative index-1 bg-title">
            <div class="br-bg br-bg-4 position-absolute top-0 end-0 md-none"></div>
            <img src="../assets/vendor/bruk/img/breadcrumb/br-shape-2.webp" alt="Image" class="br-shape-two position-absolute">
            <div class="container position-relative">
                <img src="../assets/vendor/bruk/img/breadcrumb/br-shape-1.webp" alt="Image" class="br-shape-one position-absolute md-none">
                <img src="../assets/vendor/bruk/img/breadcrumb/br-shape-3.webp" alt="Image" class="br-shape-three position-absolute md-none">
                <div class="row">
                    <div class="col-xxl-6 col-xl-6 col-lg-6">
                        <ul class="br-menu list-unstyle d-inline-block">
                            <li><a href="#">Home</a></li>
                            <li>Contact Us</li>
                        </ul>
                        <h2 class="br-title fw-medium text-white mb-0">Need Any Help? You can Contact With Us</h2>
                    </div>
                </div>
            </div>
        </div>
        <!-- Breadcrumb Section End -->

        <!-- Contact Section Start -->
        <div class="contact-wrap style-one position-relative ptb-130 overflow-hidden">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="contact-img-wrap position-relative me-xxl-auto mb-md-30">
                            <div class="contact-img position-relative">
                                <img src="../assets/vendor/bruk/img/contact/contact-img-1.webp" alt="Image">
                            </div>
                            <div class="client-review position-absolute bg-optional d-flex align-items-center justify-content-between round-2">
                                <div class="trustlogo d-flex align-items-center">
                                    <div class="client-logo">
                                        <p class="fw-bold text-title">Review On</p>
                                        <img src="../assets/vendor/bruk/img/contact/trustpilot-logo.webp" alt="Image" class="logo-light">
                                        <img src="../assets/vendor/bruk/img/contact/trustpilot-logo-2.webp" alt="Image" class="logo-dark">
                                    </div>
                                    <div class="ratings">
                                        <ul class="list-unstyle d-flex">
                                            <li><img src="../assets/vendor/bruk/img/icons/star-2.svg" alt="Image"></li>
                                            <li><img src="../assets/vendor/bruk/img/icons/star-2.svg" alt="Image"></li>
                                            <li><img src="../assets/vendor/bruk/img/icons/star-2.svg" alt="Image"></li>
                                            <li><img src="../assets/vendor/bruk/img/icons/star-2.svg" alt="Image"></li>
                                            <li><img src="../assets/vendor/bruk/img/icons/half-star.svg" alt="Image"></li>
                                        </ul>
                                        <span>544+ Reviews</span>
                                    </div>
                                </div>
                                <div class="client-ratings d-flex flex-wrap align-items-center justify-content-md-between">
                                    <img src="../assets/vendor/bruk/img/contact/google.webp" alt="Image" class="logo-light">
                                    <div class="ratings">
                                        <ul class="list-unstyle d-flex">
                                            <li><i class="ri-star-s-fill"></i></li>
                                            <li><i class="ri-star-s-fill"></i></li>
                                            <li><i class="ri-star-s-fill"></i></li>
                                            <li><i class="ri-star-s-fill"></i></li>
                                            <li><i class="ri-star-s-fill"></i></li>
                                        </ul>
                                        <span>Reviews 4.9/5.0</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="contact-content mb-0">
                            <div class="section-title mb-30">
                                <span class="section-subtitle d-inline-block fs-15 fw-semibold text-title">Contact With Us</span>
                                <h2 class="mb-0">Contact With Us</h2>
                            </div>
                            <form action="#" class="contact-form">
                                <div class="form-group d-flex flex-wrap align-items-center justify-content-between">
                                    <label for="email">Your email</label>
                                    <input type="email" name="email" id="email" class="fs-15 h-52 bg-transparent">
                                </div>
                                <div class="form-group d-flex flex-wrap align-items-center justify-content-between">
                                    <label for="name">Your name</label>
                                    <input type="text" name="name" id="name" class="fs-15 h-52 bg-transparent">
                                </div>
                                <div class="form-group d-flex flex-wrap align-items-center justify-content-between">
                                    <label for="phone_no">Phone</label>
                                    <input type="number" name="phone_no" id="phone_no" class="fs-15 h-52 bg-transparent">
                                </div>
                                <div class="form-group d-flex flex-wrap align-items-center justify-content-between">
                                    <label for="website_url">Website</label>
                                    <input type="url" name="website_url" id="website_url" class="fs-15 h-52 bg-transparent">
                                </div>
                                <div class="form-group d-flex flex-wrap align-items-center justify-content-between">
                                    <label for="interest">Interested</label>
                                    <select name="interest" id="interest" class="fs-15 bg-trasnparent">
                                        <option value="1">Banking</option>
                                        <option value="2">Finance</option>
                                        <option value="3">Investment</option>
                                    </select>
                                </div>
                                <div class="form-group d-flex flex-wrap align-items-start justify-content-between">
                                    <label for="msg" class="pt-xxl-2">Message</label>
                                    <textarea name="msg" id="msg" cols="30" rows="10" class="bg-transparent resize-0" placeholder="Type a message"></textarea>
                                </div>
                                <div class="form-group d-flex flex-wrap align-items-center">
                                    <label for="msg"></label>
                                    <button class="btn style-two">Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container pb-100">
            <div class="row align-items-center">
                <div class="col-xl-8 col-md-6 pe-xxl-45">
                    <div class="comp-map mb-30">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8385385572983!2d144.95358331584498!3d-37.81725074201705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d4dd5a05d97%3A0x3e64f855a564844d!2s121%20King%20St%2C%20Melbourne%20VIC%203000%2C%20Australia!5e0!3m2!1sen!2sbd!4v1612419490850!5m2!1sen!2sbd">
                        </iframe>
                    </div>
                </div>
                <div class="col-xl-4 col-md-6 ps-xxl-45">
                    <div class="contact-info-box-wrap">
                        <div class="contact-info-box">
                            <h3 class="fs-24 fw-medium mb-15">Contact us</h3>
                            <ul class="list-unstyle">
                                <li><span class="text-title fw-bold me-2">Toll Free:</span><a href="tel:16433534356">1-6433-534356</a></li>
                                <li><span class="text-title fw-bold me-2">Fax Number:</span><a href="tel:535866552723">535-86655-2723</a></li>
                                <li><span class="text-title fw-bold me-2">Fax Number:</span><a href="https://templates.hibotheme.com/cdn-cgi/l/email-protection#c4b7b1b4b4abb6b084a6b6b1afeaa7aba9"><span class="__cf_email__" data-cfemail="ed9e989d9d829f99ad8f9f9886c38e8280">[email&#160;protected]</span></a></li>
                            </ul>
                        </div>
                        <div class="contact-info-box">
                            <h3 class="fs-24 fw-medium mb-15">Our Address</h3>
                           <p class="mb-0">Yukon, Inc.201 West Hilton Street, Suite ZY 113Fayetteville, NY 13066-1313</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Contact Section End -->



<app-footer2 [footer2Type]="'botStationAi'"></app-footer2>