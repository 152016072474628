<div class="container-fluid pt-5 information-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-12 position-relative">
                <div class="back-btn float-start w-100"></div>
                <div class="heading float-start w-100"><a routerlink="/course-detail-uxui" class="float-start mb-2 cursor-pointer" href="/training/course-detail-uxui"><i class="ri-arrow-left-circle-line text-secondary font-24 float-start"></i><span class="float-start ms-2">User-Centered Design Process</span></a>
                    <h5
                    class="text-black font-30 float-start w-100 mt-3">Assignments</h5>
                </div>
            </div>
            <div class="col-12 mt-3">
                <h5 class="text-semibold font-18 mb-3">1. Finalize minimum 3 indivitual project specifications</h5>
                <h5 class="text-semibold font-18 mb-3">2. Finalize minimum 2 group project specifications</h5>
                <h5 class="text-semibold font-18 mb-3">3. Finalize minimum 1 individual case study specifications</h5>
            </div>
        </div>
    </div>
</div>