
<!-- carousel-image-wrapper1 section start here -->
<section class="container-fluid carousel-image-wrapper2 section info-wrapper-1 bg-light-blue" *ngIf="carouselWrapper2Type=='corporateBlue'" data-aos="zoom-in" data-aos-duration="1000">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper col">
                <img src="./assets/images/brand-logo/amazon-1.svg" class="m-auto" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper col">
                <img src="./assets/images/brand-logo/facebook-1.svg" class="m-auto" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper col">
                <img src="./assets/images/brand-logo/google-1.svg" class="m-auto" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper col">
                <img src="./assets/images/brand-logo/linkedin-1.svg" class="m-auto" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper col">
                <img src="./assets/images/brand-logo/netflix-1.svg" class="m-auto" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="carousel-image-wrapper col">
                <img src="./assets/images/brand-logo/tesla-1.svg" class="m-auto" />
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>
  <!-- carousel-image-wrapper1 section end here -->