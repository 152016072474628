<nav class="navbar navbar-expand-lg bg-blue static-top common-header2 pt-4 pb-4" *ngIf="header2Type=='corporateBlue'" >
    <div class="container">
        <!-- Brand -->
      <a class="navbar-brand" [routerLink]="['/corporate-blue-home']">
        <img src="./assets/images/logo-dark-trimmy.png" alt="logo" />
      </a>
      <!-- Toggler -->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon text-white">
            <i class="ri-menu-3-line"></i>
        </span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <a class="nav-link active text-white" aria-current="page" [routerLink]="['/corporate-blue-home']">Home</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Features
            </a>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                <li>
                    <a class="dropdown-item" [routerLink]="['/corporate-blue-features']">
                        <i class="ri-bubble-chart-fill float-start"></i> Products
                    </a>
                </li>
                <li>
                    <a class="dropdown-item" [routerLink]="['/corporate-blue-features']">
                        <i class="ri-download-cloud-line float-start"></i> Downloads
                    </a>
                </li>
                <li>
                    <a class="dropdown-item" [routerLink]="['/corporate-blue-features']">
                        <i class="ri-question-line float-start"></i> Support
                    </a>
                </li>
                <li>
                    <a class="dropdown-item" [routerLink]="['/corporate-blue-features']">
                        <i class="ri-file-copy-2-line float-start"></i> Documentation
                    </a>
                </li>
                <li>
                    <a class="dropdown-item" [routerLink]="['/corporate-blue-features']">
                        <i class="ri-video-line float-start"></i> Tutorials
                    </a>
                </li>
                <li>
                    <a class="dropdown-item" [routerLink]="['/corporate-blue-features']">
                        <i class="ri-flag-2-line float-start"></i> Solutions
                    </a>
                </li>
                <li>
                    <a class="dropdown-item" [routerLink]="['/corporate-blue-features']">
                        <i class="ri-search-line float-start"></i> FAQ's
                    </a>
                </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link active text-white" aria-current="page" [routerLink]="['/corporate-blue-about']">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active text-white" aria-current="page" [routerLink]="['/corporate-blue-price']">Pricing</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active text-white" aria-current="page" [routerLink]="['/corporate-blue-contact']">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>