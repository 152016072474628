<footer id="footer" class="container-fluid section footer-2 chatbot-wrapper" *ngIf="footer2Type=='botStationAi'" data-aos="fade-up" data-aos-duration="1000">
  <div class="container">
    <div class="footer-widgets-wrap">
      <div class="row">
        <div class="col-md-3 col-12">
          <div class="widget widget_links">
            <h4 class="text-white mb-3">Company</h4>

            <ul>
              <li><a href="#">About us</a></li>
              <li><a href="#">What we do</a></li>
              <li><a href="#">Contacts</a></li>
              <li><a href="#">Investor Relations</a></li>
              <li><a href="#">Media Relations</a></li>
              <li><a href="#">Jobs Opportunities</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-12">
          <div class="widget widget_links">
            <h4 class="text-white mb-3">Product</h4>

            <ul>
              <li><a href="#">Log in or Register</a></li>
              <li><a href="#">Plans and Prices</a></li>
              <li><a href="#">Our Blog</a></li>
              <li><a href="#">Developer Program</a></li>
              <li><a href="#">Agency Program</a></li>
              <li><a href="#">Account Program</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-12">
          <div class="widget widget_links">
            <h4 class="text-white mb-3">Legal notices</h4>

            <ul>
              <li><a href="#">Company information</a></li>
              <li><a href="#">Privacy Policy</a></li>
              <li><a href="#">Cookie Policy</a></li>
              <li><a href="#">Terms and Conditions</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-12">
          <div class="widget widget_links mb-5">
            <h4 class="text-white mb-3">Registered address</h4>

            <ul>
              <li>
                  <p>International Tech Park 
                      Bangalore(ITPB), 
                      Bengaluru, Karnataka 560066</p>
              </li>
            </ul>
          </div>
          <div class="widget widget_links">
            <h4 class="text-white mb-3">Contact us</h4>

            <ul>
              <li>
                  <p>Do you have any queries or ideas 
                      in mind? Let's chat! 
                      hello@allinone.com</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

</footer>
