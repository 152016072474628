import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-why-wrapper1',
  templateUrl: './why-wrapper1.component.html',
  styleUrls: ['./why-wrapper1.component.scss']
})
export class WhyWrapper1Component implements OnInit {

  @Input() whyWrappre1Type:string;

  constructor() { }

  ngOnInit(): void {
  }

}
