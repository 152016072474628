import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-training-course-details-ux',
  templateUrl: './training-course-details-ux.component.html',
  styleUrls: ['./training-course-details-ux.component.scss']
})
export class TrainingCourseDetailsUxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
    window.scroll(0,0);
    
  }

}
