import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-training-course-details-aws',
  templateUrl: './training-course-details-aws.component.html',
  styleUrls: ['./training-course-details-aws.component.scss']
})
export class TrainingCourseDetailsAwsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scroll(0,0);
  }

}
