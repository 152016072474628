
<!-- image-text-wrapper1 section start here -->
<section class="container subscribe-wrapper1 chatbot-wrapper" *ngIf="subscribeWrapper1Type=='botStationAi'">
    <div class="row">
        <div class="col-12 col-md-4 text-center device-image" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="600">
            <img src="./assets/images/devices/classic.png" />
        </div>
        <div class="col-12 col-md-8 vertical-center">
            <div class="w-100 float-start text-wrapper" data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="600">
                <h4 class="h2 mb-2">
                    <span class="chatbot-text-primary">Subscribe </span>to our weekly news
                </h4>
                <p class="text-muted">
                    Start a free Bot Station AI trial and build your first chatbot today!
                </p>
                <form class="subscribe-form mt-3" novalidate>
                    <div class="email-field float-start">
                        <input type="text" class="form-control" placeholder="Email Address" required>
                        <div class="valid-feedback">
                          Looks good!
                        </div>
                    </div>
                    <div class="btn-wrapper float-start">
                      <button class="btn btn-primary" type="submit">Subscribe Now</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
  </section>
  <!-- image-text-wrapper1 section end here -->