import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab1',
  templateUrl: './tab1.component.html',
  styleUrls: ['./tab1.component.scss']
})
export class Tab1Component implements OnInit {

  @Input() tabWrapper1Type:string;

  active = 1;
  constructor() { }

  ngOnInit(): void {
  }

}
