import { Component, HostListener, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-modal1',
  templateUrl: './modal1.component.html',
  styleUrls: ['./modal1.component.scss']
})
export class Modal1Component implements OnInit {

  @Input() modal1Type:string;
  
  loginModalStatus: boolean = false;
  step1Status: boolean=true;
  step1btnStatus: boolean=true;
  step2Status:boolean=false;
  step2btnStatus: boolean=false;
  step3Status:boolean=false;
  step3btnStatus: boolean=false;
  step4Status:boolean=false;
  step4btnStatus: boolean=false;
  step5Status:boolean=false;
  step5btnStatus: boolean=false;
  step6Status:boolean=false;

  value1 = '';
  value2 = '';
  value3 = '';
  value4 = '';
  value5 = '';

  loginModalStatusClick(){
    this.loginModalStatus = !this.loginModalStatus;
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });   
  }

  onKey1(event: any) {
    this.value1 = event.target.value ;
  }
  onKey2(event: any) {
    this.value2 = event.target.value ;
  }
  onKey3(event: any) {
    this.value3 = event.target.value ;
  }
  onKey4(event: any) {
    this.value4 = event.target.value ;
  }
  onKey5(event: any) {
    this.value4 = event.target.value ;
  }
  constructor() { }
  step1(){
    this.step1btnStatus = !this.step1btnStatus;
    this.step2btnStatus = !this.step2btnStatus;
    this.step2Status = !this.step2Status;
  }
  step2(){
    this.step2btnStatus = !this.step2btnStatus;
    this.step3btnStatus = !this.step3btnStatus;
    this.step3Status = !this.step3Status;
  }
  step3(){
    this.step3btnStatus = !this.step3btnStatus;
    this.step4btnStatus = !this.step4btnStatus;
    this.step4Status = !this.step4Status;
  }
  step4(){
    this.step4btnStatus = !this.step4btnStatus;
    this.step5btnStatus = !this.step5btnStatus;
    this.step5Status = !this.step5Status;
  }
  step5(){
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.step1Status = !this.step1Status;
    this.step2Status = !this.step2Status;
    this.step3Status = !this.step3Status;
    this.step4Status = !this.step4Status;
    this.step5Status = !this.step5Status;
    this.step5btnStatus = !this.step5btnStatus;
    this.step6Status = !this.step6Status;
  }
  ngOnInit(): void {
  }

}
