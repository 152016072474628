import { Component, Input, OnInit } from '@angular/core';

import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-carousel-wrapper4',
  templateUrl: './carousel-wrapper4.component.html',
  styleUrls: ['./carousel-wrapper4.component.scss']
})
export class CarouselWrapper4Component implements OnInit {

  @Input() carouselWrapper4Type:string;

  limit: number = 10; // <==== Edit this number to limit API results
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    center: false,
    dots: false,
    autoHeight: true,
    autoWidth: true,
    nav: false,
    navText: [ '','' ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      769: {
        items: 3,
      },
    }
  }
  customOptions1: OwlOptions = {
    loop: true,
    autoplay: true,
    center: false,
    dots: false,
    autoHeight: true,
    autoWidth: true,
    nav: false,
    navText: [ '','' ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      769: {
        items: 2,
      },
    }
  }
  constructor() { }

  ngOnInit(): void {
  }

}
