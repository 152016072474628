<nav class="navbar navbar-expand-lg navbar-light bg-white common-header1" data-aos="fade-up" data-aos-once="true">
    <div class="container">
  
      <!-- Brand -->
      <a class="navbar-brand" href="#">
        <img src="./assets/images/brand-logo/paypal.svg" class="navbar-brand-img" alt="...">
      </a>
  
      <!-- Toggler -->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
  
      <!-- Collapse -->
      <div class="collapse navbar-collapse animate custom-scrollbar" id="navbarCollapse">
  
        <!-- Toggler -->
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <i class="fe fe-x"></i>
        </button>
  
        <!-- Navigation -->
        <ul class="navbar-nav ms-auto">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="navbarLandings" data-bs-toggle="dropdown" href="#" aria-haspopup="true" aria-expanded="false">
              Landings
            </a>
            <div class="dropdown-menu default-dropdown dropdown-menu-xl p-0" aria-labelledby="navbarLandings">
              <div class="row gx-0">
                <div class="col-12 col-lg-6 text-center text-wrapper">
                  <div class="dropdown-img-start h-100">
                    <div class="dropdown-img-text pt-1">
                      <!-- Heading -->
                      <h5 class="fw-bold text-white mb-2 pt-5">
                        Want to see an overview?
                      </h5>
                      <!-- Text -->
                      <p class="fs-sm text-white">
                        See all the pages at once.
                      </p>
                      <!-- Button -->
                      <a href="#" class="btn btn-white shadow-dark fonFt-size-sm mt-3">
                        View all pages
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="dropdown-body">
                    <div class="row gx-0 p-4">
                      <div class="col-6">
  
                        <!-- Heading -->
                        <h6 class="dropdown-header text-style-medium">
                          Services
                        </h6>
  
                        <!-- List -->
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Coworking
                        </a>
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Rental
                        </a>
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Job Listing
                        </a>
  
                        <!-- Heading -->
                        <h6 class="dropdown-header">
                          Apps
                        </h6>
  
                        <!-- List -->
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Desktop
                        </a>
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Mobile
                        </a>
  
                      </div>
                      <div class="col-6">
  
                        <!-- Heading -->
                        <h6 class="dropdown-header">
                          Web
                        </h6>
  
                        <!-- List -->
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Basic
                        </a>
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Startup
                        </a>
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Enterprise
                        </a>
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Service
                        </a>
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Cloud Hosting
                        </a>
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Agency
                        </a>
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Framework <span class="small text-uppercase text-primary">(new)</span>
                        </a>
  
                      </div>
                    </div> <!-- / .row -->
                  </div>
                </div>
              </div> <!-- / .row -->
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="navbarPages" data-bs-toggle="dropdown" href="#" aria-haspopup="true" aria-expanded="false">
              Pages
            </a>
            <div class="dropdown-menu dropdown-menu-xl default-dropdown p-3" aria-labelledby="navbarPages">

              <div class="dropdown-body">
                <div class="row gx-0">
                  <div class="col-6">
                    <div class="row gx-0">
                      <div class="col-12 col-lg-6">
    
                        <!-- Heading -->
                        <h6 class="dropdown-header">
                          Career
                        </h6>
    
                        <!-- List -->
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Listing
                        </a>
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Opening
                        </a>
    
                        <!-- Heading -->
                        <h6 class="dropdown-header">
                          Company
                        </h6>
    
                        <!-- List -->
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          About
                        </a>
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Pricing
                        </a>
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Terms
                        </a>
    
                      </div>
                      <div class="col-12 col-lg-6">
    
                        <!-- Heading -->
                        <h6 class="dropdown-header">
                          Help center
                        </h6>
    
                        <!-- List -->
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Overview
                        </a>
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Article
                        </a>
    
                        <!-- Heading -->
                        <h6 class="dropdown-header">
                          Contact
                        </h6>
    
                        <!-- List -->
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Basic
                        </a>
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Cover
                        </a>
    
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row gx-0">
                      <div class="col-12 col-lg-6">
    
                        <!-- Heading -->
                        <h6 class="dropdown-header">
                          Blog
                        </h6>
    
                        <!-- List -->
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Rich View
                        </a>
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Article
                        </a>
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Showcase
                        </a>
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Search
                        </a>
    
                      </div>
                      <div class="col-12 col-lg-6">
    
                        <!-- Heading -->
                        <h6 class="dropdown-header">
                          Portfolio
                        </h6>
    
                        <!-- List -->
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Masonry
                        </a>
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Grid Rows
                        </a>
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Parallax
                        </a>
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Case Study
                        </a>
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Sidebar
                        </a>
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Sidebar: Fluid
                        </a>
                        <a class="dropdown-item" [routerLink]="['/home']" >
                          Basic Grid
                        </a>
    
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> <!-- / .row -->
          </li>
          <li class="nav-item dropdown right-side-dropdown">
            <a class="nav-link dropdown-toggle" id="navbarAccount" data-bs-toggle="dropdown" href="#" aria-haspopup="true" aria-expanded="false">
              Account
            </a>
            <ul class="dropdown-menu pt-3 pb-3" aria-labelledby="navbarAccount">
              <li class="dropdown-item dropend float-left w-100">
                <a class="dropdown-link dropdown-toggle float-left w-100" data-bs-toggle="dropdown" href="#">
                  Settings
                  <i class="ri-arrow-drop-right-line"></i>
                </a>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="#">
                    General
                  </a>
                  <a class="dropdown-item" href="#">
                    Security
                  </a>
                  <a class="dropdown-item" href="#">
                    Notifications
                  </a>
                  <a class="dropdown-item" href="#">
                    Plans &amp; Payment
                  </a>
                  <a class="dropdown-item" href="#">
                    Users
                  </a>
                </div>
              </li>
              <li class="dropdown-item dropend float-left w-100">
                <a class="dropdown-link dropdown-toggle float-left w-100" data-bs-toggle="dropdown" href="#">
                  Sign In
                  <i class="ri-arrow-drop-right-line"></i>
                </a>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="#">
                    Side Cover
                  </a>
                  <a class="dropdown-item" href="#">
                    Illustration
                  </a>
                  <a class="dropdown-item" href="#">
                    Basic
                  </a>
                  <a class="dropdown-item" data-bs-toggle="modal" href="#">
                    Modal Horizontal
                  </a>
                  <a class="dropdown-item" data-bs-toggle="modal" href="#">
                    Modal Vertical
                  </a>
                </div>
              </li>
              <li class="dropdown-item dropend float-left w-100">
                <a class="dropdown-link dropdown-toggle float-left w-100" data-bs-toggle="dropdown" href="#">
                  Sign Up
                  <i class="ri-arrow-drop-right-line"></i>
                </a>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="#">
                    Side Cover
                  </a>
                  <a class="dropdown-item" href="#">
                    Illustration
                  </a>
                  <a class="dropdown-item" href="#">
                    Basic
                  </a>
                  <a class="dropdown-item" data-bs-toggle="modal" href="#">
                    Modal Horizontal
                  </a>
                  <a class="dropdown-item" data-bs-toggle="modal" href="#">
                    Modal Vertical
                  </a>
                </div>
              </li>
              <li class="dropdown-item dropend float-left w-100">
                <a class="dropdown-link dropdown-toggle float-left w-100" data-bs-toggle="dropdown" href="#">
                  Password Reset
                  <i class="ri-arrow-drop-right-line"></i>
                </a>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="#">
                    Side Cover
                  </a>
                  <a class="dropdown-item" href="#">
                    Illustration
                  </a>
                  <a class="dropdown-item" href="#">
                    Basic
                  </a>
                </div>
              </li>
              <li class="dropdown-item dropend float-left w-100">
                <a class="dropdown-link dropdown-toggle float-left w-100" data-bs-toggle="dropdown" href="#">
                  Error
                  <i class="ri-arrow-drop-right-line"></i>
                </a>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="#">
                    Side Cover
                  </a>
                  <a class="dropdown-item" href="#">
                    Illustration
                  </a>
                  <a class="dropdown-item" href="#">
                    Basic
                  </a>
                </div>
              </li>
            </ul>
          </li>
        </ul>
  
        <!-- Button -->
        <a class="navbar-btn btn btn-sm btn-primary lift ms-auto" href="#" target="_blank">
          Buy now
        </a>
  
      </div>
  
    </div>
  </nav>
  
