<!-- image-text-wrapper3 section start here -->
<section class="container tab1-wrapper section chatbot-wrapper" *ngIf="tabWrapper1Type=='botStationAi'" data-aos="zoom-in-up" data-aos-easing="ease-in-sine" data-aos-duration="1000">
  <div class="row mb-5">
    <div class="col-12 text-center">
      <h4 class="h2 mb-3">
        Package of smart<span class="chatbot-text-primary"> Bot Station AI</span>
      </h4>
      <p class="text-muted">
        Build intelligent virtual assistants for your websites, facebook pages,
        and messaging apps.
      </p>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-12">
      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs d-flex">
        <li [ngbNavItem]="1" class="flex-fill">
          <a ngbNavLink class="text-center">
            <span class="tab-label">Product</span>
          </a>
          <ng-template ngbNavContent>
            <div class="row mobile-tab-title">
              <div class="col-12 text-center">
                <h4 class="h4 pb-4 pt-4">Product</h4>
              </div>
              <div class="tab-arrow-wrapper">
                <!-- <button class="pre-btn arrow-btn" [disabled]="active === 2" (click)="nav.select(2)">
                  <i class="ri-arrow-left-s-line"></i>
                </button> -->
                <button class="next-btn arrow-btn" [disabled]="active === 2" (click)="nav.select(2)">
                  <i class="ri-arrow-right-s-line"></i>
                </button>
              </div>
            </div>
            <div class="row pt-4">
              <div class="col-12 col-md-5 text-center bot-image">
                <img src="./assets/images/bot-station-ai/bot/hi-bot.svg" class="w-50" />
              </div>
              <div class="col-12 col-md-7">
                <ngb-accordion [closeOthers]="true" #acc="ngbAccordion" activeIds="ngb-panel1-1">
                  <ngb-panel id="ngb-panel1-1">
                    <ng-template ngbPanelTitle>
                      <span class="float-start w-100">Stand out from the crowd </span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted small">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                        sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                        occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                        labore sustainable VHS.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel id="ngb-panel1-2">
                    <ng-template ngbPanelTitle>
                      <span>Boost engagement with rich messages</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted small">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                        sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                        occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                        labore sustainable VHS.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel id="ngb-panel1-3">
                    <ng-template ngbPanelTitle>
                      <span>Actions that do magic tricks</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted small">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                        sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                        occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                        labore sustainable VHS.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel id="ngb-panel1-4">
                    <ng-template ngbPanelTitle>
                      <span>Create a LiveChat ticket</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted small">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                        sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                        occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                        labore sustainable VHS.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel id="ngb-panel1-5">
                    <ng-template ngbPanelTitle>
                      <span>Transfer to LiveChat agent</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted small">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                        sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                        occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                        labore sustainable VHS.</p>
                    </ng-template>
                  </ngb-panel>
              </ngb-accordion>              
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="2" class="flex-fill">
          <a ngbNavLink class="text-center">
            <span class="tab-label">Solutions</span>
          </a>
          <ng-template ngbNavContent>
            <div class="row mobile-tab-title">
              <div class="col-12 text-center">
                <h4 class="h4 pb-4 pt-4">Solutions</h4>
              </div>
              <div class="tab-arrow-wrapper">
                <button class="pre-btn arrow-btn" [disabled]="active === 1" (click)="nav.select(1)">
                  <i class="ri-arrow-left-s-line"></i>
                </button>
                <button class="next-btn arrow-btn" [disabled]="active === 3" (click)="nav.select(3)">
                  <i class="ri-arrow-right-s-line"></i>
                </button>
              </div>
            </div>
            <div class="row pt-4">
              <div class="col-12 col-md-5 text-center bot-image">
                <img src="./assets/images/bot-station-ai/bot/remote-bot.svg" class="w-50" />
              </div>
              <div class="col-12 col-md-7">
                <ngb-accordion [closeOthers]="true" #acc="ngbAccordion" activeIds="ngb-panel2-1">
                  <ngb-panel id="ngb-panel2-1">
                    <ng-template ngbPanelTitle>
                      <span class="float-start w-100">Marketing can be automated </span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted small">Add Chat Widget to your website with a few simple clicks and automate 
                        communication with potential customers. Use a smart chatbot on your 
                        Facebook fanpage and wow users with your swift responses. Support 
                        visitors at every stage of their decision making process and dispel their 
                        doubts in the blink of an eye. Build better relationships through real-time 
                        communication and get more happy customers.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel id="ngb-panel2-2">
                    <ng-template ngbPanelTitle>
                      <span>Turn traffic into growing revenue</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted small">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                        sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                        occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                        labore sustainable VHS.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel id="ngb-panel2-3">
                    <ng-template ngbPanelTitle>
                      <span>Skyrocket your customer experience</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted small">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                        sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                        occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                        labore sustainable VHS.</p>
                    </ng-template>
                  </ngb-panel>
              </ngb-accordion>              
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="3" class="flex-fill">
          <a ngbNavLink class="text-center">
            <span class="tab-label">Integrations</span>
          </a>
          <ng-template ngbNavContent>
            <div class="row mobile-tab-title">
              <div class="col-12 text-center">
                <h4 class="h4 pb-4 pt-4">Integrations</h4>
              </div>
              <div class="tab-arrow-wrapper">
                <button class="pre-btn arrow-btn" [disabled]="active === 2" (click)="nav.select(2)">
                  <i class="ri-arrow-left-s-line"></i>
                </button>
                <button class="next-btn arrow-btn" [disabled]="active === 4" (click)="nav.select(4)">
                  <i class="ri-arrow-right-s-line"></i>
                </button>
              </div>
            </div>
            <div class="row pt-4">
              <div class="col-12 col-md-5 text-center bot-image">
                <img src="./assets/images/bot-station-ai/bot/search-bot.svg" class="w-50" />
              </div>
              <div class="col-12 col-md-7">
                <ngb-accordion [closeOthers]="true" #acc="ngbAccordion" activeIds="ngb-panel3-1">
                  <ngb-panel id="ngb-panel3-1">
                    <ng-template ngbPanelTitle>
                      <span class="float-start w-100">Stand out from the crowd </span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted small">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                        sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                        occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                        labore sustainable VHS.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel id="ngb-panel3-2">
                    <ng-template ngbPanelTitle>
                      <span>Boost engagement with rich messages</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted small">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                        sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                        occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                        labore sustainable VHS.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel id="ngb-panel3-3">
                    <ng-template ngbPanelTitle>
                      <span>Actions that do magic tricks</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted small">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                        sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                        occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                        labore sustainable VHS.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel id="ngb-panel3-4">
                    <ng-template ngbPanelTitle>
                      <span>Create a LiveChat ticket</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted small">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                        sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                        occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                        labore sustainable VHS.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel id="ngb-panel3-5">
                    <ng-template ngbPanelTitle>
                      <span>Transfer to LiveChat agent</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted small">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                        sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                        occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                        labore sustainable VHS.</p>
                    </ng-template>
                  </ngb-panel>
              </ngb-accordion>              
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="4" class="flex-fill">
          <a ngbNavLink class="text-center">
            <span class="tab-label">Chatbot Type</span>
          </a>
          <ng-template ngbNavContent>
            <div class="row mobile-tab-title">
              <div class="col-12 text-center">
                <h4 class="h4 pb-4 pt-4">Chatbot Type</h4>
              </div>
              <div class="tab-arrow-wrapper">
                <button class="pre-btn arrow-btn" [disabled]="active === 3" (click)="nav.select(3)">
                  <i class="ri-arrow-left-s-line"></i>
                </button>
                <button class="next-btn arrow-btn" [disabled]="active === 5" (click)="nav.select(5)">
                  <i class="ri-arrow-right-s-line"></i>
                </button>
              </div>
            </div>
            <div class="row pt-4">
              <div class="col-12 col-md-5 text-center bot-image">
                <img src="./assets/images/bot-station-ai/bot/money-bot.svg" class="w-50" />
              </div>
              <div class="col-12 col-md-7">
                <ngb-accordion [closeOthers]="true" #acc="ngbAccordion" activeIds="ngb-panel4-1">
                  <ngb-panel id="ngb-panel4-1">
                    <ng-template ngbPanelTitle>
                      <span class="float-start w-100">Chatbot </span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted small">73% of customers say that Customer Experience plays an important role in 
                        their decision making! Conversational AI helps you in transforming your 
                        customer experience to the next level. Engagely.ai’s Conversational Chatbots 
                        deliver effective customer experience on any desired platform and in the 
                        customer’s preferred language.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel id="ngb-panel4-2">
                    <ng-template ngbPanelTitle>
                      <span>WhatsApp Bot</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted small">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                        sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                        occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                        labore sustainable VHS.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel id="ngb-panel4-3">
                    <ng-template ngbPanelTitle>
                      <span>Phone Bot</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted small">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                        sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                        occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                        labore sustainable VHS.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel id="ngb-panel4-4">
                    <ng-template ngbPanelTitle>
                      <span>Email Bot</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted small">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                        sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                        occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                        labore sustainable VHS.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel id="ngb-panel4-5">
                    <ng-template ngbPanelTitle>
                      <span>Social Media Bot</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted small">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                        sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                        occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                        labore sustainable VHS.</p>
                    </ng-template>
                  </ngb-panel>
              </ngb-accordion>              
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="5" class="flex-fill">
          <a ngbNavLink class="text-center">
            <span class="tab-label">Help Center</span>
          </a>
          <ng-template ngbNavContent>
            <div class="row mobile-tab-title">
              <div class="col-12 text-center">
                <h4 class="h4 pb-4 pt-4">Help Center</h4>
              </div>
              <div class="tab-arrow-wrapper">
                <button class="pre-btn arrow-btn" [disabled]="active === 4" (click)="nav.select(4)">
                  <i class="ri-arrow-left-s-line"></i>
                </button>
                <!-- <button class="next-btn arrow-btn" [disabled]="active === 3" (click)="nav.select(2)">
                  <i class="ri-arrow-right-s-line"></i>
                </button> -->
              </div>
            </div>
            <div class="row pt-4">
              <div class="col-12 col-md-5 text-center bot-image">
                <img src="./assets/images/bot-station-ai/bot/envelope-bot.svg" class="w-50" />
              </div>
              <div class="col-12 col-md-7">
                <ngb-accordion [closeOthers]="true" #acc="ngbAccordion" activeIds="ngb-panel5-1">
                  <ngb-panel id="ngb-panel5-1">
                    <ng-template ngbPanelTitle>
                      <span class="float-start w-100">Meet our ChatBot platform </span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted small">To understand what is our platform lets talk for a moment what are chatbots 
                        in general and who they are designed for. A chatbot is a technology that 
                        simulates human-like conversations in real time. The conversation is 
                        analyzed by the AI and advanced algorithms to deliver high-quality 
                        experience and smooth communication between human and the computer.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel id="ngb-panel5-2">
                    <ng-template ngbPanelTitle>
                      <span>Meet Your Dashboard</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted small">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                        sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                        occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                        labore sustainable VHS.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel id="ngb-panel5-3">
                    <ng-template ngbPanelTitle>
                      <span>Ask question</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted small">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                        sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                        occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                        labore sustainable VHS.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel id="ngb-panel5-4">
                    <ng-template ngbPanelTitle>
                      <span>Tag for LiveChat</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted small">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                        sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                        occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                        labore sustainable VHS.</p>
                    </ng-template>
                  </ngb-panel>
              </ngb-accordion>              
              </div>
            </div>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</section>
