<div class="footer-3">
    <ul class="float-start w-100">
        <li class="float-start">
            <a class="float-start" [routerLinkActive]="['active']" [routerLink]="['/portfolio-aboutme-home']">
                <span class="float-start line"></span> 
                <span class="text">Home</span>
            </a>
        </li>
        <li class="float-start">
            <a class="float-start" [routerLinkActive]="['active']" [routerLink]="['/portfolio-aboutme-about']">
                <span class="float-start line"></span> 
                <span class="text">About</span>
            </a>
        </li>
        <li class="float-start">
            <a class="float-start" [routerLinkActive]="['active']" >
                <span class="float-start line"></span> 
                <span class="text">Skill</span>
            </a>
        </li>
        <li class="float-start">
            <a class="float-start" [routerLinkActive]="['active']" >
                <span class="float-start line"></span> 
                <span class="text">Education</span>
            </a>
        </li>
        <li class="float-start">
            <a class="float-start" [routerLinkActive]="['active']" >
                <span class="float-start line"></span> 
                <span class="text">Experience</span>
            </a>
        </li>
        <li class="float-start">
            <a class="float-start" [routerLinkActive]="['active']" >
                <span class="float-start line"></span> 
                <span class="text">Project</span>
            </a>
        </li>
    </ul>
</div>