<section class="container-fluid accordion1 section bg-light" *ngIf="accordion1Type=='botStationAi'">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 left-section" data-aos="zoom-in" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="1000" data-aos-delay="200">
            <ngb-accordion [closeOthers]="true" activeIds="accordion-1">
                <ngb-panel id="accordion-1">
                    <ng-template ngbPanelTitle>
                        E-commerce 
                        <i class="ri-arrow-down-s-line"></i>
                    </ng-template>
                  <ng-template ngbPanelContent>
                      <div class="float-start w-100 content-wrapper">
                        <div class="text-wrapper">
                            <h5 class="h5">Redefine customer experience with conversational commerce</h5>
                            <p class="text-muted">E-commerce companies use Verloop.io’s conversational AI platform to answer customer questions, handle repetitive tasks and remove friction in customer support across channels. Customers can shop and get seamless support without leaving the platform. </p>
                        </div>
                        <div class="img-wrapper">
                            <img src="./assets/images/undraw/web_shopping.svg" class="accordion-img" />
                        </div>
                      </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="accordion-2">
                    <ng-template ngbPanelTitle>
                        Banking and FS
                        <i class="ri-arrow-down-s-line"></i>
                    </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="float-start w-100 content-wrapper">
                      <div class="text-wrapper">
                          <h5 class="h5">Advance your digital banking with conversational AI</h5>
                          <p class="text-muted">Banking and Financial Services are providing digital solutions to their customers through Verloop.io’s conversation AI platform. Customers can now check their EMI details, apply for loans and transfer money from their preferred channel and language on the go. </p>
                      </div>
                      <div class="img-wrapper">
                          <img src="./assets/images/undraw/pay_online.svg" class="accordion-img" />
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="accordion-3">
                    <ng-template ngbPanelTitle>
                        Real Estate
                        <i class="ri-arrow-down-s-line"></i>
                    </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="float-start w-100 content-wrapper">
                      <div class="text-wrapper">
                          <h5 class="h5">Support customers with conversational AI for real estate</h5>
                          <p class="text-muted">Real Estate companies use Verloop.io to engage with customers on a personalised level using intelligent conversations. Companies can schedule site visits, submit/verify documents, reduce the sales cycle to increase conversion rates, and close more deals..</p>
                      </div>
                      <div class="img-wrapper">
                          <img src="./assets/images/undraw/buy_house.svg" class="accordion-img" />
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="accordion-4">
                    <ng-template ngbPanelTitle>
                        Travel
                        <i class="ri-arrow-down-s-line"></i>
                    </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="float-start w-100 content-wrapper">
                      <div class="text-wrapper">
                          <h5 class="h5">Up your travel experience game with conversational AI</h5>
                          <p class="text-muted">Online Travel companies, with the help of Verloop.io’s AI-powered platform, are conversing with their users in natural language to boost customer engagement. They are providing support through a fast, simple, elegant, and robust experience. </p>
                      </div>
                      <div class="img-wrapper">
                          <img src="./assets/images/undraw/travelers.svg" class="accordion-img" />
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="accordion-5">
                    <ng-template ngbPanelTitle>
                        Insurance
                        <i class="ri-arrow-down-s-line"></i>
                    </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="float-start w-100 content-wrapper">
                      <div class="text-wrapper">
                          <h5 class="h5">Provide premium support with conversational AI in insurance</h5>
                          <p class="text-muted">Insurance companies use Verloop.io’s customer support automation platform to reduce manual workflows and free up employees to focus on more strategic tasks. Customers can select policy selection and file claims quickly, securely and accurately. </p>
                      </div>
                      <div class="img-wrapper">
                          <img src="./assets/images/undraw/virtual_assistant.svg" class="accordion-img" />
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="accordion-6">
                    <ng-template ngbPanelTitle>
                        Food
                        <i class="ri-arrow-down-s-line"></i>
                    </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="float-start w-100 content-wrapper">
                      <div class="text-wrapper">
                          <h5 class="h5">Automate support with conversational AI in foodservice</h5>
                          <p class="text-muted">Foodtech companies that use Verloop.io’s intelligent platform are solving to meet support demand using insights from the intuitive analytics dashboard. They are improving customer experience with real-time answers and notification. </p>
                      </div>
                      <div class="img-wrapper">
                          <img src="./assets/images/undraw/online_groceries.svg" class="accordion-img" />
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="accordion-7">
                    <ng-template ngbPanelTitle>
                        Logistics
                        <i class="ri-arrow-down-s-line"></i>
                    </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="float-start w-100 content-wrapper">
                      <div class="text-wrapper">
                          <h5 class="h5">Ship the best customer support with conversational AI</h5>
                          <p class="text-muted">Logistics companies on Verloop.io’s conversational AI platform build support that is scalable and customisable to meet customer needs. From automating low-level questions on order statuses and refund policies to tracking packages, they are simplifying processes.</p>
                      </div>
                      <div class="img-wrapper">
                          <img src="./assets/images/undraw/logistics.svg" class="accordion-img" />
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="accordion-8">
                    <ng-template ngbPanelTitle>
                        Education
                        <i class="ri-arrow-down-s-line"></i>
                    </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="float-start w-100 content-wrapper">
                      <div class="text-wrapper">
                          <h5 class="h5">Make edtech interactive with conversational AI in education</h5>
                          <p class="text-muted">Edtech companies on Verloop.io’s platform automate their interactions with teachers, parents and students through messaging and voice interfaces. Our conversational AI engages with customers on a personalised level on their preferred channel. </p>
                      </div>
                      <div class="img-wrapper">
                          <img src="./assets/images/undraw/education.svg" class="accordion-img" />
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
        </div>
      </div>
    </div>
  </section>