import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-image-text-wrapper4',
  templateUrl: './image-text-wrapper4.component.html',
  styleUrls: ['./image-text-wrapper4.component.scss']
})
export class ImageTextWrapper4Component implements OnInit {

  @Input() imageTextwrapper4Type:string;

  constructor() { }

  ngOnInit(): void {
  }

}
